import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { setTheme } from "@app/store/userPreferences/userPreferences.actions";
import { getTheme } from "@app/store/userPreferences/userPreferences.selector";
import { LightboxService } from "@common/components/lightbox/lightbox.service";
import { APP_THEME } from "@common/constants/application.constants";
import { ROUTES } from "@common/constants/routes.constants";
import { useValueRef } from "@common/hooks/useValueRef";

export const usePreferenceColorScheme = () => {
    const userPreferenceTheme = useAppSelector(getTheme);
    const dispatch = useAppDispatch();

    const location = useLocation();
    const currentPathRef = useValueRef(location?.pathname);

    useEffect(() => {
        const prefersColorScheme = window.matchMedia(`(prefers-color-scheme: ${APP_THEME.LIGHT})`);

        const onColorSchemeChange = ({ matches }: { matches: boolean }) => {
            const newTheme = matches ? APP_THEME.LIGHT : APP_THEME.DARK;

            if (
                newTheme !== userPreferenceTheme &&
                currentPathRef.current?.includes(ROUTES.PIRATE_SHIP)
            ) {
                LightboxService.openConfirmation({
                    title: "Confirm The Color Scheme Change",
                    content: `Do you want to change the color scheme to ${newTheme}?`,
                    okText: "Yes",
                    cancelText: "No",
                    onOK: () => dispatch(setTheme(newTheme)),
                });
            }
        };

        prefersColorScheme.addEventListener("change", onColorSchemeChange);

        return () => prefersColorScheme.removeEventListener("change", onColorSchemeChange);
    }, [dispatch, userPreferenceTheme, currentPathRef]);

    return userPreferenceTheme;
};
