import {
    getAnalysisCalibrationCode,
    getAnalysisTravelModeFromCode,
} from "@app/analysis/state/analysisConfiguration.helpers";
import { getMapStyle as getUserPreferredMapStyle } from "@app/store/userPreferences/userPreferences.selector";
import {
    getAllAvailableZones,
    getVisualizationPropertyFullLabel,
    getWeightFilterOptions,
    getZoneType,
} from "@app/viz3/base/state/baseViz.helpers";
import { VISUALIZATION_BY_ANALYSIS_TYPE } from "@app/viz3/base/visualizationsConfiguration";
import { DEFAULT_BASE_MAP_STYLE, MAP_MODES } from "@common/components/baseMap/baseMap.constants";
import { ANALYSIS_TYPES_LIST } from "@common/constants/analysis.constants";
import { createSelector } from "reselect";

import { MEASUREMENT_TYPES, TIME_FILTERS } from "./baseViz.constants";

/*
    Getter of the main object, just for the sake of convenience.
*/
export const getBaseViz = state => state.viz3.base;

/*
    Getters sub-objects of the main one. Just for the sake of convenience.
*/
export const getSelectedAnalysis = state => getBaseViz(state).selectedAnalysis;
export const getFilters = state => getBaseViz(state).filters;
export const getIsFilterChanged = filterName => state =>
    getBaseViz(state).changedFilters[filterName];
export const getWidgets = state => getBaseViz(state).widgets;
export const getVisualization = state => getBaseViz(state).visualization;
export const getMapState = state => getBaseViz(state).map;
export const getMultipleSelection = state => getBaseViz(state).multipleSelection;
export const getPendingExportRequests = state => getBaseViz(state).pendingExportRequests;
export const getLastFilterHash = state => getBaseViz(state).filterHash;

/*
    These getters should be private (used only in this file) and are necessary only for proper
    createSelector` memoization. If you want to get those properties, please use parent object.
*/
const getBaseVizMapStyle = state => getMapState(state).style;
const getMeasurement = state => getVisualization(state).measurement;
const getVisualizationProperty = state => getVisualization(state).visualizationProperty;

/*
    Other getters which are used in different places.
    Please create new ones with caution. We definitely don't want this file become the place
    where functions used only once and/or don't serve as helpers.
*/
export const getMapLayersVisibility = state => getVisualization(state).mapLayersVisibility;
export const getIsInCompareMode = state => getMapState(state).mode === MAP_MODES.COMPARE_SEGMENTS;
export const getMapMode = state => getMapState(state).mode;

export const getCalibration = state => {
    const selectedAnalysis = getSelectedAnalysis(state);

    return {
        code: getAnalysisCalibrationCode(selectedAnalysis),
        label: selectedAnalysis.output_type_name,
    };
};

export const getVehicleWeightFilters = state => {
    const selectedAnalysis = getSelectedAnalysis(state);
    const { vehicleWeightClass = [] } = getVisualization(state);

    return selectedAnalysis.enable_veh_weight ? vehicleWeightClass : undefined;
};

export const getBaseVizScreenshotsState = state => {
    const map = { zoom: window.BASE_MAP.getZoom(), center: window.BASE_MAP.getCenter() };

    return { base: getBaseViz(state), map: map };
};

/*
    re-select selectors.
    Implements memoization. Use them in case of some calculations you need to perform together
    with store selectors.
*/

export const getTravelMode = createSelector(getSelectedAnalysis, selectedAnalysis =>
    getAnalysisTravelModeFromCode(selectedAnalysis.travel_mode_type),
);

/**
 * Get a map of zones by zone type
 * returns { [key: ZoneType]: Zone[] }
 * where ZoneType = "origin" | "destination"
 */
export const getAvailableZonesMap = createSelector(getSelectedAnalysis, selectedAnalysis =>
    getAllAvailableZones(selectedAnalysis),
);

export const getAvailableZonesByType = zoneType =>
    createSelector(getAvailableZonesMap, zonesMap => zonesMap[zoneType.typeName]);

const getVizCode = createSelector(getSelectedAnalysis, selectedAnalysis => {
    if (!selectedAnalysis) return null;

    return VISUALIZATION_BY_ANALYSIS_TYPE[selectedAnalysis.project_type].code;
});

/**
 * Get a map of zones for zoneType
 * returns { [key: zoneId]: Zone }
 */
export const getZonesMapByType = zoneType =>
    createSelector(getAvailableZonesByType(zoneType), zonesMap =>
        zonesMap.reduce((result, zone) => {
            result[zone.zone_id] = zone;

            return result;
        }, {}),
    );

export const getDisplayedMeasurement = createSelector(
    getMeasurement,
    getVisualizationProperty,
    getCalibration,
    getSelectedAnalysis,
    (measurement, vizProperty, calibration, selectedAnalysis) => {
        if (measurement?.code === MEASUREMENT_TYPES.VOLUME.code) {
            return {
                label: getVisualizationPropertyFullLabel({
                    property: vizProperty,
                    calibration,
                    selectedAnalysis,
                }),
            };
        }

        return measurement;
    },
);

const getActiveMapLayerConfigurations = createSelector(
    getVizCode,
    state => state.viz3,
    (vizCode, viz3State) => {
        const activeState = viz3State[vizCode];

        if (!activeState) return null;

        return activeState.map.layerConfigurations;
    },
);

/**
 * Get a map of zones by zoneId
 * returns { [key: zoneId]: Zone }
 */
export const getZonesMap = createSelector(getAvailableZonesMap, zonesByType => {
    const allZones = Object.values(zonesByType).flat();

    return allZones.reduce((result, zone) => {
        result[zone.zone_id] = zone;

        return result;
    }, {});
});

export const getAnalysisCommonConfig = createSelector(
    getVizCode,
    getActiveMapLayerConfigurations,
    getBaseVizMapStyle,
    getWidgets,
    (vizCode, layerConfigurations, baseMapStyle, widgets) => {
        return {
            vizCode,
            layerConfigurations,
            baseMapStyle,
            widgets,
        };
    },
);

export const getBaseAnalysisUserConfig = createSelector(
    getFilters,
    getVisualization,
    (filters, visualization) => {
        // Prepare only zone ids for saving configuration to reduce payload
        const zones = Object.keys(filters).reduce((result, zoneType) => {
            result[zoneType] = Array.isArray(filters[zoneType])
                ? filters[zoneType].map(zone => zone?.value)
                : filters[zoneType];

            return result;
        }, {});

        return {
            filters: zones,
            visualization,
        };
    },
);

/**
 * Checks whether analysis has invalid/not supported configuration
 * - if 'enable_veh_weight' flag is set, analysis should have at least 1 'include_comm_*' flag enabled
 */
export const getIsAnalysisWithInvalidConfig = createSelector(
    getSelectedAnalysis,
    selectedAnalysis => {
        return !!(
            selectedAnalysis &&
            selectedAnalysis.enable_veh_weight &&
            !getWeightFilterOptions(selectedAnalysis).length
        );
    },
);

export const getTimeControlScreenshotFilters = createSelector(getFilters, filters => {
    const dayTypes = filters[TIME_FILTERS.DAY_TYPES.filterName]?.map(dayType => dayType.label);
    const dayParts = filters[TIME_FILTERS.DAY_PARTS.filterName]?.map(dayPart => dayPart.label);

    return {
        title: "Time Controls",
        filters: [
            { label: "Day Types", values: dayTypes },
            { label: "Day Parts", values: dayParts },
        ],
    };
});

export const getVehicleWeightScreenshotFilters = createSelector(
    getVehicleWeightFilters,
    vehicleWeightFilters => {
        if (!vehicleWeightFilters) return null;
        const vehicleWeightLabels = vehicleWeightFilters?.map(weight => weight.display) || [];

        return {
            title: "Truck Segmentation",
            filters: [{ label: "Commercial Truck Segmentation", values: vehicleWeightLabels }],
        };
    },
);

export const getMetricScreenshotFilters = createSelector(getVisualization, visualization => {
    const { measurement, dataTrimRange } = visualization;

    return {
        title: "Metric Controls",
        filters: [
            { label: "Measurement", values: measurement ? [measurement.label] : [] },
            {
                label: "Data trimming (percent)",
                values: dataTrimRange ? [dataTrimRange.join(" - ")] : [],
            },
        ],
    };
});

export const getIsSpeedMetricAvailable = createSelector(getSelectedAnalysis, selectedAnalysis => {
    return ANALYSIS_TYPES_LIST.some(
        ({ id, hasSpeedMetric }) => id === selectedAnalysis.project_type && hasSpeedMetric,
    );
});

/**
 * Returns a zone by id from the list of all zones, which were used at the analysis
 */
export const getAvailableZoneById = (id, state) => {
    const zonesMap = getZonesMap(state);

    return zonesMap[id];
};

/**
 * Returns a zone by id and type from the list of all zones of provided type
 */
export const getAvailableZonesByIdAndType = (id, type, state) => {
    if (!type || !id) return null;

    const zoneType = getZoneType(type);
    const zonesMap = getZonesMapByType(zoneType)(state);

    return zonesMap[id];
};

export const getSegmentsGroupConfig = state => getBaseViz(state).segmentsGroupConfigurations;

export const getSelectedSegmentFilters = state => getFilters(state).selectedSegmentFilters;

export const getSegmentGroups = state => getFilters(state).segmentGroups;

export const getSelectedRoads = state => getFilters(state).selectedRoads;

export const getSelectedSegments = state => getSegmentsGroupConfig(state).selectedSegments;

export const getSegmentsGroupMapConfig = state => getSegmentsGroupConfig(state).map;

export const getHoveredSegment = state => getSegmentsGroupMapConfig(state).hoveredSegment;

export const getSelectedSegmentsIds = createSelector(getSelectedSegments, selectedSegments =>
    selectedSegments.map(segment => segment.zone_id),
);

export const getVisualizationPropertyCode = createSelector(
    getVisualization,
    visualization => visualization.visualizationProperty?.code,
);

export const getMapStyle = createSelector(
    getBaseVizMapStyle,
    getUserPreferredMapStyle,
    (baseVizMapStyle, userPreferredMapStyle) =>
        baseVizMapStyle || userPreferredMapStyle || DEFAULT_BASE_MAP_STYLE.styleId,
);
