import { TCurrentUser } from "@app/store/currentUser/currentUser.types";
import { StlButton } from "@common/components";
import { AdminApiService } from "@common/services/server/adminApi.service";
import { faUserAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./emulationBadge.less";

const endEmulation = () => {
    AdminApiService.endEmulation();
};

export const EmulationBadge = <T extends TCurrentUser>({ currentUser }: { currentUser: T }) => (
    <div className="stl-emulation-badge">
        <div className="user-name">
            <FontAwesomeIcon icon={faUserAlt} className="user-icon" />
            Emulating:&nbsp;
            <span className="user-email">{currentUser.realUser.email_addr}</span>
        </div>
        <StlButton className="stop-emulation-btn" size="sm" onClick={endEmulation}>
            Stop
        </StlButton>
    </div>
);
