import { History } from "history";
import { PRODUCT_DETAILS } from "@app/auth/auth.constants";
import { PLATFORM_ACCESS_TYPES } from "@common/constants/orgTypes.constants";
import { ROUTES } from "@common/constants/routes.constants";
import { TProductType } from "@common/services/server/userApi.types";

export const LAST_ACCESSED_PRODUCT = "last-accessed-product";
export const IS_REDIRECTED = "isRedirected";

export const handleNavigation = (
    event:
        | React.MouseEvent<HTMLDivElement, MouseEvent>
        | React.MouseEvent<HTMLButtonElement, MouseEvent>,
    product: string,
    isProductExpired: boolean,
    url: string,
    history: History,
) => {
    if (isProductExpired) return;

    const productType = product as TProductType;
    const route = PRODUCT_DETAILS[productType].route;
    const isMiddleClick = event.button === 1;
    const isFromStudyOrg = location.search === "isFromStudyOrg=true";

    const openInNewTab = (path: string) => {
        localStorage.setItem(LAST_ACCESSED_PRODUCT, path);
        const newWindow = window.open(path, "_blank");

        const checkWindow = setInterval(() => {
            if (newWindow && newWindow.document.readyState === "complete") {
                newWindow.sessionStorage.setItem(
                    LAST_ACCESSED_PRODUCT,
                    localStorage.getItem(LAST_ACCESSED_PRODUCT)!,
                );
                clearInterval(checkWindow);
            }
        }, 100);
    };

    const navigateTo = (path: string) => {
        sessionStorage.setItem(LAST_ACCESSED_PRODUCT, path);
        localStorage.setItem(LAST_ACCESSED_PRODUCT, path);
        history.push(path);
    };

    if (isMiddleClick) {
        if (productType !== PLATFORM_ACCESS_TYPES.INSIGHT.value) {
            openInNewTab(url);
        } else if (isFromStudyOrg && route === "/") {
            openInNewTab(ROUTES.CHOOSE_STUDY);
        } else {
            openInNewTab("/");
        }
    } else if (productType !== PLATFORM_ACCESS_TYPES.INSIGHT.value) {
        sessionStorage.setItem(LAST_ACCESSED_PRODUCT, url);
        localStorage.setItem(LAST_ACCESSED_PRODUCT, url);
        window.location.href = url;
    } else if (isFromStudyOrg && route === "/") {
        navigateTo(ROUTES.CHOOSE_STUDY);
    } else {
        navigateTo("/");
    }
};
