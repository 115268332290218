import { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import type { Map } from "mapbox-gl";
import { StlLoader } from "@common/components";
import { MAP_LOADER_ANIMATION_CONFIGURATION } from "@common/components/baseMap/baseMap.constants";
import type { TControlConfig } from "@common/components/baseMap/baseMap.types";
import { MapLoader } from "@common/components/baseMap/customControls";

export type TProps = {
    map: Map | null;
    enabled: boolean;
    position: TControlConfig["position"];
    isLoading: boolean;
};

export const useMapLoaderControl = ({ map, enabled, position, isLoading }: TProps) => {
    const [isControlAdded, setIsControlAdded] = useState(false);
    const mapLoader = useMemo(
        () =>
            enabled
                ? new MapLoader({
                      onAdd: () => setIsControlAdded(true),
                  })
                : null,
        [enabled],
    );

    useEffect(() => {
        if (!map || !mapLoader) return;

        map.addControl(mapLoader, position);
    }, [map, mapLoader, position]);

    return useCallback(() => {
        if (!map || !mapLoader || !isControlAdded) return null;

        return ReactDOM.createPortal(
            <StlLoader show={isLoading} animationConfig={MAP_LOADER_ANIMATION_CONFIGURATION} />,
            mapLoader.container as HTMLDivElement,
        );
    }, [map, mapLoader, isControlAdded, isLoading]);
};
