import { CALIBRATIONS } from "@app/analysis/state/analysisConfiguration.constants";
import { MEASUREMENT_TYPES } from "@app/viz3/base/state/baseViz.constants";
import { calculateBinColors } from "@app/viz3/base/state/baseViz.helpers";
import { SPEED_METRIC_TYPES } from "@app/viz3/base/widgets/zoneDistribution/zoneDistribution.constants";
import {
    NP_SEGMENT_VISUALIZATION_PROPERTIES,
    NP_SEGMENT_VISUALIZATION_PROPERTIES_LIST,
} from "@app/viz3/npSegmentVisualization/state/npSegmentViz.constants";
import {
    METRIC_TYPES,
    SEGMENT_DISTRIBUTION_METRIC_KEY,
} from "@app/viz3/npSegmentVisualization/widgets/npSegmentDistribution/npSegmentDistribution.constants";
import { NP_TIME_DISTRIBUTION_METRIC_TYPES } from "@app/viz3/npSegmentVisualization/widgets/npTimeDistribution/npSegmentTimeDistribution.constants";
import { MODES_OF_TRAVEL } from "@common/constants/analysis.constants";
import { formatValue, numberToPercentage } from "@common/formatters/formatValue";
import { getAttributeMeasurementUnit } from "@common/helpers/measurementUnits.helpers";
import { arrayIncludes } from "@common/utils/arrayIncludes";

export const createBinItems = ({
    zoneScores,
    statistics,
    measurement,
    colors,
    showValueAsPercent,
    fractionDigitsCount,
    showValueAsFractional = false,
    isWeightClassPercentage = false,
}) => {
    if (!zoneScores || !statistics || !statistics.total_count) return [];

    const bins = isWeightClassPercentage ? zoneScores.bins_weight_class_pct : zoneScores.bins;

    const totalCount = statistics.total_count;
    const binStarts = bins.kmeans;
    const binColors = calculateBinColors(colors, binStarts.length);

    const shouldShowShare = measurement?.code === MEASUREMENT_TYPES.PERCENT.code;

    const getBinLabel = (binStart, binEnd) => {
        if (isWeightClassPercentage) {
            const _binStart = formatValue(binStart, { shouldShowAsPercentage: true });
            const _binEnd = formatValue(binEnd, { shouldShowAsPercentage: true });

            if (_binEnd > _binStart) return `${_binStart}-${_binEnd}`;
            return `${_binStart}`;
        }

        let [_binStart, _binEnd] = [binStart, binEnd].map(
            showValueAsFractional ? value => value.toFixed(2) : Math.round,
        );

        if (shouldShowShare) {
            _binStart = numberToPercentage(_binStart, totalCount);
            _binEnd = numberToPercentage(_binEnd, totalCount);
        }

        if (showValueAsPercent) {
            _binStart = formatValue(binStart, {
                fractionDigitsCount,
                shouldShowAsPercentage: true,
            });
            _binEnd = formatValue(binEnd, { fractionDigitsCount, shouldShowAsPercentage: true });
        }

        if (_binEnd > _binStart) return `${_binStart}-${_binEnd}`;
        return `${_binStart}`;
    };

    const getBinEnd = index => {
        if (index < binStarts.length - 1) {
            // When showValueAsPercent is set, binStarts are percentages in fractional form, i.e. 40.25% will come as 0.4025;
            // In this case we use increment of 0.0001 because percents are displayed with 2 decimal places
            // (0.0001 * 100 = 0.01% - step for percentage mode). For absolute values we use increment of 1
            const increment =
                showValueAsPercent || isWeightClassPercentage
                    ? 0.0001
                    : showValueAsFractional
                    ? 0.01
                    : 1;
            const tryBinEnd = binStarts[index + 1] - increment;
            // If single-valued bin, set binEnd to binStart
            return tryBinEnd >= binStarts[index] ? tryBinEnd : binStarts[index];
        } else {
            // use max value as end of last bin
            return isWeightClassPercentage ? statistics.max_weight_class_pct : statistics.max;
        }
    };

    return binStarts
        .map((binStart, index) => {
            const binEnd = getBinEnd(index);

            return {
                color: binColors[index],
                label: getBinLabel(binStart, binEnd),
            };
        })
        .reverse();
};

export const zonesRequiredValidator = (errors, context) => {
    const { filters } = context;

    if (!filters.zone_ids?.length) {
        errors.push("Please select at least one Segment");
    }

    return errors;
};

export const getSegmentErrorMessage = (vizOption, measurementUnit) => {
    return `Time distribution charts are not available for ${vizOption.label} (${
        vizOption.units
            ? getAttributeMeasurementUnit({ attribute: vizOption, measurementUnit })
            : vizOption.unit
    })`;
};

export const getVisualizationPropertyByDistributionMetric = ({ activeMetricType, metricKey }) =>
    NP_SEGMENT_VISUALIZATION_PROPERTIES_LIST.find(
        option => option[metricKey].id === activeMetricType,
    );

export const getNPVisualizationPropertyByDistributionMetric = ({
    activeMetricType,
    metricKey,
    speedMetricType,
}) => {
    if (
        activeMetricType === METRIC_TYPES.speed.id &&
        metricKey === SEGMENT_DISTRIBUTION_METRIC_KEY &&
        speedMetricType.includes(SPEED_METRIC_TYPES.SPEED_PERCENTILE.code)
    ) {
        return NP_SEGMENT_VISUALIZATION_PROPERTIES.SPEED_PERCENTILE;
    }

    return NP_SEGMENT_VISUALIZATION_PROPERTIES_LIST.find(
        option => option[metricKey].id === activeMetricType,
    );
};

const filterVisualizationProperties = excludedOptionIds => {
    return NP_SEGMENT_VISUALIZATION_PROPERTIES_LIST.filter(
        option => !arrayIncludes(excludedOptionIds, option.code),
    );
};

export const getAvailableNPVisualizationProperties = analysis => {
    const excludedOptionIds = [];

    if (!analysis?.enable_travel_time_reliability)
        excludedOptionIds.push(NP_TIME_DISTRIBUTION_METRIC_TYPES.truck_travel_time_reliability.id);

    if (analysis.travel_mode_type !== MODES_OF_TRAVEL.TRUCK_GT.code)
        return filterVisualizationProperties(excludedOptionIds);

    if (analysis.output_type_id === CALIBRATIONS.TRUCK_VOLUME.id)
        excludedOptionIds.push(NP_SEGMENT_VISUALIZATION_PROPERTIES.AVG_DURATION.code);

    if (analysis.output_type_id === CALIBRATIONS.TRIP_COUNTS_TRUCK_GT.id)
        excludedOptionIds.push(NP_SEGMENT_VISUALIZATION_PROPERTIES.VHD.code);

    return filterVisualizationProperties(excludedOptionIds);
};
