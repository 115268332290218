import type { LineString, MultiPolygon, Point, Polygon } from "geojson";
import type { Layer, Map } from "mapbox-gl";
import { MAP_MODES } from "@common/components/baseMap/baseMap.constants";
import { TSelectableZoneLayers } from "@common/components/baseMap/customControls/zoneSelectionTools/hooks/useZoneSelectionControl/useZoneSelectionControl";
import type { EMeasurementUnits } from "@common/features/orgs/orgs.types";

export type TMapModes = keyof typeof MAP_MODES;

export enum EMapStyleIds {
    dark = "dark-v10",
    streets = "streets-v11",
    light = "light-v10",
    satellite = "satellite-streets-v11",
    navigator_day = "navigation-day-v1",
    navigator_night = "navigation-night-v1",
    esri_dark_gray_osm = "dark-gray",
    esri_light_gray_osm = "light-gray",
    esri_streets_osm = "streets",
    esri_imagery_hybrid_osm = "hybrid",
}

export enum ETransportationMode {
    Pedestrian = "pedestrian",
    Bicycle = "bicycle",
    Vehicle = "vehicle",
}

export type TMapStyleProvider = "mapbox" | "esri";

export enum EZoomBehaviors {
    ZOOM_ON_INIT = "ZOOM_ON_INIT",
    ZOOM_ON_CHANGE = "ZOOM_ON_CHANGE",
    ZOOM_ON_CONTROL_ONLY = "ZOOM_ON_CONTROL",
}

export enum EControlPositions {
    TOP_RIGHT = "top-right",
    TOP_LEFT = "top-left",
    BOTTOM_RIGHT = "bottom-right",
    BOTTOM_LEFT = "bottom-left",
}
export interface ILegendGroup {
    isGroup: boolean;
    groupName: string;
    swatches: Array<ISwatch>;
    breakPointBins?: Array<number>;
}

export interface ISwatch {
    color: string;
    label: string;
}

export type TMapRegion = {
    id: number;
    buffer: number;
    geometry: string;
    buffered_geometry: string;
    measurement_unit?: EMeasurementUnits;
    core: {
        buffer?: number;
        geometry: string | null;
        buffered_geometry: string | null;
    };
};

export type TBaseMaps = {
    [key: string]: Map | null;
};

export type TMapLayersConfig = {
    travelModeCode?: string;
    shouldUseLocalState?: boolean;
    showEnhancedLayers?: boolean;
    showOsmLayers?: boolean;
};

export type TSelectionToolsConfig<T extends unknown> = {
    zoneTypeId?: string;
    selectedZones: T[];
    layers: TSelectableZoneLayers;
    position: EControlPositions;
    showSelectionTools?: boolean;
    disableSelectionTools?: boolean;
    initialStack?: { customBackwardStack: T[][] };
};

export type TControlConfig = {
    imgs?: {
        northArrow: string;
    };
    controls: {
        mapStyle?: boolean;
        location?: boolean | { open: boolean };
        mapLoader?: boolean;
        northArrow?: boolean;
        fullScreen?: boolean;
        navigation?: boolean;
        mapLayers?: boolean;
        compact?: boolean;
        measurementTool?: boolean;
    };
    position?: EControlPositions;
    styleChangeHandler: (event?: Event) => void;
    mapLayersConfig?: TMapLayersConfig;
    selectionToolsConfig?: TSelectionToolsConfig<unknown>;
};

export type TPrevLayer = Layer & {
    [key: string]: unknown;
};

export type TGeometryCoordinates =
    | Point["coordinates"]
    | LineString["coordinates"]
    | Polygon["coordinates"]
    | MultiPolygon["coordinates"];
