const ACTION_PREFIX = "time_periods";

export const TIME_PERIODS_ACTION_TYPES = {
    SET_DATA_PERIOD_SETTINGS: `${ACTION_PREFIX}/SET_DATA_PERIOD_SETTINGS`,
    TOGGLE_EXCLUDE_DATE_RANGES: `${ACTION_PREFIX}/TOGGLE_EXCLUDE_DATE_RANGES`,
    CLEAR_DATA_PERIODS: `${ACTION_PREFIX}/CLEAR_DATA_PERIODS`,
    SET_DATA_PERIODS: `${ACTION_PREFIX}/SET_DATA_PERIODS`,
    SET_DATA_MONTHS: `${ACTION_PREFIX}/SET_DATA_MONTHS`,
    SET_DAY_TYPE_KIND: `${ACTION_PREFIX}/SET_DAY_TYPE_KIND`,
    SET_WEEKDAY_TYPE: `${ACTION_PREFIX}/SET_WEEKDAY_TYPE`,
    SET_DAY_TYPES: `${ACTION_PREFIX}/SET_DAY_TYPES`,
    SET_DRAFT_CUSTOM_DAY_TYPES: `${ACTION_PREFIX}/SET_DRAFT_CUSTOM_DAY_TYPES`,
    SET_DAILY_DAY_TYPES: `${ACTION_PREFIX}/SET_DAILY_DAY_TYPES`,
    ADD_DAY_TYPE: `${ACTION_PREFIX}/ADD_DAY_TYPE`,
    REMOVE_DAY_TYPE: `${ACTION_PREFIX}/REMOVE_DAY_TYPE`,
    CANCEL_ALL_EDIT_DAY_TYPE: `${ACTION_PREFIX}/CANCEL_ALL_EDIT_DAY_TYPE`,
    SET_INITIAL_DAY_TYPES: `${ACTION_PREFIX}/SET_INITIAL_DAY_TYPES`,
    CLEAR_DAY_TYPES: `${ACTION_PREFIX}/CLEAR_DAY_TYPES`,
    CANCEL_ALL_EDIT_DAY_PART: `${ACTION_PREFIX}/CANCEL_ALL_EDIT_DAY_PART`,
    SET_DAY_PART_KIND: `${ACTION_PREFIX}/SET_DAY_PART_KIND`,
    SET_DAY_PARTS: `${ACTION_PREFIX}/SET_DAY_PARTS`,
    SET_DRAFT_CUSTOM_DAY_PARTS: `${ACTION_PREFIX}/SET_DRAFT_CUSTOM_DAY_PARTS`,
    SET_INITIAL_DAY_PARTS: `${ACTION_PREFIX}/SET_INITIAL_DAY_PARTS`,
    CLEAR_DAY_PARTS: `${ACTION_PREFIX}/CLEAR_DAY_PARTS`,
    SET_INITIAL_15_MINUTE_BINS_DAY_PARTS: `${ACTION_PREFIX}/SET_INITIAL_15_MINUTE_BINS_DAY_PARTS`,
    SET_DATA_PERIOD: `${ACTION_PREFIX}/SET_DATA_PERIOD`,
    ADD_DAY_PART: `${ACTION_PREFIX}/ADD_DAY_PART`,
    REMOVE_DAY_PART: `${ACTION_PREFIX}/REMOVE_DAY_PART`,
    SET_VALIDATION: `${ACTION_PREFIX}/SET_VALIDATION`,
    RESET_REDUCER: `${ACTION_PREFIX}/RESET_REDUCER`,
    SET_INITIAL_DATA: `${ACTION_PREFIX}/SET_INITIAL_DATA`,
    SET_AADT_YEAR: `${ACTION_PREFIX}/SET_ADDT_YEAR`,
    SET_DEFAULT_AADT_YEAR: `${ACTION_PREFIX}/SET_DEFAULT_AADT_YEAR`,
    SET_USE_CUSTOM_RANGES: `${ACTION_PREFIX}/SET_USE_CUSTOM_RANGES`,
    SET_DEFAULT_DAY_PART_SETTINGS: `${ACTION_PREFIX}/SET_DEFAULT_DAY_PART_SETTINGS`,
    SET_DEFAULT_DAY_TYPE_SETTINGS: `${ACTION_PREFIX}/SET_DEFAULT_DAY_TYPE_SETTINGS`,
};
