import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";
import { StlButton, StlPopover } from "@common/components";
import { ROUTES } from "@common/constants/routes.constants";
import { IUserAPI } from "@common/services/server/userApi.types";
import { faCaretDown, faCheck, faSignOut, faUserAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./userInfoBadge.less";

export interface IOrganization {
    status: string;
    org_id: number;
    org_name: string;
    org_note: string;
    org_type: string;
    is_eval: boolean;
    is_active: boolean;
    country_ca: boolean;
    country_us: boolean;
    org_nickname: string;
    created_date: string;
    default_area: number;
    is_study_enabled: boolean;
    active_user_count: number;
    is_enterprise_org: boolean;
    charge_code: string | null;
    task_number: string | null;
    org_expiration_date: string;
    parent_org_id: number | null;
    platform_access_type: string;
    skilljar_named_group: boolean;
    crm_account_id: string | null;
    measurement_unit: "km" | "mi";
    regional_subscription: boolean;
    is_enterprise_dashboard: boolean;
    unique_zone_quota: number | null;
    unique_zone_remain: number | null;
    per_zone_area_quota: number | null;
    crm_streetlight_account_uri: string | null;
}

const ANCHOR_ORIGIN = { vertical: "bottom", horizontal: "right" } as const;
const TRANSFORM_ORIGIN = { vertical: "top", horizontal: "right" } as const;

const ACTIONS = {
    CHANGE_ORG: "changeOrg",
    LOG_OUT: "logOut",
};

export const UserInfoBadge = <T extends IUserAPI>({
    userInfo,
    canChangeOrg,
}: {
    userInfo: T;
    canChangeOrg: boolean;
}) => {
    const { pathname } = useLocation();
    const [showUserBadge, setShowUserBadge] = useState(true);

    const { org } = userInfo;

    const history = useHistory();

    const handleOpen = () => setShowUserBadge(true);
    const handleClose = () => setShowUserBadge(false);

    const userLabel = useMemo(() => {
        return (
            <StlButton
                startIcon={<FontAwesomeIcon icon={faUserAlt} />}
                endIcon={<FontAwesomeIcon icon={faCaretDown} />}
                className={classNames(
                    "stl-user-info-badge",
                    showUserBadge && "account-action-pressed",
                )}
                onClick={handleOpen}
            >
                {userInfo.email_addr}
            </StlButton>
        );
    }, [userInfo.email_addr, showUserBadge]);

    const actions = {
        [ACTIONS.CHANGE_ORG]: () => history.push("/chooseOrg"),
        [ACTIONS.LOG_OUT]: () => history.push("/logout"),
    };

    return (
        <StlPopover
            id="user-info-dropdown"
            sourceControl={userLabel}
            className="stl-user-info-popover"
            anchorOrigin={ANCHOR_ORIGIN}
            transformOrigin={TRANSFORM_ORIGIN}
            onClose={handleClose}
            contentClickable
        >
            <div className="user-section">
                <div className="user-section-item">
                    <div className="user-section-title user-section-title--bold">User Name</div>
                    <div className="email">{userInfo.email_addr}</div>
                </div>

                <div className="user-section-item">
                    <div className="user-section-title">Accounts</div>
                    <div className="account-name">
                        <FontAwesomeIcon icon={faCheck} className="checkmark" />
                        {org.org_name}
                    </div>
                    {canChangeOrg && !pathname.startsWith(ROUTES.SAFETY) && (
                        <StlButton
                            variant="primary"
                            className="change-account-button"
                            onClick={actions[ACTIONS.CHANGE_ORG]}
                        >
                            Change Account
                        </StlButton>
                    )}
                </div>
            </div>
            <div className="actions-section">
                <StlButton className="logout-button" onClick={actions[ACTIONS.LOG_OUT]}>
                    <FontAwesomeIcon icon={faSignOut} className="sign-out" />
                    <FormattedMessage id="app.signOut" defaultMessage="Sign Out" />
                </StlButton>
            </div>
        </StlPopover>
    );
};
