import {
    BASE_VIZ_DEFAULTS,
    TIME_FILTERS,
    VIZ_MAP_LAYERS,
    VIZ_ZONE_TYPES,
} from "@app/viz3/base/state/baseViz.constants";
import {
    METRIC_TYPES,
    SEGMENT_DISTRIBUTION_METRIC_KEY,
} from "@app/viz3/npSegmentVisualization/widgets/npSegmentDistribution/npSegmentDistribution.constants";
import {
    NP_TIME_DISTRIBUTION_METRIC_TYPES,
    SEGMENT_TIME_DISTRIBUTION_METRIC_KEY,
} from "@app/viz3/npSegmentVisualization/widgets/npTimeDistribution/npSegmentTimeDistribution.constants";

export const NP_SEGMENT_ZONE_TYPE = {
    ...VIZ_ZONE_TYPES.ZONE,
    displayName: "Segments",
} as const;

export const NP_SEGMENT_ZONE_TYPES_LIST = [NP_SEGMENT_ZONE_TYPE];

export type TNPSegmentZoneTypesList = typeof NP_SEGMENT_ZONE_TYPES_LIST;

export const NP_SEGMENT_LAYERS = {
    SEGMENT: {
        code: "segment",
        label: "Segments",
        hasConfiguration: true,
    },
    ...VIZ_MAP_LAYERS,
} as const;

export type TNPSegmentLayerType = keyof typeof NP_SEGMENT_LAYERS;
export type TNPSegmentLayer = typeof NP_SEGMENT_LAYERS[keyof typeof NP_SEGMENT_LAYERS];

// Network Performance Segment Visualization Properties
export const NP_SEGMENT_VISUALIZATION_PROPERTIES = {
    TRAFFIC: {
        code: "traffic",
        label: "Volume",
        unit: "",
        [SEGMENT_DISTRIBUTION_METRIC_KEY]: METRIC_TYPES.traffic,
        [SEGMENT_TIME_DISTRIBUTION_METRIC_KEY]: NP_TIME_DISTRIBUTION_METRIC_TYPES.vmt,
    },
    CONGESTION: {
        code: "congestion",
        label: "Congestion",
        unit: "Percent",
        [SEGMENT_DISTRIBUTION_METRIC_KEY]: METRIC_TYPES.congestion,
        [SEGMENT_TIME_DISTRIBUTION_METRIC_KEY]: {},
    },
    AVG_SPEED: {
        code: "avg_speed",
        label: "Average Speed",
        units: { MPH: "Mph", KPH: "Kph" },
        [SEGMENT_DISTRIBUTION_METRIC_KEY]: METRIC_TYPES.speed,
        [SEGMENT_TIME_DISTRIBUTION_METRIC_KEY]: NP_TIME_DISTRIBUTION_METRIC_TYPES.speed,
    },
    AVG_DURATION: {
        code: "avg_duration",
        label: "Average Travel Time",
        unit: "Seconds",
        [SEGMENT_DISTRIBUTION_METRIC_KEY]: METRIC_TYPES.travel_time,
        [SEGMENT_TIME_DISTRIBUTION_METRIC_KEY]: {},
    },
    VHD: {
        code: "vhd",
        label: "VHD",
        unit: "",
        [SEGMENT_DISTRIBUTION_METRIC_KEY]: {},
        [SEGMENT_TIME_DISTRIBUTION_METRIC_KEY]: NP_TIME_DISTRIBUTION_METRIC_TYPES.vhd,
    },
    RELIABILITY: {
        code: "truck_travel_time_reliability",
        label: "Reliability",
        unit: "TTTR",
        [SEGMENT_DISTRIBUTION_METRIC_KEY]: METRIC_TYPES.truck_travel_time_reliability,
        [SEGMENT_TIME_DISTRIBUTION_METRIC_KEY]:
            NP_TIME_DISTRIBUTION_METRIC_TYPES.truck_travel_time_reliability,
    },
    SPEED_PERCENTILE: {
        code: "speed_percentile",
        label: "Speed Percentile",
        unit: "",
        [SEGMENT_DISTRIBUTION_METRIC_KEY]: METRIC_TYPES.speed,
        [SEGMENT_TIME_DISTRIBUTION_METRIC_KEY]: {},
    },
};
export const NP_SEGMENT_VISUALIZATION_PROPERTIES_LIST = Object.values(
    NP_SEGMENT_VISUALIZATION_PROPERTIES,
);
export type TVisualizationPropertyType = keyof typeof NP_SEGMENT_VISUALIZATION_PROPERTIES;
export type TVisualizationProperty =
    typeof NP_SEGMENT_VISUALIZATION_PROPERTIES[keyof typeof NP_SEGMENT_VISUALIZATION_PROPERTIES];

// Time distribution chart won't be shown if one of properties is selected
export const SPECIFIC_SEGMENT_VISUALIZATION_PROPERTIES_LIST =
    NP_SEGMENT_VISUALIZATION_PROPERTIES_LIST.filter(
        property => property.code !== NP_SEGMENT_VISUALIZATION_PROPERTIES.TRAFFIC.code,
    );

export const DEFAULT_MAP_LAYERS_VISIBILITY = {
    [NP_SEGMENT_LAYERS.SEGMENT.code]: true,
    [NP_SEGMENT_LAYERS.BASE.code]: true,
    [NP_SEGMENT_LAYERS.UNSELECTED.code]: false,
    [NP_SEGMENT_LAYERS.LABEL.code]: true,
    [NP_SEGMENT_LAYERS.LEGEND.code]: true,
} as const;

export const NP_SEGMENT_VIZ_DEFAULTS = {
    filters: {
        ...BASE_VIZ_DEFAULTS.filters,
        [TIME_FILTERS.DAY_TYPES.filterName]: [],
        [TIME_FILTERS.DAY_PARTS.filterName]: [],
    },
    visualization: {
        ...BASE_VIZ_DEFAULTS.visualization,
        visualizationProperty: NP_SEGMENT_VISUALIZATION_PROPERTIES.TRAFFIC,
        mapLayersVisibility: DEFAULT_MAP_LAYERS_VISIBILITY,
    },
};

export const TRAFFIC_BEHAVIORS = {
    zoneType: VIZ_ZONE_TYPES.ZONE,
} as const;

export type TTrafficBehaviorType = keyof typeof TRAFFIC_BEHAVIORS;
export type TTrafficBehavior = typeof TRAFFIC_BEHAVIORS[keyof typeof TRAFFIC_BEHAVIORS];

export const NETWORK_PERFORMANCE_DISTRIBUTION_CHART_TYPE = "segmentDistributionMetric";
