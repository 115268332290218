import "./app.less";

import { ProtectedRoute } from "@app/auth/protectedRoute/protectedRoute";
import { store } from "@app/store";
import { MsalProvider } from "@azure/msal-react";
import { ErrorBoundary, StlLoader } from "@common/components";
import { SnackbarsContainer } from "@common/components/snackbar/snackbarsContainer/snackbarsContainer";
import { history } from "@common/services/history.service";
import { StylesProvider } from "@material-ui/core/styles";
import { Suspense, useState } from "react";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { Router, Switch } from "react-router-dom";

import { routes } from "./app.routing";
import { AppInitializer } from "./appInitializer";
import { Tracker } from "./tracker";

const INITIAL_STATE = {
    locale: "",
    messages: null,
};

export const IS_APP_INITIATED = "isAppLaunched";

export const App = ({ msalInstance }) => {
    const [{ locale, messages }, setInitializationState] = useState(INITIAL_STATE);
    sessionStorage.setItem(IS_APP_INITIATED, "true");
    return (
        <MsalProvider instance={msalInstance}>
            <StylesProvider injectFirst>
                <Provider store={store}>
                    <Router history={history}>
                        <AppInitializer onInit={setInitializationState}>
                            <IntlProvider locale={locale} messages={messages}>
                                <ErrorBoundary>
                                    <Tracker>
                                        <Suspense fallback={<StlLoader show size="large" />}>
                                            <Switch>
                                                {routes.map(route => (
                                                    <ProtectedRoute key={route.id} {...route} />
                                                ))}
                                            </Switch>
                                        </Suspense>
                                    </Tracker>
                                    <SnackbarsContainer />
                                </ErrorBoundary>
                            </IntlProvider>
                        </AppInitializer>
                    </Router>
                </Provider>
            </StylesProvider>
        </MsalProvider>
    );
};
