import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { StlPopover } from "@common/components";
import { StlButton } from "@common/components/button/button";
import type { StlPopoverHandlers } from "@common/components/popover/popover";
import { handleNavigation } from "@common/helpers/navigation.helpers";
import { faGrid, IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./platformSwitcher.less";

export type TPlatformType = {
    label: string;
    value: string;
    icon: IconDefinition;
    subscriptions_expired: boolean;
    product_url: string;
};

const INITIAL_STATE = {
    isOpen: false,
} as const;

type TProps<T extends TPlatformType> = {
    availablePlatformTypes: T[];
};

export const PlatformSwitcher = <T extends TPlatformType>({
    availablePlatformTypes,
}: TProps<T>) => {
    const [isOpen, setIsOpen] = useState<boolean>(INITIAL_STATE.isOpen);
    const history = useHistory();
    const popover = useRef<StlPopoverHandlers | null>(null);

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleOpen = () => {
        if (!isOpen) {
            setIsOpen(true);
            return;
        }

        handleClose();
    };

    const activePlatforms = availablePlatformTypes.filter(
        platform => !platform.subscriptions_expired,
    );

    if (!activePlatforms.length) {
        return null;
    }

    return (
        <StlPopover
            id="stl-platform-switcher-popover"
            className="stl-platform-switcher-popover"
            data-testid="platform-switcher-wrapper"
            ref={popover}
            sourceControl={
                <StlButton
                    startIcon={<FontAwesomeIcon icon={faGrid} />}
                    className="platform-switcher-btn"
                    ariaLabel="Platform switcher"
                    onClick={handleOpen}
                    size="sm"
                />
            }
            disableAutoFocus={false}
            disableEnforceFocus={false}
            disableRestoreFocus={false}
            contentClickable
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <div className="stl-platform-switcher">
                <div className="switcher-header">Your Products</div>
                <div className="switch-wrapper" data-testid="platform-switcher">
                    {activePlatforms.map(platform => (
                        <StlButton
                            key={platform.value}
                            className="optional-type"
                            onMouseDown={event =>
                                handleNavigation(
                                    event,
                                    platform.value,
                                    platform.subscriptions_expired,
                                    platform.product_url,
                                    history,
                                )
                            }
                            aria-label={platform.label}
                            data-testid="optional-platform-type-btn"
                        >
                            <FontAwesomeIcon
                                className="platform-type-icon"
                                icon={platform.icon}
                                data-testid="optional-platform-type-logo"
                            />
                            <span>{platform.label}</span>
                        </StlButton>
                    ))}
                </div>
            </div>
        </StlPopover>
    );
};
