import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { StlButton, StlCard } from "@common/components";
import { ROUTES } from "@common/constants/routes.constants";
import "./status.less";

const ACCESS_DENIED_TITLE = (
    <FormattedMessage id="app.accessDenied" defaultMessage="Access Denied" />
);

const ACCESS_DENIED_DESCRIPTION = (
    <FormattedMessage
        id="app.status403.description"
        defaultMessage="You don't have access to this page. Here is an alternative page that you do have access to"
    >
        {text => <p>{text}</p>}
    </FormattedMessage>
);

const REDIRECT_TO_LOGIN_PAGE_TEXT = (
    <FormattedMessage id="app.status.redirectToLogin" defaultMessage="Redirect to Login page" />
);

export const StlStatus403 = () => {
    const history = useHistory();
    const redirectToLogin = () => history.push(ROUTES.CHOOSE_PRODUCT);

    return (
        <div className="stl-status">
            <StlCard title={ACCESS_DENIED_TITLE}>
                {ACCESS_DENIED_DESCRIPTION}
                <div className="footer">
                    <StlButton variant="primary" size="sm" onClick={redirectToLogin}>
                        {REDIRECT_TO_LOGIN_PAGE_TEXT}
                    </StlButton>
                </div>
            </StlCard>
        </div>
    );
};
