import { BASE_VIZ_ACTIONS } from "@app/viz3/base/state/baseViz.actionTypes";
import { VIZ_CODES } from "@app/viz3/base/visualizationsConfiguration";
import { merge } from "lodash-es";

import { NP_SEGMENT_VIZ_ACTIONS } from "./npSegmentViz.actionTypes";
import { NP_SEGMENT_VIZ_INITIAL_STATE } from "./npSegmentViz.state";

export const npSegmentVizReducer = (state = NP_SEGMENT_VIZ_INITIAL_STATE, { data, type }) => {
    switch (type) {
        case BASE_VIZ_ACTIONS.RESET_VIZ_STATE: {
            return NP_SEGMENT_VIZ_INITIAL_STATE;
        }

        case BASE_VIZ_ACTIONS.SET_ANALYSIS_COMMON_CONFIG: {
            let newLayerConfig;
            if (data.vizCode === VIZ_CODES.SEGMENT && data.layerConfigurations) {
                newLayerConfig = merge(
                    {},
                    { ...NP_SEGMENT_VIZ_INITIAL_STATE.map.layerConfigurations },
                    { ...data.layerConfigurations },
                );
            } else {
                newLayerConfig = NP_SEGMENT_VIZ_INITIAL_STATE.map.layerConfigurations;
            }

            return {
                ...state,
                map: {
                    ...state.map,
                    layerConfigurations: {
                        ...state.map.layerConfigurations,
                        ...newLayerConfig,
                    },
                },
            };
        }

        case NP_SEGMENT_VIZ_ACTIONS.SET_CLICKED_ZONE_ID: {
            return {
                ...state,
                map: {
                    ...state.map,
                    clickedZoneId: data.zoneId,
                },
            };
        }

        case NP_SEGMENT_VIZ_ACTIONS.SET_PRE_SELECTED_ZONE: {
            return {
                ...state,
                preSelectedZones: data,
            };
        }

        case NP_SEGMENT_VIZ_ACTIONS.SET_SEGMENT_LAYERS: {
            return {
                ...state,
                segmentLayers: data,
            };
        }

        case NP_SEGMENT_VIZ_ACTIONS.SET_LAYER_CONFIGURATION: {
            return {
                ...state,
                map: {
                    ...state.map,
                    layerConfigurations: {
                        ...state.map.layerConfigurations,
                        ...data,
                    },
                },
            };
        }

        case NP_SEGMENT_VIZ_ACTIONS.SET_VIZ_STATE: {
            return {
                ...state,
                ...data.state,
            };
        }

        default:
            return state;
    }
};
