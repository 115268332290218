import { TSelectableZoneLayers } from "@common/components/baseMap/customControls/zoneSelectionTools/hooks/useZoneSelectionControl/useZoneSelectionControl";
import {
    DEFAULT_TOOL,
    TZoneSelectionSelectedToolId,
    ZONE_SELECTION_TOOLS,
} from "@common/components/baseMap/customControls/zoneSelectionTools/zoneSelectionTools.constants";
import { ZONE_TYPES } from "@common/features/zonesManager/state/zonesManager.constants";

export const getIsLineLayers = (zoneTypeId?: string) =>
    zoneTypeId === ZONE_TYPES.OSM_LINE_SEGMENTS.id;

export const getLayerIds = (zoneTypeId: string, layers: TSelectableZoneLayers) =>
    getIsLineLayers(zoneTypeId) ? layers.line : layers.polygon;

export const getCursorType = (selectedTool?: TZoneSelectionSelectedToolId | null) => {
    let cursor;
    switch (selectedTool) {
        case ZONE_SELECTION_TOOLS.LASSO_ADD.id:
        case ZONE_SELECTION_TOOLS.LASSO_REMOVE.id:
            cursor = "crosshair";
            break;
        case ZONE_SELECTION_TOOLS.CURSOR.id:
            cursor = "pointer";
            break;
        case DEFAULT_TOOL.id:
            cursor = "";
            break;
        default:
            cursor = "grab";
    }

    return cursor;
};
