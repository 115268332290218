import { FormattedMessage } from "react-intl";

export const TIME_DISTRIBUTION_CLASS_NAME = "stl-viz3-segment-time-distribution";

export const CHART_TYPE_OPTIONS = {
    CHART: { id: "chart", name: "Chart" },
    TABLE: { id: "table", name: "Table" },
};

export const CHART_TYPE_OPTIONS_LIST = Object.values(CHART_TYPE_OPTIONS).map(option => ({
    label: option.name,
    value: option.id,
}));

export const TIME_DISTRIBUTION_METRIC_TYPES = {
    volume: {
        id: "volume",
        aggregation: "Sum",
        chartType: "timeDistributionMetric",
        ignoredFilters: null,
        dataField: "bins",
    },
    vmt: {
        id: "vmt",
        aggregation: "Sum",
        chartType: "timeDistributionMetric",
        ignoredFilters: null,
        dataField: "bins",
    },
    vhd: {
        id: "vhd",
        aggregation: "Sum",
        chartType: "timeDistributionMetric",
        ignoredFilters: null,
        dataField: "bins",
    },
    speed: {
        id: "avg_speed",
        aggregation: "Avg",
        chartType: "timeDistributionPctl",
        ignoredFilters: ["visualization_property"],
        dataField: "bins",
    },
} as const;
export type TTimeDistributionMetricTypeName = keyof typeof TIME_DISTRIBUTION_METRIC_TYPES;
export type TTimeDistributionMetricTypes = typeof TIME_DISTRIBUTION_METRIC_TYPES;
export type TTimeDistributionMetricType =
    typeof TIME_DISTRIBUTION_METRIC_TYPES[keyof typeof TIME_DISTRIBUTION_METRIC_TYPES];
export const TIME_DISTRIBUTION_METRIC_TYPES_LIST = Object.values(TIME_DISTRIBUTION_METRIC_TYPES);

export const SEGMENT_TIME_DISTRIBUTION_WIDGET_KEY = "segmentTimeDistribution";

export const SEGMENT_TIME_DISTRIBUTION_METRIC_KEY = "timeDistributionMetric";

export const OUTDATED_ANALYSIS_ERROR = (
    <FormattedMessage
        id="app.segmentTimeDistributionErrorMessage"
        defaultMessage="This metric is only available for analyses run on StreetLight Insight 9.6 and later. Please rerun the analysis to return this metric."
    />
);

export const SEGMENT_TRUCK_ANALYSIS_ERROR = (
    <FormattedMessage
        id="app.segmentTruckTimeDistributionErrorMessage"
        defaultMessage="This metric is currently unavailable for truck analyses."
    />
);
