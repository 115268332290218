import { LngLat } from "mapbox-gl";
import moment from "moment";
import { defaultState, rootReducer, TRootState } from "@app/store/root.reducer";
import { USER_PREFERENCES_ACTIONS_TO_SAVE } from "@app/store/userPreferences/userPreferences.actionTypes";
import { UserPreferencesApiService } from "@common/services/server/userPreferencesApi.service";
import { configureStore, isPlain } from "@reduxjs/toolkit";

export type { TGetState, TRootState } from "@app/store/root.reducer";

/*
 * Changes to local user-preference state should be applied regardless
 * as to whether remote-store persistence succeeds.
 *
 * Hence server-side user-preferences update is treated as a side-effect
 * of action/reduce.  It is not an action in the redux sense.
 *
 * So redux-thunk is not used in favor of a custom middleware.
 *
 * See https://redux.js.org/api/applymiddleware
 */
function userPreferencesInterceptor({ getState }: any) {
    return (next: any) => (action: any) => {
        // Call the next dispatch method in the middleware chain.
        const returnValue = next(action);

        // If this is a user-preferences update, update remote store
        if (USER_PREFERENCES_ACTIONS_TO_SAVE.find(item => item === action.type)) {
            UserPreferencesApiService.updatePreferences(getState().userPreferences);
        }

        // This will likely be the action itself, unless
        // a middleware further in chain changed it.
        return returnValue;
    };
}

export const store = configureStore({
    reducer: rootReducer,
    preloadedState: defaultState as TRootState,
    middleware: getDefaultMiddlewares => {
        const isLocalDevelopment = !!IS_LOCAL_DEVELOPMENT;
        return getDefaultMiddlewares({
            serializableCheck: isLocalDevelopment && {
                isSerializable: (value: any) => {
                    return (
                        isPlain(value) ||
                        value instanceof Date ||
                        value instanceof moment ||
                        value instanceof LngLat
                    );
                },
            },
            immutableCheck: isLocalDevelopment,
        }).concat(userPreferencesInterceptor);
    },
});

export type TAppDispatch = typeof store.dispatch;
