import type {
    Feature,
    GeoJsonProperties,
    LineString,
    MultiPolygon,
    Point,
    Polygon,
} from "geojson";
import { LngLat } from "mapbox-gl";
import { OPTION_ALL } from "@common/components/select/select.constants";
import {
    BUS_ZONE_KINDS,
    RAIL_ZONE_KINDS,
    TSelectedZoneKind,
    TZoneKind,
    TZoneKindId,
    ZONE_KINDS,
    ZONE_KINDS_LIST,
} from "@common/constants/zoneLibrary.constants";
import { ZONE_GEOM_TYPES } from "@common/constants/zoneSet.constants";
import {
    TOSMZoneType,
    TSelectedKinds,
    TStandardAreaZoneType,
    TTransitZoneType,
} from "@common/features/zonesManager/components/libraryZonesPicker/libraryZonePicker.types";
import { GateProperties } from "@common/features/zonesManager/components/spotCounter/spotCounter.types";
import { convertCalibrationToApiObject } from "@common/features/zonesManager/components/zoneCalibration/zoneCalibration.helpers";
import {
    ICustomGate,
    ICustomLineZone,
    ISelectedZones,
    TCustomZone,
    TGateId,
} from "@common/features/zonesManager/zonesManager.types";
import type {
    IGateAPI,
    ISaveNewLineZoneApiObject,
    ISaveNewLineZoneInputs,
    ISaveNewPolygonZoneApiObject,
    ISaveNewPolygonZoneInputs,
    ISaveNewSpotCounterGateApiObject,
    ISaveNewSpotCounterGateZoneInputs,
    ISaveNewZoneInputs,
    IUpdateLineZoneApiObject,
    IUpdateLineZoneGateApiObject,
    IUpdatePolygonZoneApiObject,
} from "@common/services/server/zonesApi.types";
import { arrayIncludes } from "@common/utils/arrayIncludes";
import { IntersectionWithSameTypes, PartialProperties } from "@common/utils/utility.types";
import bearing from "@turf/bearing";
import centroid from "@turf/centroid";
import { AllGeoJSON, feature as createFeature, point as createPoint } from "@turf/helpers";
import {
    DEFAULT_BUS_ZONES_BUFFER_RADIUS,
    DEFAULT_STANDARD_AREA,
    DEFAULT_ZONE_TYPE,
    DIRECTION_OPTIONS,
    ERROR_TYPES,
    MANAGER_MODES,
    OSM_SEGMENT_SPLIT_TYPES,
    PASS_THROUGH_OPTIONS,
    ROAD_TYPE_OPTIONS,
    ROAD_TYPE_OPTIONS_LIST,
    ROAD_TYPES_LIST,
    TDirectionOption,
    TOSMNetworkType,
    TRoadId,
    TRoadType,
    TRoadTypeOption,
    ZONE_GATES_OPTIONS,
    ZONE_GATES_OPTIONS_LIST,
    ZONE_TYPES,
} from "./zonesManager.constants";

type TZoneTypeSource =
    | PartialProperties<TOSMZoneType, "filters">
    | PartialProperties<TStandardAreaZoneType, "filters">
    | {
          id: "user";
      }
    | TTransitZoneType;

export const makeZoneTypeObject = (source: TZoneTypeSource) => {
    switch (source.id) {
        case ZONE_TYPES.OSM_LINE_SEGMENTS.id: {
            return {
                id: ZONE_TYPES.OSM_LINE_SEGMENTS.id,
                filters: {
                    segmentSplitType:
                        source.filters?.segmentSplitType || OSM_SEGMENT_SPLIT_TYPES.TERTIARY.id,
                },
            };
        }

        case ZONE_TYPES.STANDARD_AREAS.id: {
            return {
                id: ZONE_TYPES.STANDARD_AREAS.id,
                filters: {
                    selectedStandardArea:
                        source.filters?.selectedStandardArea || DEFAULT_STANDARD_AREA.id,
                },
            };
        }

        case ZONE_TYPES.USER.id: {
            return {
                id: ZONE_TYPES.USER.id,
            };
        }

        case ZONE_TYPES.TRANSIT.id: {
            const busFilters = source.filters[ZONE_KINDS.BUS.code] || {};
            const railFilters = source.filters[ZONE_KINDS.RAIL.code] || {};

            return {
                id: ZONE_TYPES.TRANSIT.id,
                filters: {
                    selectedTransitZoneKind: source.filters.selectedTransitZoneKind,
                    [ZONE_KINDS.BUS.code]: {
                        bufferRadius: busFilters.bufferRadius || DEFAULT_BUS_ZONES_BUFFER_RADIUS,
                        selectedAgencies: busFilters.selectedAgencies,
                        selectedLines: busFilters.selectedLines,
                        selectedKinds: busFilters.selectedKinds || {
                            [BUS_ZONE_KINDS.ROUTES.id]: true,
                        },
                    },
                    [ZONE_KINDS.RAIL.code]: {
                        selectedAgencies: railFilters.selectedAgencies,
                        selectedLines: railFilters.selectedLines,
                        selectedKinds: railFilters.selectedKinds || {
                            [RAIL_ZONE_KINDS.SEGMENTS.id]: true,
                            [RAIL_ZONE_KINDS.STATIONS.id]: true,
                        },
                    },
                },
            };
        }

        default: {
            return DEFAULT_ZONE_TYPE;
        }
    }
};

export const getAvailableRoadTypeIdsByNetworkType = (
    networkType: TOSMNetworkType,
    excludedRoads: TRoadType["id"][] = [],
) =>
    ROAD_TYPES_LIST.reduce((res: TRoadType["id"][], roadType) => {
        if (
            arrayIncludes(roadType.osmNetwork, networkType.id) &&
            !arrayIncludes(excludedRoads, roadType.id)
        ) {
            res.push(roadType.id);
        }
        return res;
    }, []);

export const getRoadIdsFromRoads = (roads: TRoadType["id"][] = []) => {
    return roads.reduce((res: TRoadId[], roadId) => {
        const _roadType = ROAD_TYPES_LIST.find(roadType => roadType.id === roadId);

        if (!_roadType) return res;

        return [...res, ..._roadType.roadIds];
    }, []);
};

export const getTransitOptionIds = <T extends { value: number | "all" }>(
    options: T[] | undefined = [],
) => {
    return options.reduce((result: number[], option: T) => {
        if (option.value === OPTION_ALL.value) return result;

        result.push(option.value);

        return result;
    }, []);
};

export const getSelectedKindsList = (selectedKinds: TSelectedKinds = {}) => {
    return Object.entries(selectedKinds).reduce((result: number[], [kind, isChecked]) => {
        if (!isChecked) return result;

        result.push(Number(kind));

        return result;
    }, []);
};

export const getSelectedZoneIdsWithRailDirection = (selectedZones: ISelectedZones) => {
    return Object.entries(selectedZones).reduce(
        (selectedZonesMap: { [key: string]: string[] }, [role, zones]) => {
            selectedZonesMap[role] = zones.map(zone => zone.zone_id.toString());
            return selectedZonesMap;
        },
        {},
    );
};

export const getZoneKindById = (zoneKindId: TZoneKindId): TZoneKind | undefined => {
    return ZONE_KINDS_LIST.find(zoneKind => arrayIncludes(zoneKind.id, zoneKindId));
};

export const getActiveZoneKindIds = (activeZoneKind?: TSelectedZoneKind | null) => {
    if (!activeZoneKind) {
        return null;
    }
    return Array.isArray(activeZoneKind.selectedKindsList)
        ? activeZoneKind.selectedKindsList
        : [...activeZoneKind.id];
};

export const getLngLatCoordinatesFromGeometry = (
    geometry: LineString | Polygon | MultiPolygon,
) => {
    let coordinates;
    if (geometry.type === "LineString") {
        coordinates = geometry.coordinates;
    } else if (geometry.type === "Polygon") {
        coordinates = geometry.coordinates[0];
    } else {
        coordinates = geometry.coordinates[0][0];
    }

    return coordinates.map(pair => LngLat.convert(pair as [number, number]));
};

export const getZoneDirectionType = <
    T extends {
        direction_type?: "UniDirectional" | "BiDirectional" | "NoDirection";
        is_bidi: boolean;
        direction: number | null;
    },
>(
    zone: T,
): TDirectionOption["id"] => {
    if (zone.direction_type) return zone.direction_type;
    if (zone.is_bidi) return DIRECTION_OPTIONS.BiDirectional.id;

    return zone.direction || zone.direction === 0
        ? DIRECTION_OPTIONS.UniDirectional.id
        : DIRECTION_OPTIONS.NoDirection.id;
};

export const getRoadTypeById = (roadTypeId?: TRoadTypeOption["id"]) => {
    if (!roadTypeId) return ROAD_TYPE_OPTIONS.BLANK;

    return (
        ROAD_TYPE_OPTIONS_LIST.find(roadType => roadType.id === roadTypeId) ??
        ROAD_TYPE_OPTIONS.BLANK
    );
};

export const getZoneGate = (gateId: TGateId) =>
    ZONE_GATES_OPTIONS_LIST.find(_gate => _gate.id === gateId)!;

export const getReversedDirection = (direction: number | null) => ((direction ?? 0) + 180) % 360;

export const createGateApiObject = ({
    zone,
    geometry,
    gateId,
}: {
    zone: ICustomLineZone<boolean>;
    geometry: MultiPolygon;
    gateId: TGateId;
}) => {
    const polygon = getLngLatCoordinatesFromGeometry(geometry);
    const { directionProperty } = getZoneGate(gateId);

    return {
        gate_type: gateId,
        polygon,
        direction: zone[directionProperty],
    };
};

export const convertCustomGateToApiObject = (
    zone: ICustomGate,
    editableFeature: Feature<LineString | Polygon | MultiPolygon> | null,
): ISaveNewSpotCounterGateApiObject => {
    const zoneData: ISaveNewSpotCounterGateApiObject = {
        customer_zone_id: zone.customer_zone_id || undefined,
        direction: zone.direction,
        is_bidi: zone.is_bidi,
        is_pass: zone.is_pass === PASS_THROUGH_OPTIONS.PassThrough.id,
        osm_segment_zone_id: zone.osm_segment_zone_id,
        osm_segment_zone_kind_id: zone.osm_segment_zone_kind_id,
        point: zone.point,
        polygon: getLngLatCoordinatesFromGeometry(editableFeature!.geometry),
        set_geom_type: ZONE_GEOM_TYPES.POLYGON.id,
        zone_name: zone.zone_name,
    };

    if (zone.is_immutable) {
        zoneData.predecessor_zone_id = zone.zone_id;
        zoneData.predecessor_zone_kind_id = zone.zone_kind_id;
    }

    return zoneData;
};

export const convertNewZoneToApiObject = (
    zone: TCustomZone<boolean> & { used_with_multiple_roles: boolean },
    editableFeature: Feature<LineString | Polygon | MultiPolygon> | null,
): ISaveNewLineZoneApiObject | ISaveNewPolygonZoneApiObject => {
    const isLineZone = zone.geom_type === ZONE_GEOM_TYPES.LINE.id;
    const newZoneGeometry = editableFeature?.geometry || zone.feature.geometry;

    const zoneData: IntersectionWithSameTypes<
        ISaveNewLineZoneApiObject,
        ISaveNewPolygonZoneApiObject
    > = {
        zone_name: zone.zone_name,
        zone_id: zone.customer_zone_id ?? undefined,
        is_bidi: zone.direction_type === DIRECTION_OPTIONS.BiDirectional.id,
        is_pass: zone.is_pass === PASS_THROUGH_OPTIONS.PassThrough.id,
        sa_version_id: -1,
        predecessor_zone_id: null,
        predecessor_zone_kind_id: null,
    };

    if (zone.is_immutable || zone.used_with_multiple_roles) {
        zoneData.predecessor_zone_id = zone.zone_id;
        zoneData.predecessor_zone_kind_id = zone.zone_kind_id;
    }

    if (isLineZone) {
        const isGateEditing =
            !!editableFeature?.properties?.gateType ||
            editableFeature?.geometry?.type !== "LineString";
        const newLineGeometry = isGateEditing
            ? zone.feature.geometry
            : (newZoneGeometry as LineString);

        return {
            ...zoneData,
            set_geom_type: ZONE_GEOM_TYPES.LINE.id,
            polyline: getLngLatCoordinatesFromGeometry(newLineGeometry),
            road_type: zone.road_type,
            gates: ZONE_GATES_OPTIONS_LIST.map(gate => {
                const gateGeometry =
                    isGateEditing && gate.id === zone.gate && editableFeature?.geometry
                        ? (editableFeature.geometry as MultiPolygon)
                        : zone[`feature${gate.id}`].geometry;

                return createGateApiObject({
                    zone,
                    geometry: gateGeometry,
                    gateId: gate.id,
                });
            }),
        };
    } else {
        return {
            ...zoneData,
            set_geom_type: ZONE_GEOM_TYPES.POLYGON.id,
            polygon: getLngLatCoordinatesFromGeometry(newZoneGeometry),
            direction:
                zone.direction_type !== DIRECTION_OPTIONS.NoDirection.id ? zone.direction : null,
        };
    }
};

export const convertZoneToApiObject = (
    zone: TCustomZone,
    editableFeature: Feature<Polygon | LineString | MultiPolygon>,
): IUpdateLineZoneApiObject | IUpdateLineZoneGateApiObject | IUpdatePolygonZoneApiObject => {
    const isLineZone = zone.geom_type === ZONE_GEOM_TYPES.LINE.id;

    const zoneData = {
        zone_name: zone.zone_name,
        zone_id: zone.customer_zone_id || null,
        is_bidi: zone.direction_type === DIRECTION_OPTIONS.BiDirectional.id,
        is_pass: zone.is_pass === PASS_THROUGH_OPTIONS.PassThrough.id,
    } as IntersectionWithSameTypes<
        IUpdateLineZoneApiObject | IUpdateLineZoneGateApiObject,
        IUpdatePolygonZoneApiObject
    >;

    if (isLineZone) {
        const getGeometriesUpdate = (feature?: Feature<MultiPolygon | LineString>) => {
            if (!feature) return {};

            if (feature.geometry.type !== "LineString") {
                // Send one gate update in case of gate editing
                return {
                    gates: [
                        createGateApiObject({
                            zone,
                            geometry: feature.geometry,
                            gateId: zone.gate,
                        }),
                    ],
                };
            } else {
                // Send line and gate geometries in case if line has been edited
                return {
                    gates: ZONE_GATES_OPTIONS_LIST.map(gate => {
                        return createGateApiObject({
                            zone,
                            geometry: zone[`feature${gate.id}`].geometry,
                            gateId: gate.id,
                        });
                    }),
                    polyline: getLngLatCoordinatesFromGeometry(feature.geometry),
                };
            }
        };

        return {
            ...zoneData,
            road_type: getRoadTypeById(zone.road_type).id,
            geom_type: zone.geom_type,
            direction: zone.direction,
            ...getGeometriesUpdate(editableFeature as Feature<MultiPolygon | LineString>),
        };
    } else {
        return {
            ...zoneData,
            direction:
                zone.direction_type !== DIRECTION_OPTIONS.NoDirection.id ? zone.direction : null,
            geom_type: zone.geom_type,
            polygon: editableFeature
                ? getLngLatCoordinatesFromGeometry(editableFeature.geometry)
                : undefined,
        };
    }
};

export const convertGateApiObjectToZone = (gateApiObject: {
    start_gate: string;
    middle_gate: string;
    end_gate: string;
    start_direction: number;
    end_direction: number;
    middle_direction: number;
    line_geom_length: number;
}) => {
    return {
        featureStart: createFeature(JSON.parse(gateApiObject.start_gate)),
        featureMiddle: createFeature(JSON.parse(gateApiObject.middle_gate)),
        featureEnd: createFeature(JSON.parse(gateApiObject.end_gate)),
        s_geom_direction: gateApiObject.start_direction,
        e_geom_direction: gateApiObject.end_direction,
        direction: gateApiObject.middle_direction,
        line_geom_length: gateApiObject.line_geom_length,
    };
};

export const convertApiObjectToCustomGate = (zoneApiObject: IGateAPI) => {
    const intersectionGeometry = zoneApiObject.osm_segments[0]?.intersection_geometry
        ? JSON.parse(zoneApiObject.osm_segments[0].intersection_geometry)
        : null;
    const intersectionFeature: Feature<Point, GateProperties> | null = intersectionGeometry
        ? createPoint(intersectionGeometry.coordinates, {
              direction: zoneApiObject.direction,
              is_pass: zoneApiObject.is_pass,
              is_bidi: zoneApiObject.is_bidi,
              zone_id: String(zoneApiObject.zone_id),
              zone_kind_id: zoneApiObject.zone_kind_id,
              zone_name: zoneApiObject.zone_name,
          })
        : null;

    return {
        ...zoneApiObject,
        zone_id: zoneApiObject.zone_id,
        customer_zone_id:
            zoneApiObject.customer_zone_id !== null ? zoneApiObject.customer_zone_id : "",
        area: zoneApiObject.area,
        direction: zoneApiObject.direction ?? null,
        direction_type: getZoneDirectionType(zoneApiObject),
        is_bidi: zoneApiObject.is_bidi,
        is_pass: zoneApiObject.is_pass
            ? PASS_THROUGH_OPTIONS.PassThrough.id
            : PASS_THROUGH_OPTIONS.NonPassThrough.id,
        geometry: JSON.parse(zoneApiObject.geometry),
        feature: createFeature(JSON.parse(zoneApiObject.geometry)),
        road_type: zoneApiObject.road_type || ROAD_TYPE_OPTIONS.BLANK.id,
        osm_segment_zone_id: zoneApiObject.primary_osm_segment_zone_id,
        osm_segment_zone_kind_id: zoneApiObject.primary_osm_segment_zone_kind_id,
        intersection: intersectionFeature,
    };
};

export const buildSaveNewZoneInputs = (
    selectedZone: TCustomZone<boolean> & {
        used_with_multiple_roles: boolean;
    },
    editableFeature: Feature<Polygon | LineString> | null,
): ISaveNewZoneInputs => {
    // @ts-ignore TODO: Remove ignore after updating TCustomZone type
    const isCustomGate = selectedZone.zone_kind_id === ZONE_KINDS.GATE.id[0];

    if (isCustomGate) {
        return {
            // @ts-ignore TODO: Remove ignore after updating TCustomZone type
            zone_inputs: convertCustomGateToApiObject(selectedZone, editableFeature),
        } as ISaveNewSpotCounterGateZoneInputs;
    }

    const data = {
        zone_inputs: convertNewZoneToApiObject(selectedZone, editableFeature),
        with_calibration: selectedZone.with_calibration,
        calibration_inputs: selectedZone.with_calibration
            ? convertCalibrationToApiObject(selectedZone.calibration_detail!)
            : undefined,
    } as ISaveNewLineZoneInputs | ISaveNewPolygonZoneInputs;

    // When editing standard zone (not ZONE_KINDS.USER) first time, need to call
    // Create Zone API with predecessor_zone_kind_id and predecessor_zone_id properties included
    if (
        selectedZone.zone_kind_id &&
        !arrayIncludes(ZONE_KINDS.USER.id, selectedZone.zone_kind_id)
    ) {
        data.zone_inputs.predecessor_zone_id = selectedZone.zone_id;
        data.zone_inputs.predecessor_zone_kind_id = selectedZone.zone_kind_id;
    }

    return data;
};

export const createDefaultCustomGate = () => {
    return {
        zone_name: "",
        zone_kind_id: ZONE_KINDS.GATE.id[0],
        is_pass: PASS_THROUGH_OPTIONS.PassThrough.id,
        direction_type: DIRECTION_OPTIONS.UniDirectional.id,
        direction: 0,
        is_bidi: false,
        geom_type: ZONE_GEOM_TYPES.POLYGON.id,
        polygon: [],
    };
};

export const createDefaultPolygonZone = () => {
    return {
        zone_name: "",
        zone_kind_id: ZONE_KINDS.USER.id[0],
        is_pass: PASS_THROUGH_OPTIONS.PassThrough.id,
        direction_type: DIRECTION_OPTIONS.UniDirectional.id,
        direction: 0,
        is_bidi: false,
        geom_type: ZONE_GEOM_TYPES.POLYGON.id,
        polygon: [],
        with_calibration: false,
    };
};

export const createDefaultLineZone = () => {
    return {
        zone_name: "",
        zone_kind_id: ZONE_KINDS.USER.id[0],
        is_pass: PASS_THROUGH_OPTIONS.PassThrough.id,
        direction_type: DIRECTION_OPTIONS.UniDirectional.id,
        direction: null,
        is_bidi: false,
        geom_type: ZONE_GEOM_TYPES.LINE.id,
        line: [],
        gate: ZONE_GATES_OPTIONS.MIDDLE_GATE.id,
        road_type: ROAD_TYPE_OPTIONS.BLANK.id,
        with_calibration: false,
    };
};

export const createDefaultZone = (geomType: "polygon" | "line") => {
    const factories = {
        polygon: createDefaultPolygonZone,
        line: createDefaultLineZone,
    };

    return factories[geomType]();
};

export const getLineDirection = ({
    geometry,
    isStart,
}: {
    geometry: LineString;
    isStart: boolean;
}): number => {
    if (isStart) {
        const coords = geometry.coordinates.slice(0, 2);

        return bearing(coords[1], coords[0]);
    } else {
        const coords = geometry.coordinates.slice(-2);

        return bearing(coords[0], coords[1]);
    }
};

// Helper to verify that all line gates has geometry
export const getLineZoneHasAllGates = (
    zone: ICustomLineZone,
    editableFeature: Feature<LineString> | null,
) => {
    const isGateEditing =
        !!editableFeature?.properties?.gateType ||
        editableFeature?.geometry?.type !== "LineString";

    return ZONE_GATES_OPTIONS_LIST.every(gate => {
        return isGateEditing && gate.id === zone.gate && editableFeature?.geometry
            ? true
            : zone[`feature${gate.id}`];
    });
};

export const convertGeometryToFeature = <
    G extends LineString | Polygon | MultiPolygon,
    P extends GeoJsonProperties,
>(
    geometry: G,
    properties: P = {} as P,
): Feature<G, P> => ({
    type: "Feature",
    geometry,
    properties,
});

export const convertLineGeoJsonToPoint = ({
    geoJson,
    isStart,
    properties,
}: {
    geoJson: string;
    isStart: boolean;
    properties: GeoJsonProperties;
}): Feature<Point> => {
    const geometry = JSON.parse(geoJson);

    return {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: isStart
                ? geometry.coordinates[0]
                : geometry.coordinates[geometry.coordinates.length - 1],
        },
        properties,
    };
};

export const convertLineGeometryToPoint = ({
    geometry,
    isStart,
    properties,
}: {
    geometry: LineString;
    isStart: boolean;
    properties: GeoJsonProperties;
}): Feature<Point> => ({
    type: "Feature",
    geometry: {
        type: "Point",
        coordinates: isStart
            ? geometry.coordinates[0]
            : geometry.coordinates[geometry.coordinates.length - 1],
    },
    properties,
});

export const convertGeometryToCentroid = ({
    geometry,
    properties = {},
}: {
    geometry: AllGeoJSON;
    properties: GeoJsonProperties;
}): Feature<Point> => {
    return {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: centroid(geometry).geometry.coordinates,
        },
        properties,
    };
};

export const getCustomGateManagerModeByErrorType = (selectedZone: ICustomGate) => {
    if (!selectedZone.error_type) return MANAGER_MODES.EDIT_SPOT_COUNTER;

    const isEditableGeometry =
        selectedZone.intersection &&
        [ERROR_TYPES.GEOM_TOO_SHORT, ERROR_TYPES.GEOM_TOO_LONG].includes(selectedZone.error_type);

    return isEditableGeometry ? MANAGER_MODES.EDIT_SPOT_COUNTER : MANAGER_MODES.PLACE_SPOT_COUNTER;
};

export const getCustomGateIndexName = (selectedZones: Array<ICustomGate>, zoneName: string) => {
    const existingNames = new Set(selectedZones.map(zone => zone.zone_name));

    if (!existingNames.has(zoneName)) {
        return zoneName;
    }

    let index = 1;
    const getPostfixedName = (_index: number) => `${zoneName} (${_index})`;
    let newName = getPostfixedName(index);

    // Find first available name with the smallest available postfix index
    while (existingNames.has(newName)) {
        index++;
        newName = getPostfixedName(index);
    }

    return newName;
};

export const getIsCustomGateNameInvalid = (
    selectedZones: Array<ICustomGate>,
    newZoneName: string,
    prevZoneName: string,
) => {
    if (!selectedZones.length) return false;

    const zonesWithSameNameCount = selectedZones.filter(
        zone => zone.zone_name === newZoneName,
    ).length;

    const isUnique =
        prevZoneName === newZoneName ? zonesWithSameNameCount > 1 : zonesWithSameNameCount > 0;

    return !newZoneName || isUnique;
};
