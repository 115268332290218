import { HttpService, IRequestConfig } from "@common/services/server/http.service";
import {
    IGetCurrentOrgBalanceResponse,
    IGetCurrentOrgStudiesResponse,
    IGetCurrentUserOrgsResponse,
    IGetCurrentUserProductOrgsResponse,
    IGetSubscriptionRegionResponse,
    IOrgData,
    IOrgResponse,
    IOrgStudiesConfig,
    IOrgStudyData,
    IUploadStagedRegionResponse,
    IZoneUpgradeData,
} from "@common/services/server/orgApi.types";

export const OrgApiService = {
    getCurrentUserOrgs() {
        return HttpService.get<IGetCurrentUserOrgsResponse>("/current_user_orgs");
    },
    getCurrentUserProductOrgs() {
        return HttpService.get<IGetCurrentUserProductOrgsResponse>("/current_user_product_orgs");
    },
    getCurrentOrgStudies(config: IRequestConfig<IOrgStudiesConfig>) {
        return HttpService.get<IGetCurrentOrgStudiesResponse>("/current_org_studies", config);
    },
    getCurrentOrgBalance() {
        return HttpService.get<IGetCurrentOrgBalanceResponse>("/org_balance");
    },
    getSubscriptionRegion() {
        return HttpService.get<IGetSubscriptionRegionResponse>("/subscription_region").catch(
            HttpService.showIfError("Unknown error occurred while fetching subscription region."),
        );
    },
    requestZoneUpgrade(data: IZoneUpgradeData) {
        return HttpService.post<IOrgResponse>("/zone_upgrade_request", data);
    },
    setOrg(data: IOrgData) {
        return HttpService.post<IOrgResponse>("/set_org", data);
    },
    setProductOrg(data: IOrgData) {
        return HttpService.post<IOrgResponse>("/set_org_all_products", data);
    },
    setStudy(data: IOrgStudyData) {
        return HttpService.post<IOrgResponse>("/set_study", data);
    },
    uploadStagedRegion(data: FormData) {
        return HttpService.post<IUploadStagedRegionResponse>("/upload/staged_region", data, {
            headers: {
                "content-type": "multipart/form-data",
            },
        }).catch(HttpService.showIfError("Unknown error occurred while uploading staged region."));
    },
};
