import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { uniqBy } from "lodash-es";
import type { Map } from "mapbox-gl";
import {
    EControlPositions,
    TSelectionToolsConfig,
} from "@common/components/baseMap/baseMap.types";
import { SelectionToolsControl } from "@common/components/baseMap/customControls/selectionToolsControl";
import { useRegionDraw } from "@common/components/baseMap/customControls/zoneSelectionTools/hooks/useRegionDraw";
import {
    IZoneSelectionHistory,
    ZoneSelectionHistory,
} from "@common/components/baseMap/customControls/zoneSelectionTools/hooks/zoneSelectionHistory";
import { SelectionToolsComponent } from "@common/components/baseMap/customControls/zoneSelectionTools/selectionToolsComponent";
import {
    TZoneSelectionSelectedToolId,
    ZONE_SELECTION_TOOLS,
} from "@common/components/baseMap/customControls/zoneSelectionTools/zoneSelectionTools.constants";
import { getUpdatedSelectedZones } from "@common/components/baseMap/customControls/zoneSelectionTools/zoneSelectionTools.helpers";
import bbox from "@turf/bbox";
import { DRAWN_POLYGON_SELECTION_FILTERS } from "./useZoneSelectionControl.constants";
import { getCursorType, getIsLineLayers, getLayerIds } from "./useZoneSelectionControl.helpers";

export type TZoneSelectionTools = {
    historyStack?: IZoneSelectionHistory<unknown> | null;
    selectedToolId: TZoneSelectionSelectedToolId | null;
    setSelectedToolId: (tool: TZoneSelectionSelectedToolId | null) => void;
};

export type TSelectableZoneLayerIds = {
    selected: string[];
    unselected: string[];
};

export type TSelectableZoneLayers = {
    line: TSelectableZoneLayerIds;
    polygon: TSelectableZoneLayerIds;
};

type TFreeSelectionProps<T> = {
    map: Map | null;
    layerIds: string[];
    isLineLayers?: boolean;
    selectedTool: TZoneSelectionSelectedToolId | null;
    onSetSegmentsGroupConfig: (segments: Array<T>) => void;
};

export const useFreeSelection = <T extends unknown>({
    map,
    layerIds,
    selectedTool,
    isLineLayers = false,
    onSetSegmentsGroupConfig,
}: TFreeSelectionProps<T>) => {
    const { removeRegion, region } = useRegionDraw({ map, selectedTool });

    useEffect(() => {
        if (
            !map ||
            !selectedTool ||
            !map.getCanvas()?.style?.cursor ||
            getCursorType(selectedTool) === map.getCanvas().style.cursor
        )
            return;

        map.getCanvas().style.cursor = getCursorType(selectedTool);
    }, [map, selectedTool]);

    useEffect(() => {
        if (!map || !region) return;

        const drawnPolygonBbox = bbox(region);

        const topMinPoint = map.project([drawnPolygonBbox[0], drawnPolygonBbox[1]]);
        const topMaxPoint = map.project([drawnPolygonBbox[2], drawnPolygonBbox[3]]);

        if (!layerIds.length) {
            removeRegion();
            return;
        }

        const layers = layerIds.filter(layer => map.getLayer(layer));
        const segmentsWithinBBox = map.queryRenderedFeatures([topMinPoint, topMaxPoint], {
            layers,
        });

        const filterFunction = isLineLayers
            ? DRAWN_POLYGON_SELECTION_FILTERS.LINE
            : DRAWN_POLYGON_SELECTION_FILTERS.POLYGON;

        const uniqSegmentsWithinBBox = uniqBy(filterFunction(segmentsWithinBBox, region), "id");

        removeRegion();

        if (!uniqSegmentsWithinBBox.length) return;

        onSetSegmentsGroupConfig(
            uniqSegmentsWithinBBox.map(({ properties }) => properties) as Array<T>,
        );
    }, [
        region,
        map,
        selectedTool,
        removeRegion,
        onSetSegmentsGroupConfig,
        layerIds,
        isLineLayers,
    ]);
};

export type TProps<T> = Partial<TSelectionToolsConfig<T>>;

export const useZoneSelectionControl = <T extends unknown>(
    map: Map | null,
    {
        layers,
        zoneTypeId,
        initialStack,
        selectedZones = [],
        showSelectionTools = true,
        disableSelectionTools = false,
        position = EControlPositions.TOP_RIGHT,
    }: TProps<T>,
) => {
    const [selectedToolId, setSelectedToolId] = useState<TZoneSelectionSelectedToolId>(
        ZONE_SELECTION_TOOLS.CURSOR.id,
    );

    const historyStackRef = useRef(new ZoneSelectionHistory<unknown>());

    useEffect(() => {
        const backwardStack = historyStackRef.current.getBackwardStack();
        const isNavigationEnabled =
            historyStackRef.current.isForwardsNavigationEnabled() ||
            historyStackRef.current.isBackwardsNavigationEnabled();

        if (initialStack && !isNavigationEnabled && !backwardStack[0].length) {
            historyStackRef.current.setInitialStack(initialStack);
        }
    }, [initialStack]);

    const isLassoAddMode = selectedToolId === ZONE_SELECTION_TOOLS.LASSO_ADD.id;
    const isLassoRemoveMode = selectedToolId === ZONE_SELECTION_TOOLS.LASSO_REMOVE.id;

    const layerIds = useMemo(() => {
        if (!showSelectionTools || !zoneTypeId || !layers) return [];

        const _layers = getLayerIds(zoneTypeId, layers);

        if (isLassoAddMode) return _layers.unselected;

        if (isLassoRemoveMode) return _layers.selected;

        return [];
    }, [layers, zoneTypeId, isLassoAddMode, isLassoRemoveMode, showSelectionTools]);

    const toolsControl = useMemo(() => new SelectionToolsControl(), []);

    const handleToolSelect = useCallback(
        (tool: TZoneSelectionSelectedToolId) => {
            if (disableSelectionTools) return;

            setSelectedToolId(tool);
        },
        [disableSelectionTools],
    );

    const onUpdateZoneSelection = useCallback(
        zones => {
            map?.fire("selectionTools.zonesSelectionChange", { zones });
        },
        [map],
    );

    const handleUpdateZoneSelection = useCallback(
        (newlySelectedZones: Array<T>, isForward?: boolean) => {
            if (!(isLassoAddMode || isLassoRemoveMode) || !historyStackRef.current) {
                return;
            }

            if (!newlySelectedZones.length) {
                historyStackRef.current.select(newlySelectedZones);

                onUpdateZoneSelection(newlySelectedZones);
                return;
            }

            const newSelectedZones = getUpdatedSelectedZones(
                selectedZones,
                newlySelectedZones,
                isLassoAddMode,
            );

            historyStackRef.current.select(newSelectedZones);

            onUpdateZoneSelection(newSelectedZones);
        },
        [isLassoAddMode, isLassoRemoveMode, onUpdateZoneSelection, selectedZones],
    );

    useEffect(() => {
        if (!map || !toolsControl || !showSelectionTools) return undefined;

        map.addControl(toolsControl, position);

        return () => {
            map.removeControl(toolsControl);
            setSelectedToolId(ZONE_SELECTION_TOOLS.CURSOR.id);
        };
    }, [map, toolsControl, position, showSelectionTools]);

    useFreeSelection<T>({
        map,
        layerIds,
        selectedTool: selectedToolId,
        isLineLayers: getIsLineLayers(zoneTypeId),
        onSetSegmentsGroupConfig: handleUpdateZoneSelection,
    });

    useEffect(() => {
        if (disableSelectionTools) setSelectedToolId(ZONE_SELECTION_TOOLS.CURSOR.id);
    }, [disableSelectionTools]);

    const mountSelectionTools = useCallback(() => {
        if (!toolsControl.toolsContainer) return null;

        return ReactDOM.createPortal(
            <SelectionToolsComponent
                selectedToolId={selectedToolId}
                onToolSelect={handleToolSelect}
                historyStack={historyStackRef.current}
                onUpdateZoneSelection={onUpdateZoneSelection}
                disableSelectionTools={disableSelectionTools}
            />,
            toolsControl.toolsContainer as HTMLDivElement,
        );
    }, [
        handleToolSelect,
        onUpdateZoneSelection,
        toolsControl.toolsContainer,
        selectedToolId,
        disableSelectionTools,
    ]);

    return {
        mountSelectionTools,
        zoneSelectionTools: {
            selectedToolId,
            setSelectedToolId,
            historyStack: historyStackRef.current,
        } as TZoneSelectionTools,
    };
};
