import { PLATFORM_ACCESS_TYPES } from "@common/constants/orgTypes.constants";

export const CRM_ID_REGEX = /^https:\/\/streetlightdata.*\.lightning\.force\.com\/\w{18}$/;

export const INITIAL_ORG_STATE_OBJ = {
    id: null,
    name: "",
    nickname: "",
    type: null,
    moduleType: null,
    parentOrgId: null,
    childOrgs: [],
    crmAccountId: "",
    crmStreetlightAccountUri: "",
    sourceSubscriptionId: "",
    orgNote: "",
    countries: [],
    measurementUnit: null,
    skilljarNamedGroup: false,
    productAccessTypes: [PLATFORM_ACCESS_TYPES.INSIGHT.value],
    hasActiveInsightSubscription: false,
    insightFullSubscriptions: [],
    insightLiteSubscriptions: [],
    constructionSubscriptions: [],
    safetyPrioritizeSubscriptions: [],
    trafficMonitorSubscriptions: [],
    isActive: false,
    isStudyEnabled: true,
    taskNumber: "",
    chargeCode: "",
    isEnterpriseOrg: false,
};

export const DEFAULT_ANALYSIS_PROCESSING_LIMIT = 250;
