import classnames from "classnames";
import { Map } from "mapbox-gl";

export class SelectionToolsControl {
    toolsContainer!: HTMLDivElement;
    _map!: Map | undefined;
    _container!: HTMLDivElement;

    onAdd = (map: Map) => {
        this._map = map;
        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl mapboxgl-ctrl-group selection-control";

        this.toolsContainer = document.createElement("div");
        this.toolsContainer.className = classnames("selection-tools-wrapper");

        this._container.appendChild(this.toolsContainer);

        return this._container;
    };

    onRemove = () => {
        this._container.parentNode?.removeChild(this._container);
        this._map = undefined;
    };
}
