import {
    setTravelerAttrEnabled,
    setTravelerBinTypeEnabled,
    updateHwlParams,
} from "@app/analysis/addOns/state/addOns.actions";
import { TRAVELER_PARAMS_US_CENSUS_2020 } from "@app/analysis/addOns/state/addOns.constants";
import {
    getHWLSettings,
    getTravelerAttrSettings,
} from "@app/analysis/addOns/state/addOns.selectors";
import { getBasics } from "@app/analysis/basics/state/basics.selectors";
import {
    AVAILABLE_AADT_YEARS_BY_TRAVEL_MODE_CODE,
    CALIBRATIONS,
    CREATE_ANALYSIS_TYPES,
    SCREEN_MODES,
} from "@app/analysis/state/analysisConfiguration.constants";
import {
    getAnalysisTypeFromCode,
    getIsAnalysisTypeSupportOption,
    getIsCVDAnalysisWithLimitedTravelerAttrs,
    getIsTravelModeSupportOption,
    getIsUSTruckVolumeAnalysis,
} from "@app/analysis/state/analysisConfiguration.helpers";
import {
    getAvailableBusRailTruckDataMonths,
    getAvailableBusRailTruckDataPeriods,
    getAvailableDataMonthsByMode,
    getAvailableDataPeriodsByMode,
    getDefaultDataMonthsByMode,
    getDefaultDataPeriodsByMode,
    getIsDefaultAadtYearSelected,
    getIsDefaultTimePeriodsSelected,
} from "@app/analysis/state/analysisConfiguration.selectors";
import { getAnalysisTypeCode, getUiStates } from "@app/analysis/state/general/general.selectors";
import { setDataPeriods } from "@app/analysis/timePeriods/components/dataPeriodParams/dataPeriodParams.actions";
import {
    setDataMonths,
    setDataPeriodSettings,
} from "@app/analysis/timePeriods/state/timePeriods.actions";
import { getHasUnsupportedTimePeriods } from "@app/analysis/timePeriods/state/timePeriods.helpers";
import {
    getAadtSettings,
    getCustomDayTypes,
    getDataMonthSettings,
    getDataPeriodSettings,
    getIsAvailableSpecificProjectDates,
    getIsCustomRangesModeSupported,
} from "@app/analysis/timePeriods/state/timePeriods.selectors";
import { setSelectedZones } from "@app/analysis/zones/chooseZones/state/chooseZones.actions";
import {
    getSupportedByTravelModeZones,
    getUnsupportedByTravelModeZones,
} from "@app/analysis/zones/chooseZones/state/chooseZones.helpers";
import { getSelectedZones } from "@app/analysis/zones/chooseZones/state/chooseZones.selectors";
import { MODES_OF_TRAVEL, ORG_COUNTRIES } from "@common/constants/analysis.constants";
import { getIsNetworkPerformanceAnalysis } from "@common/helpers/analysis";
import { batch } from "react-redux";

export const RESET_PROPERTY_ON_TRAVEL_MODE_CHANGE = {
    selectedZones: {
        id: "selectedZones",
        propertyName: "Selected Zones",
        condition({ travelModeCode, state }) {
            const selectedZones = getSelectedZones(state);
            const isTruckVolumeAnalysis = getIsUSTruckVolumeAnalysis(state);

            const shouldShowWarningAndReset = !!getUnsupportedByTravelModeZones({
                zones: selectedZones,
                travelModeCode,
                isTruckVolumeAnalysis,
            }).length;

            return {
                shouldShowWarning: shouldShowWarningAndReset,
                shouldReset: shouldShowWarningAndReset,
            };
        },
        action(dispatch, { travelModeCode, state }) {
            const selectedZones = getSelectedZones(state);
            const { country, calibrationSettings } = getBasics(state);
            const analysisTypeCode = getAnalysisTypeCode(state);
            const isTruckVolumeAnalysis = getIsUSTruckVolumeAnalysis(
                country,
                travelModeCode,
                calibrationSettings.calibrationCode,
            );
            const availableZones = getSupportedByTravelModeZones({
                selectedZones,
                travelModeCode,
                analysisTypeCode,
                isTruckVolumeAnalysis,
            });

            dispatch(setSelectedZones(availableZones));
        },
    },
    travelerAttr: {
        id: "travelerAttr",
        propertyName: "Traveler Attributes",
        getPropertyName: ({ travelModeCode, state }) => {
            const analysisTypeCode = getAnalysisTypeCode(state);

            return getIsCVDAnalysisWithLimitedTravelerAttrs(analysisTypeCode, travelModeCode)
                ? "Traveler Attributes: Trip Purpose"
                : "Traveler Attributes";
        },
        condition({ travelModeCode, state }) {
            const { enabled, tripPurposeBin } = getTravelerAttrSettings(state);
            const analysisTypeCode = getAnalysisTypeCode(state);
            const isCVDMode = travelModeCode === MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code;
            const isTruckMode = travelModeCode === MODES_OF_TRAVEL.TRUCK.code;

            let shouldShowWarningAndReset = false;

            if (getIsCVDAnalysisWithLimitedTravelerAttrs(analysisTypeCode, travelModeCode)) {
                shouldShowWarningAndReset = tripPurposeBin.enabled;
            } else if (enabled && (isCVDMode || isTruckMode)) {
                shouldShowWarningAndReset = true;
            }

            return {
                shouldShowWarning: shouldShowWarningAndReset,
                shouldReset: shouldShowWarningAndReset,
            };
        },
        action(dispatch, { travelModeCode, state }) {
            const analysisTypeCode = getAnalysisTypeCode(state);

            if (getIsCVDAnalysisWithLimitedTravelerAttrs(analysisTypeCode, travelModeCode)) {
                dispatch(
                    setTravelerBinTypeEnabled({
                        binUiStateName: TRAVELER_PARAMS_US_CENSUS_2020.TRIP_PURPOSES.uiStateName,
                        enabled: false,
                    }),
                );
                return;
            }
            dispatch(setTravelerAttrEnabled(false));
        },
    },
    hwl: {
        id: "hwl",
        propertyName: "Home and Work Locations",
        condition({ travelModeCode, state }) {
            const { enabled } = getHWLSettings(state);
            const analysisTypeCode = getAnalysisTypeCode(state);

            if (!enabled) {
                return {
                    shouldShowWarning: false,
                    shouldReset: false,
                };
            }

            const isHwlSupported =
                getIsAnalysisTypeSupportOption("homeWorkLocations", analysisTypeCode) &&
                getIsTravelModeSupportOption(travelModeCode, "homeWorkLocations");

            return {
                shouldShowWarning: !isHwlSupported,
                shouldReset: !isHwlSupported,
            };
        },
        action(dispatch, { travelModeCode, state }) {
            const { enabled } = getHWLSettings(state);
            const analysisTypeCode = getAnalysisTypeCode(state);

            dispatch(updateHwlParams(travelModeCode, analysisTypeCode, enabled));
        },
    },
    dataPeriods: {
        id: "dataPeriods",
        propertyName: "Data Periods",
        condition({ travelModeCode, state }) {
            const {
                travelModeCode: currentTravelMode,
                country,
                calibrationSettings,
            } = getBasics(state);
            const analysisTypeCode = getAnalysisTypeCode(state);
            const { screenMode } = getUiStates(state);
            const isAvailableSpecificProjectDates = getIsAvailableSpecificProjectDates(state);
            const { dataMonths } = getDataMonthSettings(state);
            const { dataPeriods, customDateRanges } = getDataPeriodSettings(state);
            const customDayTypes = getCustomDayTypes(state);
            const { aadtYear } = getAadtSettings(state);
            const isDefaultAadtYearSelected = getIsDefaultAadtYearSelected(state);
            const availableDataMonths = getAvailableDataMonthsByMode(state);
            const isDefaultTimePeriodsSelected = getIsDefaultTimePeriodsSelected(state);
            const whiteListDateRanges = getAvailableDataPeriodsByMode(state);
            // Need if switch travel mode with 'volume' calibration to rail/bus/truck(Canada) travel mode
            const availableBusRailTruckDataMonths = getAvailableBusRailTruckDataMonths(state);
            const whiteListBusRailTruckDateRanges = getAvailableBusRailTruckDataPeriods(state);

            const analysisType = getAnalysisTypeFromCode(analysisTypeCode);
            const isBusRailOrCanadaTruck =
                travelModeCode === MODES_OF_TRAVEL.BUS.code ||
                travelModeCode === MODES_OF_TRAVEL.RAIL.code ||
                (travelModeCode === MODES_OF_TRAVEL.TRUCK.code &&
                    country === ORG_COUNTRIES.CA.code);
            const isVolumeCalibrationCode =
                calibrationSettings.calibrationCode === CALIBRATIONS.VOLUME.code;

            if (analysisTypeCode === CREATE_ANALYSIS_TYPES.AADT.code) {
                const shouldReset =
                    !AVAILABLE_AADT_YEARS_BY_TRAVEL_MODE_CODE[travelModeCode].includes(aadtYear);

                return {
                    shouldReset: shouldReset,
                    shouldShowWarning: !isDefaultAadtYearSelected && shouldReset,
                    updatedData: null,
                };
            }

            // NP analyses don't support custom periods with "Truck" mode
            if (
                getIsNetworkPerformanceAnalysis(analysisTypeCode) &&
                travelModeCode === MODES_OF_TRAVEL.TRUCK_GT.code &&
                (customDayTypes.length || customDateRanges.length)
            ) {
                return {
                    shouldReset: true,
                    shouldShowWarning: true,
                    updatedData: null,
                };
            }

            if (
                (isDefaultTimePeriodsSelected && screenMode !== SCREEN_MODES.COPY_ANALYSIS.id) ||
                !analysisType.requiresDataPeriods
            ) {
                return {
                    shouldReset: true,
                    shouldShowWarning: false,
                };
            }

            const { _availableDataMonths, _whiteListDateRanges } =
                isBusRailOrCanadaTruck && isVolumeCalibrationCode
                    ? {
                          _availableDataMonths: availableBusRailTruckDataMonths,
                          _whiteListDateRanges: whiteListBusRailTruckDateRanges,
                      }
                    : {
                          _availableDataMonths: availableDataMonths,
                          _whiteListDateRanges: whiteListDateRanges,
                      };

            // We should show time periods reset warning if default time periods are NOT selected
            // or selected time periods are NOT available for new travel mode
            const { hasUnsupportedTimePeriods, updatedTimePeriods } = getHasUnsupportedTimePeriods(
                {
                    newTravelMode: travelModeCode,
                    currentTravelMode,
                    isAvailableSpecificProjectDates,
                    timePeriodsData: { dataMonths, dataPeriods },
                    availableDataMonths: _availableDataMonths,
                    whiteListDateRanges: _whiteListDateRanges,
                },
            );

            return {
                shouldShowWarning: hasUnsupportedTimePeriods,
                shouldReset: hasUnsupportedTimePeriods,
                updatedData: updatedTimePeriods,
            };
        },
        action(dispatch, { travelModeCode, state, updatedData: updatedTimePeriods }) {
            const defaultDataPeriodsByMode = getDefaultDataPeriodsByMode(state);
            const defaultDataMonthsByMode = getDefaultDataMonthsByMode(state);
            const isCustomRangesModeSupported = getIsCustomRangesModeSupported(state);

            const timePeriods = updatedTimePeriods || {
                dataPeriods: defaultDataPeriodsByMode[travelModeCode],
                dataMonths: defaultDataMonthsByMode[travelModeCode],
            };

            batch(() => {
                if (timePeriods.dataPeriods) {
                    const defaultDateRanges = timePeriods.dataPeriods.map(dateRange => ({
                        ...dateRange,
                        excludedDays: [],
                        isSelected: true,
                        isDefault: true,
                    }));
                    dispatch(setDataPeriods(timePeriods.dataPeriods));
                    if (isCustomRangesModeSupported)
                        dispatch(setDataPeriodSettings("customDateRanges", defaultDateRanges));
                }
                if (timePeriods.dataMonths) dispatch(setDataMonths(timePeriods.dataMonths));
            });
        },
    },
};

export const RESET_PROPERTY_ON_TRAVEL_MODE_CHANGE_LIST = Object.values(
    RESET_PROPERTY_ON_TRAVEL_MODE_CHANGE,
);

export const TRAVEL_MODE_CHANGE_WARNING_SCREENS = {
    COMMON: {
        id: "COMMON",
        order: 1,
        sections: {
            timePeriods: {
                name: "Time Periods",
                options: [RESET_PROPERTY_ON_TRAVEL_MODE_CHANGE.dataPeriods.id],
            },
            metrics: {
                name: "Metrics",
                options: [
                    RESET_PROPERTY_ON_TRAVEL_MODE_CHANGE.travelerAttr.id,
                    RESET_PROPERTY_ON_TRAVEL_MODE_CHANGE.hwl.id,
                ],
            },
        },
    },
    ZONES: {
        id: "ZONES",
        order: 2,
    },
};

export const ANALYSIS_NAME_ERRORS = {
    BLANK: "Analysis name field cannot be left blank. Please provide an analysis name",
    LENGTH: maxNameLength =>
        `Analysis name is too long. Please enter a shorter analysis name (max ${maxNameLength} characters allowed)`,
};

export const ANALYSIS_NAME_MAX_LENGTH = 50;
export const ANALYSIS_DESCRIPTION_MAX_LENGTH = 1000;
