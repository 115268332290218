export interface IZoneSelectionHistory<T> {
    isForwardsNavigationEnabled: () => boolean;
    isBackwardsNavigationEnabled: () => boolean;
    select: (zoneSelection: Array<T>) => void;
    moveForward: () => Array<T>;
    moveBackward: () => Array<T>;
}

export class ZoneSelectionHistory<T extends unknown> implements IZoneSelectionHistory<T> {
    private forwardStack: Array<Array<T>>;
    private backwardStack: Array<Array<T>>;

    constructor(initialValue?: Array<T>) {
        this.forwardStack = [];
        this.backwardStack = [initialValue || []];
    }

    setInitialStack({ customBackwardStack }: { customBackwardStack: Array<Array<T>> }) {
        this.backwardStack = customBackwardStack;
    }

    getForwardsStack() {
        return this.forwardStack;
    }

    getBackwardStack() {
        return this.backwardStack;
    }

    isForwardsNavigationEnabled() {
        return !!this.forwardStack.length;
    }

    isBackwardsNavigationEnabled() {
        return this.backwardStack.length > 1;
    }

    select(zoneSelection: Array<T>) {
        this.backwardStack.push(zoneSelection);
        this.forwardStack = [];
    }

    moveForward() {
        if (!this.isForwardsNavigationEnabled()) return [];

        const selection = this.forwardStack.pop();
        this.backwardStack.push(selection as Array<T>);

        return this.backwardStack[this.backwardStack.length - 1];
    }

    moveBackward() {
        if (!this.isBackwardsNavigationEnabled()) return [];

        const selection = this.backwardStack.pop();
        this.forwardStack.push(selection as Array<T>);

        return this.backwardStack[this.backwardStack.length - 1];
    }
}
