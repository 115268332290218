import {
    CALIBRATIONS,
    CALIBRATIONS_LIST,
} from "@app/analysis/state/analysisConfiguration.constants";
import { MEASUREMENT_TYPES_LIST } from "@app/viz3/base/state/baseViz.constants";
import type { TColumn } from "@common/components/table/table.types";
import {
    ANALYSIS_TYPES,
    ANALYSIS_TYPES_LIST,
    CREATE_SOURCE_OPTIONS_LIST,
    MODES_OF_TRAVEL,
    MODES_OF_TRAVEL_LIST,
    TAnalysisType,
} from "@common/constants/analysis.constants";
import { TZoneKindId, ZONE_KINDS_LIST } from "@common/constants/zoneLibrary.constants";
import type { IBulkActionAnalysesData } from "@common/services/server/adminApi.types";
import { arrayIncludes } from "@common/utils/arrayIncludes";

interface IAnalysis {
    enable_comm_traffic_attr?: boolean;
    premiumA?: boolean;
    premiumB?: boolean;
    project_id?: number;
    premiumVHW?: boolean;
    project_type: string;
    sa_version_id?: number;
    [key: string]: any;
}

interface ITableAnalysis {
    output_type: string;
    travel_mode_type: string;
    [key: string]: any;
}

interface IAnalysisDoubleColumnMap {
    [key: string]: string;
}

export const getAnalysisAddons = (analysis: IAnalysis) => {
    const addons = [];

    if (analysis.premiumA) addons.push("Trip Attributes");
    if (analysis.premiumB) addons.push("Traveler Attributes");
    if (analysis.premiumVHW) addons.push("Home and Work Locations");
    if (analysis.enable_comm_traffic_attr) addons.push("Commercial Vehicle Attributes");

    return addons;
};

export const getAnalysisTypeById = (analysisTypeId: typeof ANALYSIS_TYPES_LIST[number]["id"]) =>
    ANALYSIS_TYPES_LIST.find(analysis => analysis.id === analysisTypeId);

export const getAnalysisDisplayName = ({ project_type }: Partial<IAnalysis>) => {
    const analysisType = ANALYSIS_TYPES_LIST.find(analysis => analysis.id === project_type);
    return analysisType ? analysisType.name : "";
};

export const getZoneKindDisplayName = (zoneKind: typeof ZONE_KINDS_LIST[number]["code"]) =>
    ZONE_KINDS_LIST.find(zone => zone.code === zoneKind)?.name;

export const getZoneKindById = (zoneKindId: TZoneKindId) => {
    return ZONE_KINDS_LIST.find(zoneKind => arrayIncludes(zoneKind.id, zoneKindId));
};

export const getAnalysisTravelModeDisplayName = (
    travelModeCode: typeof MODES_OF_TRAVEL_LIST[number]["code"],
) => {
    const travelMode = MODES_OF_TRAVEL_LIST.find(mode => mode.code === travelModeCode);

    return travelMode?.name || "";
};

export const getIsAllVehiclesTravelMode = (
    travelModeCode: typeof MODES_OF_TRAVEL_LIST[number]["id"],
) =>
    [
        MODES_OF_TRAVEL.ALL_VEHICLES.code,
        MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code,
        MODES_OF_TRAVEL.ALL_VEHICLES_ONLY.code,
        MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code,
        MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.code,
        MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code,
    ].includes(travelModeCode);

export const getIsTruckTravelMode = (travelModeCode: typeof MODES_OF_TRAVEL_LIST[number]["id"]) =>
    [MODES_OF_TRAVEL.TRUCK.code, MODES_OF_TRAVEL.TRUCK_GT.code].includes(travelModeCode);

export const getIsAllVehiclesOrTruckTravelMode = (
    travelModeCode: typeof MODES_OF_TRAVEL_LIST[number]["id"],
) => getIsAllVehiclesTravelMode(travelModeCode) || getIsTruckTravelMode(travelModeCode);

export const getIsBikeOrPedTravelMode = (
    travelModeCode: typeof MODES_OF_TRAVEL_LIST[number]["id"],
) => [MODES_OF_TRAVEL.BICYCLE.code, MODES_OF_TRAVEL.PEDESTRIAN.code].includes(travelModeCode);

export const getIsPartialMonthsEnabled = () => false;

export const getMeasurementByCode = (
    measurementCode?: typeof MEASUREMENT_TYPES_LIST[number]["code"],
) => {
    const measurement = MEASUREMENT_TYPES_LIST.find(
        _measurement => _measurement.code === measurementCode,
    );

    if (measurement) return measurement;

    return CALIBRATIONS_LIST.find(calibration => calibration.code === measurementCode);
};

export const getApiAnalysesIds = (analyses: Array<IBulkActionAnalysesData>) =>
    analyses.map(analysis => `${analysis.project_id}|${analysis.sa_version_id}`);

export const pluralizeAnalysisLabel = (analysesQuantity: number) =>
    analysesQuantity === 1 ? "Analysis" : "Analyses";

export const getOutputType = (analysis: ITableAnalysis) => {
    if (analysis.output_type !== CALIBRATIONS.VOLUME.display) return analysis.output_type;

    if (analysis.travel_mode_type === MODES_OF_TRAVEL.PEDESTRIAN.code) {
        return CALIBRATIONS.PED_VOLUME.display;
    } else if (analysis.travel_mode_type === MODES_OF_TRAVEL.BICYCLE.code) {
        return CALIBRATIONS.BIKE_VOLUME.display;
    } else if (analysis.travel_mode_type === MODES_OF_TRAVEL.TRUCK.code) {
        return CALIBRATIONS.TRUCK_VOLUME.display;
    }

    return analysis.output_type;
};

export const getCreateSourceById = (id: typeof CREATE_SOURCE_OPTIONS_LIST[number]["value"]) =>
    CREATE_SOURCE_OPTIONS_LIST.find(option => option.value === id);

export const getColIdToDbIdMap = (columns: Array<TColumn> = []): IAnalysisDoubleColumnMap =>
    columns.reduce(
        (map, columnDefinition) => ({
            ...map,
            [columnDefinition.id]: columnDefinition.dbColName,
        }),
        {},
    );

export const getIsNetworkPerformanceAnalysis = (analysisId: TAnalysisType["id"]) =>
    ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT.id === analysisId ||
    ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.id === analysisId;

export const getIsNetworkPerformanceTruckGTAnalysis = (analysisId: TAnalysisType["id"]) =>
    ANALYSIS_TYPES.NETWORK_PERFORMANCE_TRUCK_SEGMENT.id === analysisId ||
    ANALYSIS_TYPES.NETWORK_PERFORMANCE_TRUCK_SPOT.id === analysisId;

export const getIsRVAnalysis = (analysisTypeId: TAnalysisType["id"]) =>
    ANALYSIS_TYPES.RV.id === analysisTypeId;

export const getIsTMCAnalysis = (analysisTypeId: TAnalysisType["id"]) =>
    [ANALYSIS_TYPES.TMC.id, ANALYSIS_TYPES.TT_TMC.id].includes(analysisTypeId);
