import { createSelector } from "reselect";
import {
    getAddOnsValidation,
    getAnalysisAddOns,
} from "@app/analysis/addOns/state/addOns.selectors";
import { ADD_ONS_INITIAL_STATE } from "@app/analysis/addOns/state/addOns.state";
import { getBasics, getBasicsValidation } from "@app/analysis/basics/state/basics.selectors";
import { BASICS_INITIAL_STATE } from "@app/analysis/basics/state/basics.state";
import {
    ANALYSIS_CONSTANTS,
    CREATE_ANALYSIS_TYPES,
    CREATE_ANALYSIS_TYPES_LIST,
    SCREEN_MODES,
    SCREEN_MODES_LIST,
    TABS,
    TCreateAnalysisType,
} from "@app/analysis/state/analysisConfiguration.constants";
import { getAnalysisTypeFromCode } from "@app/analysis/state/analysisConfiguration.helpers";
import { IInvalidField } from "@app/analysis/state/analysisConfiguration.types";
import { getDataPeriodsDayCount } from "@app/analysis/timePeriods/state/timePeriods.helpers";
import {
    getDataPeriodSettings,
    getTimePeriods,
    getTimePeriodsValidation,
} from "@app/analysis/timePeriods/state/timePeriods.selectors";
import { TIME_PERIODS_INITIAL_STATE } from "@app/analysis/timePeriods/state/timePeriods.state";
import { getZonesValidation } from "@app/analysis/zones/chooseZones/state/chooseZones.selectors";
import { METRICS_PACKAGES_CATEGORIES } from "@app/bigBang/projects/state/projects.constants";
import { getUniqAnalysisTypes } from "@app/bigBang/projects/state/projects.helpers";
import type { TAnalysisTypeCategoryFeatureId } from "@app/bigBang/projects/state/projects.types";
import type { TRootState } from "@app/store";
import { getConfigParams, getIsOrgHasFeature } from "@app/store/currentUser/currentUser.selector";
import { ANALYSIS_TYPES, MODES_OF_TRAVEL } from "@common/constants/analysis.constants";
import { getIsAllVehiclesOrTruckTravelMode } from "@common/helpers/analysis";
import { arrayIncludes } from "@common/utils/arrayIncludes";

/*
    Getter of the main object, just for the sake of convenience.
*/

export function getGeneral(state: TRootState) {
    return state.analysisConfiguration.general;
}

/*
    Getters of sub-objects of the main one. Just for the sake of convenience.
*/
export function getAnalysisTypeCode(state: TRootState) {
    return getGeneral(state).analysisTypeCode;
}
export const getAnalysisValidation = (state: TRootState) => getGeneral(state).validation;
export const getUiStates = (state: TRootState) => getGeneral(state).uiStates;
export const getIsReviewMode = (state: TRootState) =>
    getUiStates(state).screenMode === SCREEN_MODES.REVIEW.id;
export const getCopyAnalysisMode = (state: TRootState) =>
    getUiStates(state).screenMode === SCREEN_MODES.COPY_ANALYSIS.id;
export const isAnalysisTypeOf = (type: TCreateAnalysisType["code"], state: TRootState) =>
    getAnalysisTypeCode(state) === type;

/*
    This getters should be private (used only in this file) and are necessary only for proper
    `createSelector` memoization. If you want to get those properties, please use parent object.
*/

/*
    Other getters which are used in different places.
    Please create new ones with caution. We definitely don't want this file become the place
    where functions used only once and/or don't serve as helpers.
*/

export const analysisTypeObjSelector = createSelector(getAnalysisTypeCode, typeCode =>
    CREATE_ANALYSIS_TYPES_LIST.find(typeObj => typeObj.code === typeCode),
);

export const getIsZAHWLAnalysis = createSelector(
    getAnalysisTypeCode,
    getAnalysisAddOns,
    (analysisTypeCode, { hwlSettings }) =>
        analysisTypeCode === ANALYSIS_TYPES.ZA.id && hwlSettings.enabled,
);

export const getProjectFolder = (state: TRootState) => getGeneral(state).projectFolder;

export const getCensusMixingPermitted = (state: TRootState) => {
    const { travelModeCode } = getBasics(state);

    return travelModeCode === MODES_OF_TRAVEL.TRUCK.code;
};

export const getIs15MinuteBinsModeAvailable = (state: TRootState) =>
    getIsOrgHasFeature(state, "15_minute_granularity");

export const getIs15MinuteBinsDayPartsAvailable = (state: TRootState) => {
    const analysisTypeId = getAnalysisTypeCode(state);
    const { travelModeCode } = getBasics(state);
    const is15MinuteBinsModeAvailable = getIs15MinuteBinsModeAvailable(state);

    if (!is15MinuteBinsModeAvailable) return false;

    if (
        MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.code === travelModeCode ||
        !getIsAllVehiclesOrTruckTravelMode(travelModeCode)
    ) {
        return false;
    }

    return [
        CREATE_ANALYSIS_TYPES.OD.code,
        CREATE_ANALYSIS_TYPES.ZA.code,
        CREATE_ANALYSIS_TYPES.SEGMENT.code,
        CREATE_ANALYSIS_TYPES.NETWORK_OD.code,
        CREATE_ANALYSIS_TYPES.TMC.code,
        CREATE_ANALYSIS_TYPES.TT_TMC.code,
    ].includes(analysisTypeId);
};

export const getIsUpsamplingAvailable = (state: TRootState) => {
    const { excludedDataPeriods, dataPeriods } = getDataPeriodSettings(state);

    return (
        getIsOrgHasFeature(state, "15m_bin_metric_smoothing") &&
        getDataPeriodsDayCount(dataPeriods, excludedDataPeriods) <=
            getConfigParams(state).upsampling_day_count_threshold
    );
};

export const getIsReadOnlyMode = (state: TRootState) => {
    const { screenMode } = getUiStates(state);

    return !!SCREEN_MODES_LIST.find(mode => mode.id === screenMode)?.isReadOnly;
};

export const getIsReadOnlyMapMode = (state: TRootState) => {
    const { activeTab } = getUiStates(state);

    return getIsReadOnlyMode(state) || activeTab !== TABS.ZONES.id;
};

export const getCanChooseTravelMode = (state: TRootState) => {
    const analysisTypeCode = getAnalysisTypeCode(state);
    const analysisType = getAnalysisTypeFromCode(analysisTypeCode)!;

    return analysisType.canChooseTravelMode;
};

export const getCanChooseOutputType = (state: TRootState) => {
    const analysisTypeCode = getAnalysisTypeCode(state);
    const analysisType = getAnalysisTypeFromCode(analysisTypeCode)!;

    return analysisType.canChooseOutputType;
};

export const getGMCVDFeatureState = (state: TRootState) => {
    const { enable_gm_cvd } = getConfigParams(state);

    return CREATE_ANALYSIS_TYPES_LIST.reduce((res, analysisType) => {
        const featureState = analysisType.gmCVDFeatureName
            ? getIsOrgHasFeature(state, analysisType.gmCVDFeatureName, {
                  withoutSuperUser: true,
              })
            : false;
        return {
            ...res,
            [analysisType.code]: {
                enabled: !!enable_gm_cvd && featureState,
                supported: !!analysisType.gmCVDFeatureName,
            },
        };
    }, {} as Record<TCreateAnalysisType["code"], { enabled: boolean; supported: boolean }>);
};

export const getIsGMCVDEnabled = (state: TRootState) => {
    const gmCVDFeatureState = getGMCVDFeatureState(state);
    const analysisTypeCode = getAnalysisTypeCode(state);

    return gmCVDFeatureState[analysisTypeCode].enabled;
};

export const getIsAnalysisTypeEnabled = (state: TRootState, type: TCreateAnalysisType) => {
    return (
        getIsOrgHasFeature(state, type.featureName) &&
        type.travelModes.some(travelMode =>
            travelMode.featureNames.some(featureName => getIsOrgHasFeature(state, featureName)),
        )
    );
};

export const getAnalysisTypesOptions = (state: TRootState) => {
    const projectFolder = getProjectFolder(state);

    const getOptions = () => {
        if (!projectFolder?.project_folder_id) return ANALYSIS_CONSTANTS.analysisTypeHelp.options;

        let hasAADTAnalysis = false;

        const availableAnalysisTypes = getUniqAnalysisTypes(projectFolder.metrics_packages).reduce(
            (result, type) => {
                if (
                    METRICS_PACKAGES_CATEGORIES.ANALYSIS_TYPES.types[
                        type.feature_id as TAnalysisTypeCategoryFeatureId
                    ].code
                        .toLowerCase()
                        .includes(CREATE_ANALYSIS_TYPES.AADT.code) &&
                    !hasAADTAnalysis
                ) {
                    hasAADTAnalysis = true;
                    return [...result, CREATE_ANALYSIS_TYPES.AADT.code];
                }

                return [
                    ...result,
                    METRICS_PACKAGES_CATEGORIES.ANALYSIS_TYPES.types[
                        type.feature_id as TAnalysisTypeCategoryFeatureId
                    ].code,
                ];
            },
            [] as TCreateAnalysisType["code"][],
        );

        return ANALYSIS_CONSTANTS.analysisTypeHelp.options.filter(option =>
            arrayIncludes(availableAnalysisTypes, option.code),
        );
    };

    return getOptions().map(option => {
        let analysisTypeEnabled;
        if (option.features) {
            analysisTypeEnabled = option.features.some(featureCombo =>
                featureCombo.every(featureName => getIsOrgHasFeature(state, featureName)),
            );
        } else {
            analysisTypeEnabled = option.analysesTypes.some(type =>
                getIsAnalysisTypeEnabled(state, type),
            );
        }

        return {
            ...option,
            enabled: analysisTypeEnabled && option.code !== CREATE_ANALYSIS_TYPES.CONGESTION.code,
        };
    });
};

/*
    re-select selectors.
    Implements memoization. Use them in case of some calculations you need to perform together
    with store selectors.
*/
export const invalidFieldsSelector = createSelector(
    getBasicsValidation,
    getZonesValidation,
    getAddOnsValidation,
    getTimePeriodsValidation,
    (basicsValidation, zonesValidation, addOnsValidation, timePeriodsValidation) => {
        return Object.values({
            ...basicsValidation.fields,
            ...zonesValidation.fields,
            ...addOnsValidation.fields,
            ...timePeriodsValidation.fields,
        }).filter(
            field =>
                field &&
                (field as IInvalidField).isInvalid &&
                (field as IInvalidField).reasons.length > 0,
        );
    },
);

export const analysisHasPendingChanges = createSelector(
    getBasics,
    getAnalysisAddOns,
    getTimePeriods,
    (basicsState, addOnsState, timePeriodsState) => {
        const compareStates = (
            initialState: Record<string, any>,
            currentState: Record<string, any>,
            properties: string[],
        ) =>
            properties.some(
                property =>
                    JSON.stringify(initialState[property]) !==
                    JSON.stringify(currentState[property]),
            );

        const basicsHasChanges = compareStates(BASICS_INITIAL_STATE, basicsState, [
            "name",
            "description",
            "travelModeCode",
        ]);
        const aadtCalibrationHasChanges = compareStates(
            BASICS_INITIAL_STATE.calibrationSettings,
            basicsState.calibrationSettings,
            ["aadtCalibrationYear"],
        );

        const timePeriodsHasChanges = compareStates(TIME_PERIODS_INITIAL_STATE, timePeriodsState, [
            "dayTypeSettings",
            "dayPartSettings",
            "dataPeriodSettings",
            "dataMonthSettings",
            "aadtSettings",
        ]);

        const addOnsHasChanges = compareStates(ADD_ONS_INITIAL_STATE, addOnsState, [
            "routeOptions",
            "presetGeographyType",
        ]);

        const adminSettingsHasChanges = compareStates(
            ADD_ONS_INITIAL_STATE.adminSettings,
            addOnsState.adminSettings,
            ["enable_viz", "bypassPrivacyCheck", "bypassSizeValidationCheck"],
        );
        const tripAttrHasChanges = compareStates(
            ADD_ONS_INITIAL_STATE.tripAttrSettings,
            addOnsState.tripAttrSettings,
            ["enabled"],
        );
        const travelerAttrHasChanges = compareStates(
            ADD_ONS_INITIAL_STATE.travelerAttrSettings,
            addOnsState.travelerAttrSettings,
            ["enabled"],
        );
        const hwlHasChanges = compareStates(
            ADD_ONS_INITIAL_STATE.hwlSettings,
            addOnsState.hwlSettings,
            ["enabled"],
        );

        return (
            basicsHasChanges ||
            aadtCalibrationHasChanges ||
            addOnsHasChanges ||
            adminSettingsHasChanges ||
            tripAttrHasChanges ||
            travelerAttrHasChanges ||
            hwlHasChanges ||
            timePeriodsHasChanges
        );
    },
);
