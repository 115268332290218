import { mergeWith } from "lodash-es";
import { TTravelModePopoverOption } from "@app/analysis/basics/components/travelModeSection/travelModeSection.types";
import {
    ALL_VEHICLES_TRAVEL_MODES,
    CREATE_ANALYSIS_TYPES,
    TRAVEL_MODES,
} from "@app/analysis/state/analysisConfiguration.constants";
import { MODES_OF_TRAVEL, TTravelMode } from "@common/constants/analysis.constants";
import { getIsNetworkPerformanceAnalysis } from "@common/helpers/analysis";
import { ValuesOfType } from "@common/utils/utility.types";
import { faBicycle, faBus, faCar, faSubway, faWalking } from "@fortawesome/pro-solid-svg-icons";
import AllVehiclesByWeightIcon from "@root/src/assets/img/all-vehicles-by-weight-icon.svg";
import TrucksIcon from "@root/src/assets/img/trucks-icon.svg";

export const COMMERCIAL_VEHICLE_TYPE_OPTIONS = {
    LIGHT: {
        value: "Light",
        label: "Light Duty",
        config: { cvHeavy: false, cvMedium: false, cvLight: true },
    },
    HEAVY: {
        value: "Heavy",
        label: "Heavy Duty",
        config: { cvHeavy: true, cvMedium: false, cvLight: false },
    },
    HEAVY_AND_MEDIUM: {
        value: "HeavyAndMedium",
        label: "Heavy Duty, Medium Duty",
        config: { cvHeavy: true, cvMedium: true, cvLight: false },
    },
};

export const COMMERCIAL_VEHICLE_TYPE_OPTIONS_LIST = Object.values(COMMERCIAL_VEHICLE_TYPE_OPTIONS);

const ALL_VEHICLES_TYPE_OPTIONS = {
    ALL_VEHICLES_CVD: {
        ...ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_CVD,
        getIsSelected: ({ travelModeCode }: { travelModeCode: string }) => {
            return travelModeCode === ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_CVD.code;
        },
        getIsAvailable: () => true,
        getIsDisabled: ({ analysisTypeCode }: { analysisTypeCode: string }) => {
            return [
                CREATE_ANALYSIS_TYPES.CONGESTION.code,
                CREATE_ANALYSIS_TYPES.K_FACTOR_ESTIMATION.code,
                CREATE_ANALYSIS_TYPES.AADT.code,
            ].includes(analysisTypeCode);
        },
    },
    ALL_VEHICLES_LBS: {
        ...ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_LBS,
        getIsSelected: ({ travelModeCode }: { travelModeCode: string }) => {
            return travelModeCode === ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_LBS.code;
        },
        getIsAvailable: () => true,
        getIsDisabled: () => false,
    },
    ALL_VEHICLES_TOMTOM: {
        ...ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_TOMTOM,
        getIsSelected: ({ travelModeCode }: { travelModeCode: string }) => {
            return travelModeCode === ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_TOMTOM.code;
        },
        getIsAvailable: ({
            analysisTypeCode,
            featureEnabled,
        }: {
            analysisTypeCode: string;
            featureEnabled: boolean;
        }) => getIsNetworkPerformanceAnalysis(analysisTypeCode) && featureEnabled,
        getIsDisabled: ({ analysisTypeCode }: { analysisTypeCode: string }) =>
            !getIsNetworkPerformanceAnalysis(analysisTypeCode),
    },
    ALL_VEHICLES_TOMTOM_API: {
        ...ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_TOMTOM_API,
        getIsSelected: ({ travelModeCode }: { travelModeCode: string }) => {
            return travelModeCode === ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_TOMTOM_API.code;
        },
        getIsAvailable: ({
            analysisTypeCode,
            featureEnabled,
        }: {
            analysisTypeCode: string;
            featureEnabled: boolean;
        }) => analysisTypeCode === CREATE_ANALYSIS_TYPES.NETWORK_OD.code && featureEnabled,
        getIsDisabled: ({ analysisTypeCode }: { analysisTypeCode: string }) =>
            analysisTypeCode !== CREATE_ANALYSIS_TYPES.NETWORK_OD.code,
    },
};

export const TRAVEL_MODES_OPTIONS: Record<
    TTravelMode["code"],
    {
        travelMode:
            | ValuesOfType<typeof ALL_VEHICLES_TRAVEL_MODES>
            | ValuesOfType<typeof TRAVEL_MODES>;
        commercialVehiclesTypeOptions?: ValuesOfType<typeof COMMERCIAL_VEHICLE_TYPE_OPTIONS>[];
        allVehiclesDataTypeOptions?: TTravelModePopoverOption[];
        icon?: any;
        CustomIcon?: any;
    }
> = {
    [MODES_OF_TRAVEL.ALL_VEHICLES.code]: {
        travelMode: ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_LBS,
        icon: faCar,
        allVehiclesDataTypeOptions: [
            mergeWith({}, ALL_VEHICLES_TYPE_OPTIONS.ALL_VEHICLES_CVD),
            mergeWith({}, ALL_VEHICLES_TYPE_OPTIONS.ALL_VEHICLES_LBS),
        ],
    },
    [MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code]: {
        travelMode: ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_CVD,
        icon: faCar,
        allVehiclesDataTypeOptions: [
            mergeWith({}, ALL_VEHICLES_TYPE_OPTIONS.ALL_VEHICLES_CVD),
            mergeWith({}, ALL_VEHICLES_TYPE_OPTIONS.ALL_VEHICLES_LBS),
        ],
    },
    [MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code]: {
        travelMode: ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_TOMTOM,
        icon: faCar,
        allVehiclesDataTypeOptions: [mergeWith({}, ALL_VEHICLES_TYPE_OPTIONS.ALL_VEHICLES_TOMTOM)],
    },
    [MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code]: {
        travelMode: ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_TOMTOM_API,
        icon: faCar,
        allVehiclesDataTypeOptions: [
            mergeWith({}, ALL_VEHICLES_TYPE_OPTIONS.ALL_VEHICLES_TOMTOM_API),
        ],
    },
    [MODES_OF_TRAVEL.ALL_VEHICLES_ONLY.code]: {
        icon: faCar,
        travelMode: ALL_VEHICLES_TRAVEL_MODES.ALL_VEHICLES_ONLY,
    },
    [MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.code]: {
        travelMode: TRAVEL_MODES.ALL_VEHICLES_BY_WEIGHT,
        CustomIcon: AllVehiclesByWeightIcon,
    },
    [MODES_OF_TRAVEL.TRUCK.code]: {
        travelMode: TRAVEL_MODES.TRUCK,
        CustomIcon: TrucksIcon,
        commercialVehiclesTypeOptions: [
            COMMERCIAL_VEHICLE_TYPE_OPTIONS.HEAVY,
            COMMERCIAL_VEHICLE_TYPE_OPTIONS.HEAVY_AND_MEDIUM,
        ],
    },
    [MODES_OF_TRAVEL.TRUCK_GT.code]: {
        travelMode: TRAVEL_MODES.TRUCK_GT,
        CustomIcon: TrucksIcon,
        commercialVehiclesTypeOptions: [
            COMMERCIAL_VEHICLE_TYPE_OPTIONS.LIGHT,
            COMMERCIAL_VEHICLE_TYPE_OPTIONS.HEAVY_AND_MEDIUM,
        ],
    },
    [MODES_OF_TRAVEL.BICYCLE.code]: {
        travelMode: TRAVEL_MODES.BICYCLE,
        icon: faBicycle,
    },
    [MODES_OF_TRAVEL.PEDESTRIAN.code]: {
        travelMode: TRAVEL_MODES.PEDESTRIAN,
        icon: faWalking,
    },
    [MODES_OF_TRAVEL.BUS.code]: {
        travelMode: TRAVEL_MODES.BUS,
        icon: faBus,
    },
    [MODES_OF_TRAVEL.RAIL.code]: {
        travelMode: TRAVEL_MODES.RAIL,
        icon: faSubway,
    },
};
