import { forwardRef, useState } from "react";
import classNames from "classnames";
import { StlButton, StlInput, StlTooltip } from "@common/components";
import { faMagnifyingGlass, faSliders,faTimes, faUpDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./analysisSearchInput.less";

export const AnalysisSearchInput = forwardRef<
    HTMLDivElement,
    {
        search: string;
        showFilters?: boolean;
        setSearch: (value: string) => void;
        isSticky?: boolean;
        onExpandFilters?: () => void;
        onToggleFilters?: () => void;
        filtersCount?: { advanced: number; narrow: number };
    }
>(
    (
        {
            search,
            showFilters,
            isSticky,
            setSearch,
            onToggleFilters,
            onExpandFilters,
            filtersCount,
        },
        ref,
    ) => {
        const [isFocused, setIsFocused] = useState(false);

        const totalFiltersCount = filtersCount ? filtersCount.advanced + filtersCount.narrow : 0;
        const totalFiltersCountStr = filtersCount
            ? `${totalFiltersCount} Filter${totalFiltersCount > 1 ? "s" : ""} Applied`
            : "";
        const ariaLabel = `${showFilters ? "Hide" : "Show"} filters${
            filtersCount?.advanced
                ? ` (${filtersCount.advanced} filter${
                      filtersCount.advanced > 1 ? "s" : ""
                  } applied)`
                : ""
        }`;

        return (
            <div className="stl-analysis-search-input" ref={ref}>
                <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className={classNames("search-icon", isFocused && "focused")}
                />
                <StlInput
                    value={search}
                    onChange={value => setSearch(value)}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    placeholder="Search by Analysis Type or Keyword"
                    autoFocus
                />
                <div className="right-controls-wrapper">
                    {search && (
                        <StlButton
                            testid="clearSearch"
                            className="input-btn clear-search-btn"
                            ariaLabel="Clear search query"
                            variant="link"
                            size="lg"
                            onClick={() => setSearch("")}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </StlButton>
                    )}
                    {!isSticky && !!onToggleFilters && (
                        <StlTooltip arrow title={ariaLabel}>
                            <StlButton
                                testid="toggleFilters"
                                className={classNames("input-btn toggle-filters-btn", {
                                    enabled: showFilters,
                                })}
                                ariaLabel={ariaLabel}
                                aria-expanded={showFilters}
                                variant="link"
                                size="lg"
                                onClick={onToggleFilters}
                            >
                                <FontAwesomeIcon icon={showFilters ? faSliders : faUpDown} />
                            </StlButton>
                        </StlTooltip>
                    )}
                    {isSticky && !!onToggleFilters && (
                        <span className="total-filters-count">{totalFiltersCountStr}</span>
                    )}
                    {isSticky && !!onExpandFilters && (
                        <StlButton
                            testid="showAllFilters"
                            className="input-btn expand-filters-btn"
                            variant="link"
                            size="lg"
                            onClick={onExpandFilters}
                        >
                            <FontAwesomeIcon icon={faUpDown} />
                        </StlButton>
                    )}
                </div>
            </div>
        );
    },
);
