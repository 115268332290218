export const TIME_DISTRIBUTION_CLASS_NAME = "stl-viz3-np-segment-time-distribution";

export const CHART_TYPE_OPTIONS = {
    CHART: { id: "chart", name: "Chart" },
    TABLE: { id: "table", name: "Table" },
};

export const CHART_TYPE_OPTIONS_LIST = Object.values(CHART_TYPE_OPTIONS).map(option => ({
    label: option.name,
    value: option.id,
}));

export const NP_TIME_DISTRIBUTION_METRIC_TYPES = {
    volume: {
        id: "volume",
        aggregation: "Sum",
        chartType: "timeDistributionMetric",
        ignoredFilters: null,
        dataField: "bins",
    },
    vmt: {
        id: "vmt",
        aggregation: "Sum",
        chartType: "timeDistributionMetric",
        ignoredFilters: null,
        dataField: "bins",
    },
    vhd: {
        id: "vhd",
        aggregation: "Sum",
        chartType: "timeDistributionMetric",
        ignoredFilters: null,
        dataField: "bins",
    },
    speed: {
        id: "avg_speed",
        aggregation: "Avg",
        chartType: "timeDistributionPctl",
        ignoredFilters: ["visualization_property"],
        dataField: "percentiles",
    },
    truck_travel_time_reliability: {
        id: "truck_travel_time_reliability",
        aggregation: "Avg",
        chartType: "timeDistributionMetric",
        ignoredFilters: null,
        dataField: "bins",
    },
} as const;

export type TNPSegmentTimeDistributionMetricTypeName =
    keyof typeof NP_TIME_DISTRIBUTION_METRIC_TYPES;
export type TNPSegmentTimeDistributionMetricTypes = typeof NP_TIME_DISTRIBUTION_METRIC_TYPES;
export type TNPSegmentTimeDistributionMetricType =
    typeof NP_TIME_DISTRIBUTION_METRIC_TYPES[keyof typeof NP_TIME_DISTRIBUTION_METRIC_TYPES];
export const NP_TIME_DISTRIBUTION_METRIC_TYPES_LIST = Object.values(
    NP_TIME_DISTRIBUTION_METRIC_TYPES,
);

export const NETWORK_PERFORMANCE_TIME_DISTRIBUTION_METRICS = {
    chartProperty: {
        [NP_TIME_DISTRIBUTION_METRIC_TYPES.volume.id]: "length_weighted_avg_metric",
        [NP_TIME_DISTRIBUTION_METRIC_TYPES.speed.id]: "avg_speed",
        [NP_TIME_DISTRIBUTION_METRIC_TYPES.vmt.id]: "vmt",
        [NP_TIME_DISTRIBUTION_METRIC_TYPES.vhd.id]: "vhd",
        [NP_TIME_DISTRIBUTION_METRIC_TYPES.truck_travel_time_reliability.id]:
            "truck_travel_time_reliability",
    },
    csvExportProperty: {
        [NP_TIME_DISTRIBUTION_METRIC_TYPES.volume.id]: "traffic",
        [NP_TIME_DISTRIBUTION_METRIC_TYPES.speed.id]: "avg_speed",
        [NP_TIME_DISTRIBUTION_METRIC_TYPES.vmt.id]: "vmt",
        [NP_TIME_DISTRIBUTION_METRIC_TYPES.vhd.id]: "vhd",
        [NP_TIME_DISTRIBUTION_METRIC_TYPES.truck_travel_time_reliability.id]:
            "truck_travel_time_reliability",
    },
} as const;

export type TNetworkPerformanceTimeDistributionMetricType =
    keyof typeof NETWORK_PERFORMANCE_TIME_DISTRIBUTION_METRICS.chartProperty;
export type TNetworkPerformanceTimeDistributionMetric =
    typeof NETWORK_PERFORMANCE_TIME_DISTRIBUTION_METRICS.chartProperty[keyof typeof NETWORK_PERFORMANCE_TIME_DISTRIBUTION_METRICS.chartProperty];

export const NETWORK_PERFORMANCE_AVG_SPEED_METRICS = {
    SPEED_PERCENTILE_5: {
        id: 5,
        code: "speed_percentile_5",
    },
    SPEED_PERCENTILE_15: {
        id: 15,
        code: "avg_speed",
    },
    SPEED_PERCENTILE_85: {
        id: 85,
        code: "speed_percentile_85",
    },
    SPEED_PERCENTILE_95: {
        id: 95,
        code: "speed_percentile_95",
    },
} as const;

export const SEGMENT_TIME_DISTRIBUTION_WIDGET_KEY = "segmentTimeDistribution";

export const SEGMENT_TIME_DISTRIBUTION_METRIC_KEY = "timeDistributionMetric";
