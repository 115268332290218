import type { Feature, LineString, Polygon } from "geojson";
import type { MapboxGeoJSONFeature } from "mapbox-gl";
import { ZONE_KINDS } from "@common/constants/zoneLibrary.constants";
import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { point } from "@turf/helpers";
import intersect from "@turf/intersect";

export const DEFAULT_TOOL = {
    id: "default",
    name: "Pan tool",
    img: {
        selected: "/img/zone-selection-tools/hand_selected.svg",
        unselected: "/img/zone-selection-tools/hand.svg",
    },
};

type TDefaultTool = typeof DEFAULT_TOOL;

export const ZONE_SELECTION_TOOLS = {
    CURSOR: {
        id: "cursor",
        name: "Add/Remove to Selection",
        img: {
            selected: "/img/zone-selection-tools/cursor_selected.svg",
            unselected: "/img/zone-selection-tools/cursor.svg",
        },
    },
    LASSO_ADD: {
        id: "lasso_add",
        name: "Lasso select",
        img: {
            selected: "/img/zone-selection-tools/lasso_plus_selected.svg",
            unselected: "/img/zone-selection-tools/lasso_plus.svg",
        },
    },
    LASSO_REMOVE: {
        id: "lasso_remove",
        name: "Lasso deselect",
        img: {
            selected: "/img/zone-selection-tools/lasso_minus_selected.svg",
            unselected: "/img/zone-selection-tools/lasso_minus.svg",
        },
    },
} as const;

export type TZoneSelectionSelectedTool =
    typeof ZONE_SELECTION_TOOLS[keyof typeof ZONE_SELECTION_TOOLS];

export type TZoneSelectionSelectedToolId = TZoneSelectionSelectedTool["id"] | TDefaultTool["id"];

export const DRAWN_POLYGON_SELECTION_FILTERS = {
    LINE: (layers: Array<MapboxGeoJSONFeature>, drawnPolygon: Polygon) =>
        layers.filter(feature => {
            return (
                feature.properties?.zone_kind_id !== ZONE_KINDS.USER.id[0] &&
                (feature.geometry as LineString).coordinates.some(coords =>
                    booleanPointInPolygon(point(coords), drawnPolygon),
                )
            );
        }),
    POLYGON: (layers: Array<MapboxGeoJSONFeature>, drawnPolygon: Polygon) =>
        layers.filter(feature => {
            return (
                feature.properties?.zone_kind_id !== ZONE_KINDS.USER.id[0] &&
                intersect(feature as Feature<Polygon>, drawnPolygon) !== null
            );
        }),
};
