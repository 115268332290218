import { useCallback, useEffect, useState } from "react";
import { Map } from "mapbox-gl";
import { StlToggleButton, StlToggleButtonGroup } from "@common/components";
import { useShowCustomTooltip } from "@common/components/baseMap";
import { MAP_DRAW_EVENTS } from "@common/components/baseMap/baseMap.constants";
import { useDrawAndTrack } from "./hooks/useDrawAndTrack";
import { AreaMeasurementTool, DistanceMeasurementTool } from "./components";
import {
    AREA_MEASUREMENT_OPTIONS,
    MEASUREMENT_MODES,
    MEASUREMENT_MODES_LIST,
    TAreaMeasurementUnits,
    TDistanceMeasurementUnits,
    TMeasurementTypes,
} from "./constants";
import "./measurementTool.less";

const INITIAL_STATE = {
    measurementMode: MEASUREMENT_MODES.area.id,
    isMeasurementActive: false,
    measurementMetric: AREA_MEASUREMENT_OPTIONS.feet,
};

export const MeasurementTool = ({ map, onClose }: { map: Map; onClose: () => void }) => {
    const [measurementMode, setMeasurementMode] = useState<TMeasurementTypes["id"]>(
        INITIAL_STATE.measurementMode,
    );
    const [isMeasurementActive, setIsMeasurementActive] = useState<boolean>(
        INITIAL_STATE.isMeasurementActive,
    );
    const [measurementMetric, setMeasurementMetric] = useState<
        TAreaMeasurementUnits | TDistanceMeasurementUnits | null
    >(INITIAL_STATE.measurementMetric);
    const isAreaMeasurementMode = measurementMode === MEASUREMENT_MODES.area.id;

    const toggleActiveMeasurementState = useCallback(
        ({ isActive }: { isActive: boolean }) => {
            if (!map) return;

            map.fire(MAP_DRAW_EVENTS.MEASUREMENT, { isActive });
            setIsMeasurementActive(isActive);
        },
        [map],
    );

    useEffect(() => {
        if (!map) return undefined;

        map.on("measurementControl.active", toggleActiveMeasurementState);

        return () => {
            map.off("measurementControl.active", toggleActiveMeasurementState);
        };
    }, [map, toggleActiveMeasurementState]);

    const { draw, value, isDrawn, reset } = useDrawAndTrack({
        map,
        isMeasurementActive,
        measurementMetric,
        measurementMode,
    });

    const resetMeasuring = () => {
        if (!draw || !map) return;

        draw.deleteAll();

        if (measurementMode === MEASUREMENT_MODES.area.id) {
            //@ts-ignore
            draw.changeMode(MEASUREMENT_MODES.area.drawMode);
        } else {
            draw.changeMode(MEASUREMENT_MODES.distance.drawMode);
        }

        map.getCanvas().style.cursor = "crosshair";
        reset();
    };

    const cancelMeasuring = () => {
        if (!draw) return;

        onClose();
        setMeasurementMetric(INITIAL_STATE.measurementMetric);
        setIsMeasurementActive(INITIAL_STATE.isMeasurementActive);
        setMeasurementMode(INITIAL_STATE.measurementMode);
        reset();
        draw.deleteAll();
    };

    useShowCustomTooltip({
        map,
        content: value,
        show: !!value && !isDrawn,
        className: "stl-custom-mapbox-popup-direction",
    });

    const Component = isAreaMeasurementMode ? AreaMeasurementTool : DistanceMeasurementTool;

    return (
        <div className="stl-measurement-tool">
            Measurement Tool
            <StlToggleButtonGroup
                value={measurementMode}
                size="md"
                onChange={(_, selectedMode) => {
                    resetMeasuring();
                    setMeasurementMode(selectedMode as TMeasurementTypes["id"]);
                }}
            >
                {MEASUREMENT_MODES_LIST.map(mode => (
                    <StlToggleButton key={mode.id} id={mode.id} aria-label={`Show ${mode.name}`}>
                        {mode.name}
                    </StlToggleButton>
                ))}
            </StlToggleButtonGroup>
            <Component
                outputValue={value}
                //@ts-ignore
                measurementMetric={measurementMetric}
                setMeasurementMetric={setMeasurementMetric}
                resetMeasuring={resetMeasuring}
                cancelMeasuring={cancelMeasuring}
            />
        </div>
    );
};
