import { useMemo } from "react";
import { useAppDispatch, useAppSelector } from "@app/store/hooks";
import { patchMapLayersSettings } from "@app/store/userPreferences/userPreferences.actions";
import { getEnhancedLayersCategories } from "@app/store/userPreferences/userPreferences.selector";
import { StlSwitch } from "@common/components";
import { TMapLayersLocalState } from "@common/components/baseMap/mapLayers/mapLayers.constants";
import { setSelectedVizEnhancedLayer } from "@common/features/mapEnhancedLayers/state/mapEnhancedLayers.actions";
import {
    ENHANCED_LAYERS_LIST,
    TEnhancedLayer,
} from "@common/features/mapEnhancedLayers/state/mapEnhancedLayers.constants";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NestedRadioGroup } from "./nestedRadioGroup";
import "./mapEnhancedLayersPicker.less";

const EnhancedLayerOptionLabelWithLegend = ({ option }: { option: TEnhancedLayer }) => {
    const getLegendStyle = () => ({
        display: "inline-block",
        verticalAlign: "middle",
        marginRight: 5,
        width: 15,
        color: option.style.color,
    });

    return (
        <div className="enhanced-layers-option">
            <FontAwesomeIcon style={getLegendStyle()} icon={option.icon} />
            <span>{option.display}</span>
            {option.helpIcon && (
                <FontAwesomeIcon
                    id={option.helpIcon}
                    icon={faInfoCircle}
                    className="enhanced-layers-option-help-icon"
                />
            )}
        </div>
    );
};

type TProps = {
    mapLayersLocalState: TMapLayersLocalState | null;
    updateMapLayersState: (value: Partial<TMapLayersLocalState>) => void;
};

export const MapEnhancedLayersPicker = ({ mapLayersLocalState, updateMapLayersState }: TProps) => {
    const persistedEnhancedDataLayers = useAppSelector(getEnhancedLayersCategories);

    const dispatch = useAppDispatch();

    const enhancedLayersState = useMemo(() => {
        return mapLayersLocalState
            ? mapLayersLocalState.enhancedLayersCategories
            : persistedEnhancedDataLayers;
    }, [mapLayersLocalState, persistedEnhancedDataLayers]);

    const options = useMemo(
        () =>
            ENHANCED_LAYERS_LIST.map(option => ({
                value: option.code,
                label: <EnhancedLayerOptionLabelWithLegend option={option} />,
                nestedValue: enhancedLayersState[option.preferenceProperty],
                nestedProperty: option.preferenceProperty,
                nestedOptions: option.subLayers.map(layer => ({
                    value: layer.id,
                    label: (
                        <div className="enhanced-sublayers-option">
                            <span>{layer.name}</span>
                        </div>
                    ),
                })),
            })),
        [enhancedLayersState],
    );

    const updateMapLayersSettings = (
        updatedData: Partial<TMapLayersLocalState["enhancedLayersCategories"]>,
    ) => {
        if (mapLayersLocalState) {
            const enhancedLayersCategories = {
                ...mapLayersLocalState.enhancedLayersCategories,
                ...updatedData,
            };
            updateMapLayersState({ enhancedLayersCategories });
            dispatch(setSelectedVizEnhancedLayer(enhancedLayersCategories));
        } else {
            dispatch(
                patchMapLayersSettings({
                    section: "enhancedLayersCategories",
                    ...updatedData,
                }),
            );
        }
    };

    const onSelectLayer = (layerCode: string) => {
        updateMapLayersSettings({ category: layerCode });
    };

    const onSelectSubLayer = (propertyName: string, subLayerCode: string) => {
        updateMapLayersSettings({ [propertyName]: subLayerCode });
    };

    const onToggleEnhancedLayersState = () => {
        updateMapLayersSettings({ enabled: !enhancedLayersState.enabled });
    };

    return (
        <div className="stl-map-enhanced-layers-picker">
            <div className="enhanced-data-layers-header">
                <label className="picker-label secondary">Enhanced Data Layers</label>
                <StlSwitch
                    size="medium"
                    checked={enhancedLayersState.enabled}
                    onChange={onToggleEnhancedLayersState}
                />
            </div>
            {options.map(option => (
                <NestedRadioGroup
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    nestedValue={option.nestedValue}
                    nestedOptions={option.nestedOptions}
                    checked={option.value === enhancedLayersState.category}
                    disabled={!enhancedLayersState.enabled}
                    onChange={onSelectLayer}
                    onChangeNestedOption={value => onSelectSubLayer(option.nestedProperty, value)}
                />
            ))}
        </div>
    );
};
