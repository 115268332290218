import { TRAVELER_BIN_TYPES_US_CENSUS_2020 } from "@app/analysis/addOns/state/addOns.constants";

export const LEGEND_NODE_ID = ".stl-map-legend";
export const EXPANDED_WIDGET_PREFIX = "EXPANDED";
export const TRIP_ATTRIBUTES_CHART_POSTFIX = "TRIP_ATTRIBUTE_CHART";
export const TRAVELER_ATTRIBUTES_CHART_POSTFIX = "TRAVELER_ATTRIBUTE_CHART";
export const COMMERCIAL_ATTRIBUTES_CHART_POSTFIX = "COMMERCIAL_ATTRIBUTE_CHART";
export const CHART_LEGEND_POSTFIX = "LEGEND";
export const ATTRIBUTES_CHART_NODE_ID_PREFIX = "attributes-chart";
export const EXPANDED_ATTRIBUTES_CHART_NODE_ID_PREFIX = "expanded-attributes-chart";
export const SEGMENT_ATTRIBUTES_CHART_NODE_ID_PREFIX = "segment-attributes-chart";

const SCREENSHOT_COMPONENTS_TYPE = {
    MAP: "Map",
    CHART: "Chart",
} as const;

export type TVizComponentsType =
    typeof SCREENSHOT_COMPONENTS_TYPE[keyof typeof SCREENSHOT_COMPONENTS_TYPE];

const ATTRIBUTE_CHART_TYPES = {
    TRIP: {
        id: "TRIP",
        postfix: TRIP_ATTRIBUTES_CHART_POSTFIX,
    },
    TRAVELER: {
        id: "TRAVELER",
        postfix: TRAVELER_ATTRIBUTES_CHART_POSTFIX,
    },
    COMMERCIAL: {
        id: "COMMERCIAL",
        postfix: COMMERCIAL_ATTRIBUTES_CHART_POSTFIX,
    },
} as const;

export type TAttributeChartType = typeof ATTRIBUTE_CHART_TYPES[keyof typeof ATTRIBUTE_CHART_TYPES];

const makeAttributeChartConstant = ({
    attributeId,
    attributeCode,
    type,
}: {
    attributeId: string;
    attributeCode: string;
    type: TAttributeChartType;
}) => {
    const componentId = `${attributeId}_${type.postfix}`;

    return {
        [componentId]: {
            id: componentId,
            nodeId: `#${ATTRIBUTES_CHART_NODE_ID_PREFIX}-${attributeCode}`,
            type: SCREENSHOT_COMPONENTS_TYPE.CHART,
            legend: {
                id: `${componentId}_${CHART_LEGEND_POSTFIX}`,
                nodeId: LEGEND_NODE_ID,
            },
        },
        [`${EXPANDED_WIDGET_PREFIX}_${componentId}`]: {
            id: `EXPANDED_${componentId}`,
            nodeId: `#${EXPANDED_ATTRIBUTES_CHART_NODE_ID_PREFIX}-${attributeCode}`,
            type: SCREENSHOT_COMPONENTS_TYPE.CHART,
        },
    };
};

const makeUS2020TravelerAttrChartConstants = () => {
    return Object.entries(TRAVELER_BIN_TYPES_US_CENSUS_2020).reduce(
        (res, [binKey, binType]) => ({
            ...res,
            ...makeAttributeChartConstant({
                attributeId: binKey,
                attributeCode: binType.code,
                type: ATTRIBUTE_CHART_TYPES.TRAVELER,
            }),
        }),
        {},
    );
};

export const VIZ_SCREENSHOTS_COMPONENTS = {
    MAP: {
        id: "MAP",
        nodeId: ".mapboxgl-canvas",
        type: SCREENSHOT_COMPONENTS_TYPE.MAP,
        legend: { id: "MAP_LEGEND", nodeId: LEGEND_NODE_ID },
    },
    SYNC_MAP: {
        id: "SYNC_MAP",
        nodeId: ".mapboxgl-canvas",
        type: SCREENSHOT_COMPONENTS_TYPE.MAP,
    },
    ZONE_DISTRIBUTION_CHART: {
        id: "ZONE_DISTRIBUTION_CHART",
        nodeId: ".stl-viz-zone-distribution",
        type: SCREENSHOT_COMPONENTS_TYPE.CHART,
    },
    EXPANDED_ZONE_DISTRIBUTION_CHART: {
        id: "EXPANDED_ZONE_DISTRIBUTION_CHART",
        nodeId: ".stl-viz-zone-distribution",
        type: SCREENSHOT_COMPONENTS_TYPE.CHART,
    },
    SEGMENT_ZONE_DISTRIBUTION_CHART: {
        id: "SEGMENT_ZONE_DISTRIBUTION_CHART",
        nodeId: ".stl-viz3-segment-distribution",
        type: SCREENSHOT_COMPONENTS_TYPE.CHART,
    },
    NP_SEGMENT_ZONE_DISTRIBUTION_CHART: {
        id: "NP_SEGMENT_ZONE_DISTRIBUTION_CHART",
        nodeId: ".stl-viz3-np-segment-distribution",
        type: SCREENSHOT_COMPONENTS_TYPE.CHART,
    },
    TIME_DISTRIBUTION_CHART: {
        id: "TIME_DISTRIBUTION_CHART",
        nodeId: ".stl-viz3-time-distribution",
        type: SCREENSHOT_COMPONENTS_TYPE.CHART,
    },
    EXPANDED_TIME_DISTRIBUTION_CHART: {
        id: "EXPANDED_TIME_DISTRIBUTION_CHART",
        nodeId: ".stl-viz3-time-distribution",
        type: SCREENSHOT_COMPONENTS_TYPE.CHART,
    },
    SEGMENT_TIME_DISTRIBUTION_CHART: {
        id: "SEGMENT_TIME_DISTRIBUTION_CHART",
        nodeId: ".stl-viz3-segment-time-distribution",
        type: SCREENSHOT_COMPONENTS_TYPE.CHART,
    },
    NP_SEGMENT_TIME_DISTRIBUTION_CHART: {
        id: "NP_SEGMENT_TIME_DISTRIBUTION_CHART",
        nodeId: ".stl-viz3-np-segment-time-distribution",
        type: SCREENSHOT_COMPONENTS_TYPE.CHART,
    },
    TMC_TIME_DISTRIBUTION_CHART: {
        id: "TMC_TIME_DISTRIBUTION_CHART",
        nodeId: ".stl-viz-tmc-time-distribution",
        type: SCREENSHOT_COMPONENTS_TYPE.CHART,
    },
    TRAFFIC_FLOW_CHART: {
        id: "TRAFFIC_FLOW_CHART",
        nodeId: ".stl-viz-traffic-flow",
        type: SCREENSHOT_COMPONENTS_TYPE.CHART,
    },
    COMPARE_SEGMENTS_CHART: {
        id: "COMPARE_SEGMENTS_CHART",
        nodeId: ".stl-compare-segments",
        type: SCREENSHOT_COMPONENTS_TYPE.CHART,
    },
    ...makeAttributeChartConstant({
        attributeId: "DURATION",
        attributeCode: "trip_duration_bins",
        type: ATTRIBUTE_CHART_TYPES.TRIP,
    }),
    ...makeAttributeChartConstant({
        attributeId: "LENGTH",
        attributeCode: "trip_length_bins",
        type: ATTRIBUTE_CHART_TYPES.TRIP,
    }),
    ...makeAttributeChartConstant({
        attributeId: "SPEED",
        attributeCode: "trip_speed_bins",
        type: ATTRIBUTE_CHART_TYPES.TRIP,
    }),
    ...makeAttributeChartConstant({
        attributeId: "CIRCUITY",
        attributeCode: "trip_circuity_bins",
        type: ATTRIBUTE_CHART_TYPES.TRIP,
    }),
    // Commercial Vehicle attributes
    ...makeAttributeChartConstant({
        attributeId: "ROUTE_TYPE",
        attributeCode: "route_type",
        type: ATTRIBUTE_CHART_TYPES.COMMERCIAL,
    }),
    ...makeAttributeChartConstant({
        attributeId: "INDUSTRY",
        attributeCode: "industry",
        type: ATTRIBUTE_CHART_TYPES.COMMERCIAL,
    }),
    ...makeAttributeChartConstant({
        attributeId: "STATE_TRIPS",
        attributeCode: "in_out_state",
        type: ATTRIBUTE_CHART_TYPES.COMMERCIAL,
    }),
    // US Census 2010/Canada 2016 traveler attributes
    ...makeAttributeChartConstant({
        attributeId: "EDUCATION",
        attributeCode: "education_bins",
        type: ATTRIBUTE_CHART_TYPES.TRAVELER,
    }),
    ...makeAttributeChartConstant({
        attributeId: "FAMILY_STATUS",
        attributeCode: "family_status_bins",
        type: ATTRIBUTE_CHART_TYPES.TRAVELER,
    }),
    ...makeAttributeChartConstant({
        attributeId: "INCOME",
        attributeCode: "income_bins",
        type: ATTRIBUTE_CHART_TYPES.TRAVELER,
    }),
    ...makeAttributeChartConstant({
        attributeId: "RACE",
        attributeCode: "race_bins",
        type: ATTRIBUTE_CHART_TYPES.TRAVELER,
    }),
    ...makeAttributeChartConstant({
        attributeId: "TRIP_PURPOSE",
        attributeCode: "trip_purpose_bins",
        type: ATTRIBUTE_CHART_TYPES.TRAVELER,
    }),
    // US Census 2020 traveler attributes
    ...makeUS2020TravelerAttrChartConstants(),
};

export const VIZ_SCREENSHOTS_COMPONENTS_LIST = Object.values(
    VIZ_SCREENSHOTS_COMPONENTS,
) as Array<TVizScreenshotsComponent>;

export type TVizScreenshotsComponentType = keyof typeof VIZ_SCREENSHOTS_COMPONENTS;
export type TVizScreenshotsComponent =
    typeof VIZ_SCREENSHOTS_COMPONENTS[keyof typeof VIZ_SCREENSHOTS_COMPONENTS];

export const VIZ_SCREENSHOTS_CHARTS_DATA_LIMIT = 50;
