export const calculatePopoverPlacement = ({
    control,
    parentContainer,
    popover,
    padding,
    popoverHeight,
}: {
    control: HTMLElement;
    parentContainer: HTMLElement;
    popover: HTMLElement;
    popoverHeight: number;
    padding: number;
}) => {
    const elementRect = control.getBoundingClientRect();
    const parentRect = parentContainer.getBoundingClientRect();

    const topSpace = elementRect.top - parentRect.top;
    const bottomSpace = parentRect.bottom - elementRect.top;

    const availableSpaceBelow = bottomSpace - padding;
    const availableSpaceAbove = topSpace - padding;

    if (availableSpaceBelow >= popoverHeight) {
        popover.style.top = "0px";
        popover.style.bottom = "";
    } else if (availableSpaceAbove >= popoverHeight) {
        popover.style.top = "";
        popover.style.bottom = "0px";
    } else {
        popover.style.top = "";
        popover.style.bottom = `-${parentRect.bottom - padding - elementRect.bottom}px`;
    }
};
