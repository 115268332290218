import { useCallback, useEffect, useMemo, useState } from "react";
import ReactDOM from "react-dom";
import type { Map } from "mapbox-gl";
import type { TControlConfig } from "@common/components/baseMap/baseMap.types";
import { MapLayersControl } from "@common/components/baseMap/customControls";
import { MapLayersPicker } from "@common/components/baseMap/customControls/mapLayers";
import { calculatePopoverPlacement } from "@common/components/baseMap/hooks/internal/useMapControls/mapLayerControl.helpers";

export type TProps = {
    map: Map | null;
    enabled: boolean;
    position: TControlConfig["position"];
    mapLayersConfig: TControlConfig["mapLayersConfig"];
};

export const useMapLayersControl = ({ map, enabled, position, mapLayersConfig }: TProps) => {
    const [isControlAdded, setIsControlAdded] = useState(false);
    const mapLayersControl = useMemo(() => {
        return enabled ? new MapLayersControl({ onAdd: () => setIsControlAdded(true) }) : null;
    }, [enabled]);

    useEffect(() => {
        if (!map || !mapLayersControl) return undefined;

        map.addControl(mapLayersControl, position);

        const baseMapElement = mapLayersControl.mapLayersContainer.closest(".stl-base-map");

        if (!baseMapElement) return undefined;

        const resizeObserver = new ResizeObserver(() => {
            calculatePopoverPlacement({
                control: mapLayersControl.controlContainer,
                parentContainer: baseMapElement as HTMLElement,
                popover: mapLayersControl.mapLayersContainer,
                popoverHeight: 260,
                padding: 15,
            });
        });

        resizeObserver.observe(baseMapElement);

        return () => {
            resizeObserver.unobserve(baseMapElement);
            resizeObserver.disconnect();
            map.removeControl(mapLayersControl);
        };
    }, [map, mapLayersControl, position]);

    return useCallback(() => {
        if (!map || !mapLayersControl || !isControlAdded) return null;

        return ReactDOM.createPortal(
            <MapLayersPicker
                map={map}
                mapLayersConfig={mapLayersConfig}
                onClose={() => mapLayersControl.onShowLayers()}
            />,
            mapLayersControl.mapLayersContainer as HTMLDivElement,
        );
    }, [map, mapLayersControl, isControlAdded, mapLayersConfig]);
};
