import type { LngLat } from "mapbox-gl";
import type { TRoadId } from "@app/viz3/baseLightningViz/state/baseLightningViz.types";
import { EDefaultColors } from "@common/constants/color.constants";
import type { IIntersectionZoneGate } from "@common/features/intersections/intersection.types";
import type { ZoneRoles } from "@common/features/zones/zones.types";
import {
    ZONE_BIKE_CALIBRATION_TYPE,
    ZONE_PED_CALIBRATION_TYPE,
    ZONE_VEHICLE_CALIBRATION_TYPES_LIST,
} from "@common/features/zonesManager/components/zoneCalibration/zoneCalibration.constants";
import { ZONE_GATES_OPTIONS_LIST } from "@common/features/zonesManager/state/zonesManager.constants";
import type { TGateId, TZonesManagerZone } from "@common/features/zonesManager/zonesManager.types";

export enum EFormats {
    geojson = "geojson",
    zoneDetailOnly = "zoneDetailOnly",
    zoneDesc = "zoneDesc",
}

export interface IZone {
    zone_id: string;
    is_bidi: boolean;
    is_pass: boolean;
    zone_name: string;
    direction: number;
    is_current: boolean;
    touches_ca: boolean;
    touches_us: boolean;
    geom_type: "L" | "P";
    zone_kind_id: number;
    end_direction: number;
    start_direction: number;
    highway?: TRoadId;
}

interface ICoordinates {
    lng: number;
    lat: number;
}

export interface ILineZoneGate {
    direction: number | null;
    gate_type: TGateId;
    polygon: Array<ICoordinates>;
}

export interface IGateData {
    zone_id: number;
    zone_kind_id: number;
    intersection_geometry: string;
}

export interface IGateAPI {
    area: number;
    customer_zone_id?: number;
    direction: number;
    error_type: string | null;
    geom_type: "polygon";
    geometry: string;
    is_bidi: boolean;
    is_pass: boolean;
    is_immutable: boolean;
    osm_segments: Array<IGateData>;
    point_on_surface: string;
    predecessor_zone_id?: number;
    predecessor_zone_kind_id?: number;
    primary_osm_segment_zone_id?: number;
    primary_osm_segment_zone_kind_id?: number;
    road_type: "B" | "P" | "S" | "L" | "R" | null;
    sa_version_id: number;
    status: string;
    touches_ca: false;
    touches_us: true;
    zone_id: number;
    zone_kind_id: number;
    zone_name: string;
    zone_uuid: string;
}

export interface ILineZoneAPI<WithCalibration extends boolean = false> {
    area: number;
    customer_zone_id?: number;
    calibration_detail: WithCalibration extends true ? ICalibrationDetails : undefined;
    direction: number;
    e_geom_direction: number;
    end_geometry: string;
    gates?: Array<IIntersectionZoneGate>;
    geom_type: "line";
    geometry: string;
    is_bidi: boolean;
    is_pass: boolean;
    is_questionable: boolean;
    is_simplified: boolean;
    is_immutable: boolean;
    line_geometry: string;
    point_on_surface: string;
    predecessor_zone_id?: number | null;
    predecessor_zone_kind_id?: number | null;
    road_type: "B" | "P" | "S" | "L" | "R" | null;
    s_geom_direction: number;
    sa_version_id: number;
    start_geometry: string;
    status: string;
    within_core_region: boolean;
    within_per_zone_area: boolean;
    within_region: boolean;
    within_study_core_region: boolean | null;
    within_study_region: boolean | null;
    with_calibration: WithCalibration | null;
    zone_id: number;
    zone_kind_id: TZonesManagerZone<WithCalibration>["zone_kind_id"];
    zone_name: string;
}

export interface IPolygonZoneAPI<WithCalibration extends boolean = false> {
    area: number;
    customer_zone_id?: number;
    calibration_detail: WithCalibration extends true ? ICalibrationDetails : undefined;
    direction: number;
    geom_type: "polygon";
    geometry: string;
    is_bidi: boolean;
    is_pass: boolean;
    is_questionable: boolean;
    is_simplified: boolean;
    is_immutable: boolean;
    point_on_surface: string;
    predecessor_zone_id?: number | null;
    predecessor_zone_kind_id?: number | null;
    sa_version_id: number;
    status: string;
    within_core_region: boolean;
    within_per_zone_area: boolean;
    within_region: boolean;
    within_study_core_region: boolean | null;
    within_study_region: boolean | null;
    with_calibration: WithCalibration | null;
    zone_id: number;
    zone_kind_id: TZonesManagerZone<WithCalibration>["zone_kind_id"];
    zone_name: string;
}

export type TZoneAPI<WithCalibration extends boolean = false> =
    | ILineZoneAPI<WithCalibration>
    | IPolygonZoneAPI<WithCalibration>;

export interface IZoneSetAPI<WithCalibration extends boolean = false> {
    calibration_zone_count: number;
    created_by: string;
    created_date: string;
    is_immutable: boolean;
    is_questionable: boolean;
    sa_version_id: number;
    set_id: number;
    set_name: string;
    status: string;
    touches_ca: boolean;
    touches_us: boolean;
    updated_by: string;
    updated_date: string;
    with_all_direction: boolean;
    with_calibration: boolean;
    with_pass_through: boolean;
    within_core_region: boolean;
    within_per_zone_area: boolean;
    within_region: boolean;
    within_study_core_region: boolean | null;
    within_study_region: boolean | null;
    zone_count: number;
    zone_set_color?: EDefaultColors;
    zones: TZoneAPI<WithCalibration>[];
    project_folder_names: string[];
}

interface IDirectionDataAPI {
    direction_3857: number;
    is_bidi: boolean;
    osm_segments: Array<IGateData>;
}

interface IBusRailAgencyAPI {
    id: number;
    name: string;
}

interface IBusRailLineAPI {
    agency_id: number;
    id: number;
    name: string;
}

type TZoneRoles =
    | ZoneRoles.oz
    | ZoneRoles.az
    | ZoneRoles.cz
    | ZoneRoles.dz
    | ZoneRoles.tfz
    | ZoneRoles.mfz;

export interface IZoneVintageDatesResponse {
    status: string;
    zone_vintage: {
        [key: number]: {
            month_num: number | null;
            year_num: number | null;
        };
    };
}

export interface IZoneResponse {
    zone: IZone | TZoneAPI;
    status: string;
}

export interface IGateResponse {
    zone: IGateAPI;
    status: string;
}

export interface IZoneSetsResponse {
    baseurl: object;
    bounds: object;
    highlight_zones: TZoneAPI[];
    layers: object;
    sets: IZoneSetAPI[];
    status: string;
}

export interface IGetZoneSetByIdParams {
    format: EFormats;
}

export interface ICreateZoneSetsFromShapefileResponse {
    data: {
        zone_shapefile_request_id: number;
    };
    zone_shapefile_request_id: number;
    message: string;
    status: string;
}

export interface IUploadZonesFromShapefileResponse {
    status: string;
    zones: TZoneAPI[];
}

export interface IDeleteZoneSetResponse {
    status: string;
}

export interface IValidateZoneSetNameResponse {
    status: string;
}

export interface IUpdateZoneInZoneSetResponse {
    status: string;
    zone_id: number;
    zone_sa_version_id: number;
    zone_set: IZoneSetAPI;
}

export interface ICreateNewZoneSetResponse {
    status: string;
    zone_set_id: number;
    zone_set?: IZoneSetAPI;
}

export interface IZoneSetResponse {
    status: string;
    zone_set: IZoneSetAPI;
}

export interface IUpdateZoneSetResponse {
    status: string;
    zone_set: IZoneSetAPI;
    created_zone_id?: number;
}

interface ICalibrationZoneSets {
    status: "Available";
    within_region: true;
}

interface IStudyZoneSets {
    study_id: number;
}

type TGetZoneSetsWithZoneDetailParams = {
    [key in `${TZoneRoles}_ids`]?: number[];
} & {
    format: "zoneDetailOnly";
};

export type TGetZoneSetsParams =
    | ICalibrationZoneSets
    | IStudyZoneSets
    | TGetZoneSetsWithZoneDetailParams;

interface ICalibrationDetails {
    calibration_type: typeof ZONE_VEHICLE_CALIBRATION_TYPES_LIST[number]["value"];
    calibration_value: string | null;
    pers_ratio: number;
    comm_md_ratio: number;
    comm_hd_ratio: number;
    collection_method: string;
    description: string;
    bike_calibration_type: typeof ZONE_BIKE_CALIBRATION_TYPE;
    bike_calibration_value: string | null;
    bike_description: string;
    ped_calibration_type: typeof ZONE_PED_CALIBRATION_TYPE;
    ped_calibration_value: string | null;
    ped_description: string;
    sa_version_id: number;
}

interface IGate {
    direction: number;
    gate_type: typeof ZONE_GATES_OPTIONS_LIST[number]["id"];
    polygon: { lng: number; lat: number }[];
}

interface IUpdateZoneSetLineZoneInputs {
    gates: Array<IGate>;
    is_bidi: boolean;
    is_pass: boolean;
    polyline: { lng: number; lat: number }[];
    predecessor_zone_id: number | null;
    predecessor_zone_kind_id: number | null;
    sa_version_id: number;
    set_geom_type: "line";
    road_type: string;
    zone_id: string | undefined;
    zone_name: string;
}

interface IUpdateZoneSetPolygonZoneInputs {
    direction: number | null;
    is_bidi: boolean;
    is_pass: boolean;
    polygon: { lng: number; lat: number }[];
    predecessor_zone_id: number | null;
    predecessor_zone_kind_id: number | null;
    sa_version_id: number;
    set_geom_type: "polygon";
    zone_id: string | undefined;
    zone_name: string;
}

type TUpdateZoneSetZoneInputsData = IUpdateZoneSetLineZoneInputs | IUpdateZoneSetPolygonZoneInputs;

interface IZoneData {
    zone_ids: string[];
    zone_kind_id: number;
}

export interface IUpdateLineZoneGateApiObject {
    zone_name: string;
    zone_id: number | null;
    geom_type: "line";
    is_bidi: boolean;
    is_pass: boolean;
    road_type: string;
    direction: number;
    gates: ILineZoneGate[];
}

export interface IUpdateLineZoneApiObject {
    zone_name: string;
    zone_id: number | null;
    geom_type: "line";
    is_bidi: boolean;
    is_pass: boolean;
    road_type: string;
    direction: number | null;
    gates?: ILineZoneGate[];
    polyline?: Array<ICoordinates>;
}

export interface IUpdatePolygonZoneApiObject {
    zone_name: string;
    zone_id: number | null;
    geom_type: "polygon";
    is_bidi: boolean;
    is_pass: boolean;
    direction: number | null;
    polygon?: Array<ICoordinates>;
}

export type IUpdateZoneInputs = {
    zone_inputs:
        | IUpdateLineZoneApiObject
        | IUpdateLineZoneGateApiObject
        | IUpdatePolygonZoneApiObject;
    with_calibration?: boolean;
    calibration_inputs?: ICalibrationDetails;
};

export interface ISaveNewSpotCounterGateApiObject {
    customer_zone_id?: number;
    direction: number;
    is_bidi: boolean;
    is_pass: boolean;
    osm_segment_zone_id: number;
    osm_segment_zone_kind_id: number;
    point: LngLat;
    polygon: Array<LngLat>;
    predecessor_zone_id?: number;
    predecessor_zone_kind_id?: number;
    set_geom_type: "polygon";
    zone_name: string;
}

export interface ISaveNewLineZoneApiObject {
    zone_name: string;
    is_bidi: boolean;
    is_pass: boolean;
    set_geom_type: "line";
    sa_version_id: number;
    polyline: Array<LngLat>;
    road_type: string;
    gates: Array<ILineZoneGate>;
    predecessor_zone_id: number | null;
    predecessor_zone_kind_id: number | null;
    zone_id?: number;
}

export interface ISaveNewPolygonZoneApiObject {
    zone_name: string;
    is_bidi: boolean;
    is_pass: boolean;
    direction: number | null;
    set_geom_type: "polygon";
    sa_version_id: number;
    polygon: Array<LngLat>;
    predecessor_zone_id: number | null;
    predecessor_zone_kind_id: number | null;
    zone_id?: number;
}

export type ISaveNewLineZoneInputs = {
    zone_inputs: ISaveNewLineZoneApiObject;
    with_calibration?: boolean;
    calibration_inputs?: ICalibrationDetails;
};

export type ISaveNewPolygonZoneInputs = {
    zone_inputs: ISaveNewPolygonZoneApiObject;
    with_calibration?: boolean;
    calibration_inputs?: ICalibrationDetails;
};

export type ISaveNewSpotCounterGateZoneInputs = {
    zone_inputs: ISaveNewSpotCounterGateApiObject;
};

export type ISaveNewZoneInputs =
    | ISaveNewLineZoneInputs
    | ISaveNewPolygonZoneInputs
    | ISaveNewSpotCounterGateZoneInputs;

export interface IReverseZoneData {
    line_geom: "geojson";
}

export interface IUpdateZoneSetData {
    set_name: string;
    zone_set_color: EDefaultColors;
    zone_inputs: TUpdateZoneSetZoneInputsData;
    with_calibration: boolean;
    calibration_inputs: ICalibrationDetails;
    field_choices: {
        [key: string]: any;
    };
    sa_version_id: number;
    zones: IZoneData[];
    zone_shapefile_uuid: string;
    chunk_size: number | null;
    project_folder_id: number;
}

export interface IUpdateZoneInZoneSetData {
    zone_inputs:
        | IUpdateLineZoneApiObject
        | IUpdateLineZoneGateApiObject
        | IUpdatePolygonZoneApiObject;
    sa_version_id: number;
    zs_sa_version_id: number;
    with_calibration: boolean;
    calibration_inputs: ICalibrationDetails;
}

export type ICreateNewZoneSetData<IsZoneSetColorNotEmpty extends boolean = true> = {
    set_name: string;
    project_folder_id?: number;
} & (IsZoneSetColorNotEmpty extends true
    ? { zone_set_color?: Exclude<EDefaultColors, EDefaultColors.NO_COLOR> }
    : { zone_set_color: EDefaultColors | null });

export interface IValidateZoneSetName {
    set_name: string;
}

export interface ICreateZoneSetsFromShapefileData {
    zone_shapefile_uuid: string;
    field_choices: {
        [key: string]: any;
    };
    with_calibration: boolean;
    chunk_size: number | null;
    sets: Array<ICreateNewZoneSetData<false>>;
    sa_version_id: number;
    zone_set_id: number;
    project_folder_id?: number;
}

export interface IUploadZonesFromShapefileData {
    zone_shapefile_uuid: string;
    field_choices: {
        [key: string]: any;
    };
    with_calibration: boolean;
}

export interface IZoneVersionIDs {
    sa_version_id?: number;
    zs_sa_version_id: number;
}

export interface IDeleteZonesData {
    zs_sa_version_id: number;
    zones: {
        zone_id: number;
        zone_kind_id: number;
    }[];
}

// sa_version_id must be provided only for Custom Zones.
export interface ICopyZoneData {
    sa_version_id?: number;
}

export interface ICopyZoneSetData {
    sa_version_id: number;
}

export interface IGetBusRailAgenciesData {
    bounding_box: Array<number>;
}

export interface IGetBusRailAgenciesResponse {
    status: string;
    data: {
        agencies: Array<IBusRailAgencyAPI>;
        lines: Array<IBusRailLineAPI>;
    };
}

export interface ICreateZoneGatesData {
    line_geom: string;
    road_type?: string;
}

interface IBulkLineZonesData {
    zone_id: number;
    zone_kind_id: number;
}

export interface IBulkCreateCustomGatesFromLineZonesData {
    zone_inputs: Array<IBulkLineZonesData>;
}

export interface ICreateZoneGatesResponse {
    start_gate: string;
    start_direction: number;
    middle_gate: string;
    middle_direction: number;
    end_gate: string;
    end_direction: number;
    line_geom_length: number;
}

export interface IPolygonZoneAreaData {
    poly_geom: string;
}

export interface IPolygonZoneAreaResponse {
    area: number;
}

export interface IGetZoneResponse {
    status: string;
    data: TZoneAPI | IGateAPI;
}

export interface ISpotCounterGateDirectionData {
    zone_id: number;
    zone_kind_id: number;
    poly_geom: string;
}

export interface IGetSpotCounterGateDirectionResponse {
    status: string;
    data: IDirectionDataAPI;
}

interface IBulkUpdateZonesData {
    zone_kind_id: number;
    zone_id: number;
    sa_version_id?: number | null;
}

export enum EDirectionTypes {
    bidi = "bidi",
    unidi = "unidi",
    nodirection = "nodirection",
    reverse = "reverse",
}

export interface IBulkUpdateAnalysisZonesDirectionData {
    zone_inputs: Array<IBulkUpdateZonesData>;
    direction: EDirectionTypes;
}

export interface IBulkReverseAnalysisZonesDirectionData {
    zone_inputs: Array<IBulkUpdateZonesData>;
}

export interface IBulkUpdateAnalysisZonesDirectionResponse {
    status: string;
    zones: Array<TZoneAPI>;
}

export interface IBulkUpdateZoneSetZonesDirectionData {
    zone_inputs: Array<IBulkUpdateZonesData>;
    direction: EDirectionTypes;
    zs_sa_version_id: number;
}

export interface IBulkReverseZoneSetZonesDirectionData {
    zone_inputs: Array<IBulkUpdateZonesData>;
    zs_sa_version_id: number;
}

export interface IBulkUpdateZoneSetZonesDirectionResponse {
    status: string;
    zone_set: IZoneSetAPI;
}

interface IZoneData {
    created_by: string;
    created_date: string;
    zone_id: number;
    zone_kind_id: number;
    zone_name: string;
}

export interface IGetZonesByZoneKindIdResponse {
    status: string;
    zones: Array<IZoneData>;
}

export interface IBulkCreateCustomGatesFromLineZonesResponse {
    status: string;
    zones: Array<IGateAPI>;
}
