import React, { ReactNode, useCallback, useEffect, useRef } from "react";
import classNames from "classnames";
import { motion, useMotionValue, useSpring } from "framer-motion";
import { Divider } from "./divider";
import { Pane } from "./pane";
import "./verticalSplitPane.less";

type TProps = {
    className?: string;
    defaultValue: number | string;
    topContent?: ReactNode;
    bottomContent?: ReactNode;
};

//During mouse move some document content can be selected. Need to avoid this
const clearDocumentSelection = () => {
    try {
        // @ts-ignore
        window.getSelection().removeAllRanges();
        // eslint-disable-next-line no-empty
    } catch (e) {}
};

const MotionDivider = motion(Divider);

export const StlVerticalSplitPane = ({
    className,
    defaultValue,
    bottomContent,
    topContent,
}: TProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const y = useMotionValue(0);
    const animatedHeight = useSpring(y, {
        bounce: 0.1,
        damping: 30,
        duration: 20,
    });

    useEffect(() => {
        const containerHeight = containerRef.current?.clientHeight || 0;
        if (typeof defaultValue === "string" && defaultValue.endsWith("%")) {
            const percentage = parseFloat(defaultValue) / 100;
            y.set(containerHeight * percentage);
        } else y.set(Number(defaultValue));
    }, [defaultValue, y]);

    const onDrag = useCallback(
        (event, info) => {
            clearDocumentSelection();

            y.set(y.get() - info.delta.y);
        },
        [y],
    );

    return (
        <div ref={containerRef} className={classNames("stl-vertical-split-pane", className)}>
            <Pane className="pane-wrapper pane-top">{topContent}</Pane>
            <MotionDivider
                paneType="vertical"
                drag="y"
                onDrag={onDrag}
                dragElastic={0}
                dragMomentum={false}
                dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0 }}
            />
            <motion.div className="pane-wrapper pane-bottom" style={{ height: animatedHeight }}>
                {bottomContent}
            </motion.div>
        </div>
    );
};
