import { createSelector } from "reselect";
import { getAnalysisCountry, getBasics } from "@app/analysis/basics/state/basics.selectors";
import {
    CREATE_ANALYSIS_TYPES,
    DEFAULT_PRESET_GEOG_TYPE,
    PRESET_GEOG_TYPES_LIST,
    TPresetGeogType,
    TRAVEL_MODES,
} from "@app/analysis/state/analysisConfiguration.constants";
import {
    getAnalysisTravelModeFromCode,
    getIsAnalysisTypeSupportOption,
} from "@app/analysis/state/analysisConfiguration.helpers";
import {
    analysisTypeObjSelector,
    getAnalysisTypeCode,
    getGeneral,
    getIsReadOnlyMode,
    getUiStates,
    isAnalysisTypeOf,
} from "@app/analysis/state/general/general.selectors";
import { getIsDataPeriodsMixedWithCensus2020 } from "@app/analysis/timePeriods/state/timePeriods.helpers";
import {
    getHasDatesWithDifferentMetricsMethodologiesForTravelerAttributes,
    getIsUSAnalysisWithOnlyCensus2020Dates,
    getTimePeriods,
} from "@app/analysis/timePeriods/state/timePeriods.selectors";
import type { TRootState } from "@app/store";
import {
    getIsOrgHasFeature,
    getIsSuperUser,
    getUserOrg,
    isGenerateVisualizationAvailable,
} from "@app/store/currentUser/currentUser.selector";
import {
    ANALYSIS_CENSUS_TYPES,
    MODES_OF_TRAVEL,
    ORG_COUNTRIES,
    TAnalysisType,
} from "@common/constants/analysis.constants";
import {
    getIsAllVehiclesTravelMode,
    getIsBikeOrPedTravelMode,
    getIsNetworkPerformanceAnalysis,
    getIsTruckTravelMode,
} from "@common/helpers/analysis";
import {
    ADD_ONS_TAB_SECTIONS,
    ANALYSES_WITH_CVD_LIMITED_TRAVELER_ATTRS,
    HWL_ZONE_INTERSECTION_TYPES_LIST,
    RESET_TRAVELER_ATTRIBUTES_WARNING_MESSAGES,
    THWLZoneIntersectionType,
} from "./addOns.constants";

/*
    Getter of the main object, just for the sake of convenience.
*/
export function getAnalysisAddOns(state: TRootState) {
    return state.analysisConfiguration.addOns;
}
export function getAddOnsValidation(state: TRootState) {
    return getAnalysisAddOns(state).validation;
}

/*
    Getters sub-objects of the main one. Just for the sake of convenience.
*/
export const getHWLSettings = (state: TRootState) => getAnalysisAddOns(state).hwlSettings;
export const getAdminSettings = (state: TRootState) => getAnalysisAddOns(state).adminSettings;
export const getTripAttrSettings = (state: TRootState) =>
    getAnalysisAddOns(state).tripAttrSettings;
export const getTravelerAttrSettings = (state: TRootState) =>
    getAnalysisAddOns(state).travelerAttrSettings;
export const getCommercialVehicleSettings = (state: TRootState) =>
    getAnalysisAddOns(state).commercialVehicleSettings;

/*
    This getters should be private (used only in this file) and are necessary only for proper
    `createSelector` memoization. If you want to get those properties, please use parent object.
*/
const getAnalysisGeogType = (state: TRootState) => getAnalysisAddOns(state).presetGeographyType;
const getHwlZoneIntersectionCode = (state: TRootState) =>
    getHWLSettings(state).hwlZoneIntersectionCode;

export const isTripAttributesSectionDisabled = (state: TRootState) => {
    const analysisTypeCode = getAnalysisTypeCode(state);
    const { travelModeCode } = getBasics(state);
    const isNetworkPerformanceAnalysis = getIsNetworkPerformanceAnalysis(analysisTypeCode);

    return (
        !getIsOrgHasFeature(state, "trip_attr") ||
        isAnalysisTypeOf(CREATE_ANALYSIS_TYPES.CONGESTION.code, state) ||
        (travelModeCode !== MODES_OF_TRAVEL.TRUCK_GT.code && isNetworkPerformanceAnalysis)
    );
};

export const isTravelerAttributesSectionAvailable = (state: TRootState) => {
    const { travelModeCode } = getBasics(state);

    return !getIsTruckTravelMode(travelModeCode);
};

export const isTravelerAttributesSectionDisabled = (state: TRootState) => {
    const { travelModeCode } = getBasics(state);
    const analysisTypeCode = getAnalysisTypeCode(state);
    const isAnalysisWithCVDLimitedTravelerAttrs =
        ANALYSES_WITH_CVD_LIMITED_TRAVELER_ATTRS.includes(analysisTypeCode);
    return (
        (travelModeCode === MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code &&
            !isAnalysisWithCVDLimitedTravelerAttrs) ||
        !getIsOrgHasFeature(state, "traveler_attr") ||
        isAnalysisTypeOf(CREATE_ANALYSIS_TYPES.CONGESTION.code, state)
    );
};

export const isHWLSectionAvailable = (state: TRootState) => {
    const { travelModeCode } = getBasics(state);
    const isZAAnalysis = isAnalysisTypeOf(CREATE_ANALYSIS_TYPES.ZA.code, state);

    return isZAAnalysis && travelModeCode !== TRAVEL_MODES.TRUCK.code;
};

export const isHWLSectionDisabled = (state: TRootState) => {
    const { travelModeCode } = getBasics(state);
    return (
        travelModeCode === MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code ||
        !getIsOrgHasFeature(state, "home_work_locations")
    );
};

export const isCommVehicleFiltersSectionAvailable = (state: TRootState) => {
    const { travelModeCode } = getBasics(state);
    const isReadOnly = getIsReadOnlyMode(state);

    return travelModeCode === TRAVEL_MODES.TRUCK.code && isReadOnly;
};

export const isCommVehicleFiltersSectionDisabled = (state: TRootState) =>
    !getIsOrgHasFeature(state, "comm_weight_class");

export const isCommVehicleAttributesSectionAvailable = (state: TRootState) => {
    const { travelModeCode } = getBasics(state);
    const analysisTypeCode = getAnalysisTypeCode(state);
    const isNetworkPerformanceAnalysis = getIsNetworkPerformanceAnalysis(analysisTypeCode);

    return travelModeCode === TRAVEL_MODES.TRUCK_GT.code && isNetworkPerformanceAnalysis;
};

export const isCommVehicleAttributesSectionDisabled = (state: TRootState) =>
    !getIsOrgHasFeature(state, "commercial_traffic_attributes");

export const isValidationDetailsSectionAvailable = (state: TRootState) =>
    getUiStates(state).readOnly;

export const isOtherOptionsSectionAvailable = (state: TRootState) =>
    getIsSuperUser(state) || isGenerateVisualizationAvailable(state);

export const getAvailableAddOnsSections = (state: TRootState) => {
    const sectionsPermissions = {
        [ADD_ONS_TAB_SECTIONS.COMMERCIAL_VEHICLE_ATTRIBUTES.code]: {
            isAvailable: isCommVehicleAttributesSectionAvailable,
            isDisabled: isCommVehicleAttributesSectionDisabled,
        },
        [ADD_ONS_TAB_SECTIONS.COMMERCIAL_VEHICLE_FILTERS.code]: {
            isAvailable: isCommVehicleFiltersSectionAvailable,
            isDisabled: isCommVehicleFiltersSectionDisabled,
        },
        [ADD_ONS_TAB_SECTIONS.TRIP_ATTRIBUTES.code]: {
            isDisabled: isTripAttributesSectionDisabled,
        },
        [ADD_ONS_TAB_SECTIONS.TRAVELER_ATTRIBUTES.code]: {
            isAvailable: isTravelerAttributesSectionAvailable,
            isDisabled: isTravelerAttributesSectionDisabled,
        },
        [ADD_ONS_TAB_SECTIONS.HOME_AND_WORK_LOCATIONS.code]: {
            isAvailable: isHWLSectionAvailable,
            isDisabled: isHWLSectionDisabled,
        },
        [ADD_ONS_TAB_SECTIONS.VALIDATION_DETAILS.code]: {
            isAvailable: isValidationDetailsSectionAvailable,
        },
        [ADD_ONS_TAB_SECTIONS.OTHER_OPTIONS.code]: {
            isAvailable: isOtherOptionsSectionAvailable,
        },
    };

    const analysisTypeObj = analysisTypeObjSelector(state)!;

    return analysisTypeObj.addOns
        .filter(option => {
            const sectionPermissions = sectionsPermissions[option.code] || {};

            if (!sectionPermissions.isAvailable) return true;

            return sectionPermissions.isAvailable(state);
        })
        .map(option => {
            const sectionPermissions = sectionsPermissions[option.code] || {};

            const isDisabled =
                !!sectionPermissions.isDisabled && sectionPermissions.isDisabled(state);

            return {
                code: option.code,
                isAddOnsSection: !!option.isAddOnsSection,
                ...(isDisabled && { isDisabled }),
            };
        });
};

export const analysisHasOption = (optionCode: TAnalysisType["id"], state: TRootState) => {
    const availableOptions = getAvailableAddOnsSections(state);

    return availableOptions.find(option => option.code === optionCode);
};

export const getGeographyTypeYear = (state: TRootState) => {
    const isUSAnalysisWithOnlyCensus2020Dates = getIsUSAnalysisWithOnlyCensus2020Dates(state);
    const isReadOnly = getIsReadOnlyMode(state);
    const { zoneCensusType } = getBasics(state);
    const isUS2020Census = isReadOnly
        ? zoneCensusType === ANALYSIS_CENSUS_TYPES.US_2020
        : isUSAnalysisWithOnlyCensus2020Dates;

    return isUS2020Census ? 2020 : 2010;
};

export const getAvailableGeogTypes = (state: TRootState) => {
    const org = getUserOrg(state);
    const analysisCountry = getAnalysisCountry(state);
    const year = getGeographyTypeYear(state);

    return PRESET_GEOG_TYPES_LIST.reduce((res, geogType) => {
        if (
            (analysisCountry && geogType.country !== analysisCountry) ||
            !org[geogType.featureName]
        ) {
            return res;
        }
        const displayName = geogType.isComputedLabel
            ? `${year} ${geogType.display}`
            : geogType.display;

        return [...res, { ...geogType, display: displayName }];
    }, [] as Array<TPresetGeogType>);
};

export const getDefaultGeogTypeCode = (state: TRootState) => {
    const currentGeogType = getAnalysisGeogType(state);
    const availableGeogTypes = getAvailableGeogTypes(state);
    const geogTypeCodes = availableGeogTypes.map(type => type.code);

    if (geogTypeCodes.length) {
        return currentGeogType && geogTypeCodes.includes(currentGeogType)
            ? currentGeogType
            : geogTypeCodes[0];
    }

    return DEFAULT_PRESET_GEOG_TYPE.code;
};

export const geogTypeSelector = (state: TRootState) => {
    const currentGeogType = getAnalysisGeogType(state);
    const year = getGeographyTypeYear(state);

    const geogType = PRESET_GEOG_TYPES_LIST.find(_geogType => _geogType.code === currentGeogType)!;
    const displayName = geogType.isComputedLabel
        ? `${year} ${geogType.display}`
        : geogType.display;

    return { ...geogType, display: displayName };
};

export const hwlZoneIntersectionObjSelector = createSelector(
    getHwlZoneIntersectionCode,
    (zoneIntersectionCode: THWLZoneIntersectionType["code"]) =>
        HWL_ZONE_INTERSECTION_TYPES_LIST.find(
            zoneIntersection => zoneIntersection.code === zoneIntersectionCode,
        ),
);

export const getIsAllTravelerAttributesHaveTheSameStatus = (state: TRootState) => {
    const travelerAttrSettings = getTravelerAttrSettings(state);
    const { enabled, ...attributes } = travelerAttrSettings;

    return Object.values(attributes).every(attribute => attribute.enabled === enabled);
};

export const shouldResetTravelerAttributesOnTimePeriodsChange = (state: TRootState) => {
    const { country } = getBasics(state);
    const { enabled } = getTravelerAttrSettings(state);
    const hasDatesWithDifferentMetricsMethodologies =
        getHasDatesWithDifferentMetricsMethodologiesForTravelerAttributes(state);
    const isUSAnalysisWithOnly2022Dates = getIsUSAnalysisWithOnlyCensus2020Dates(state);
    const isAllTravelerAttributesHaveTheSameStatus =
        getIsAllTravelerAttributesHaveTheSameStatus(state);

    if (country === ORG_COUNTRIES.CA.code || !enabled) return { shouldReset: false };

    if (hasDatesWithDifferentMetricsMethodologies) {
        return {
            shouldReset: true,
            warningMessage: RESET_TRAVELER_ATTRIBUTES_WARNING_MESSAGES.NOT_AVAILABLE,
        };
    }
    if (!isAllTravelerAttributesHaveTheSameStatus && !isUSAnalysisWithOnly2022Dates) {
        return {
            shouldReset: true,
            warningMessage: RESET_TRAVELER_ATTRIBUTES_WARNING_MESSAGES.RESET,
        };
    }

    return { shouldReset: false };
};

export const shouldResetTravelerAttributesOnCountryChange = createSelector(
    getBasics,
    getTimePeriods,
    getGeneral,
    getIsAllTravelerAttributesHaveTheSameStatus,
    (
        { travelModeCode, country },
        { dataPeriodSettings, dataMonthSettings },
        { analysisTypeCode },
        isAllTravelerAttributesHaveTheSameStatus,
    ) => {
        const travelMode = getAnalysisTravelModeFromCode(travelModeCode)!;
        const isAnalysisSupportsTravelerAttributes = getIsAnalysisTypeSupportOption(
            "travelerAttributes",
            analysisTypeCode,
        );
        const isCurrentCountryUS = country === ORG_COUNTRIES.US.code;

        if (isCurrentCountryUS) {
            return isAllTravelerAttributesHaveTheSameStatus
                ? {
                      shouldReset: false,
                  }
                : {
                      shouldReset: true,
                      warningMessage: RESET_TRAVELER_ATTRIBUTES_WARNING_MESSAGES.RESET,
                  };
        } else if (travelMode.code === MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code) {
            // Traveler Attributes are not available for Canada analyses with All Vehicles CVD travel mode
            return {
                shouldReset: true,
                warningMessage:
                    RESET_TRAVELER_ATTRIBUTES_WARNING_MESSAGES.NOT_AVAILABLE_FOR_CANADA_CVD,
            };
        }

        const isVehicleMode = getIsAllVehiclesTravelMode(travelMode?.code);
        const isBikePedMode = getIsBikeOrPedTravelMode(travelMode.code);

        if (!(isVehicleMode || isBikePedMode) || !isAnalysisSupportsTravelerAttributes) {
            return { shouldReset: false };
        }

        const [dataPeriodsType, dataPeriodsData] = isVehicleMode
            ? ["dataPeriods", dataPeriodSettings.dataPeriods]
            : ["dataMonths", dataMonthSettings.dataMonths];

        const isMixedWithCensus2020 = getIsDataPeriodsMixedWithCensus2020({
            dataPeriodsType,
            dataPeriodsData,
        });

        return isMixedWithCensus2020
            ? {
                  shouldReset: true,
                  warningMessage: RESET_TRAVELER_ATTRIBUTES_WARNING_MESSAGES.NOT_AVAILABLE,
              }
            : {
                  shouldReset: false,
              };
    },
);
