import React, { forwardRef, useState } from "react";
import classNames from "classnames";
import { StlInput } from "@common/components/input/input";
import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./passwordInput.less";

export const StlPasswordInput = forwardRef<HTMLInputElement, any>(
    ({ label, id, className, ...restProps }, ref): JSX.Element => {
        const [isPasswordVisible, setIsPasswordVisible] = useState(false);

        const togglePasswordVisibility = () => {
            setIsPasswordVisible(!isPasswordVisible);
        };

        return (
            <div className="form-group">
                {!!label && (
                    <label
                        className={classNames("stl-label", { required: restProps.required })}
                        htmlFor={id}
                    >
                        {label}
                        {restProps.required && <span className="required-label">(required)</span>}
                    </label>
                )}
                <div className="input-wrapper">
                    <StlInput
                        type={isPasswordVisible ? "text" : "password"}
                        id={id}
                        className={classNames("stl-input", className)}
                        ref={ref}
                        {...restProps}
                    />
                    <span
                        className="toggle-password"
                        role="form"
                        onClick={togglePasswordVisibility}
                    >
                        <FontAwesomeIcon icon={isPasswordVisible ? faEyeSlash : faEye} />
                    </span>
                </div>
            </div>
        );
    },
);

StlPasswordInput.displayName = "PasswordInput";
