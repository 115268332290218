import { useCallback, useEffect, useState } from "react";
import type { Polygon } from "geojson";
import type { Map } from "mapbox-gl";
import { MAP_DRAW_EVENTS } from "@common/components/baseMap/baseMap.constants";
import {
    TZoneSelectionSelectedToolId,
    ZONE_SELECTION_TOOLS,
} from "@common/components/baseMap/customControls/zoneSelectionTools/zoneSelectionTools.constants";
import { DRAW_STYLES } from "@common/components/baseMap/hooks/useDraw/drawOptionStyles";
import { DirectSelectForSpotCounter } from "@common/components/baseMap/hooks/useDraw/modes/directSelectForSpotCounter";
import { DirectSelectWithoutMidpointsMode } from "@common/components/baseMap/hooks/useDraw/modes/directSelectWithoutMidpointsMode";
import { DrawTwoPointLineMode } from "@common/components/baseMap/hooks/useDraw/modes/drawTwoPointLineMode";
import { FreehandMode } from "@common/components/baseMap/hooks/useDraw/modes/freehandMode";
import { StaticMode } from "@common/components/baseMap/hooks/useDraw/modes/staticMode";
import { EDrawMode, useGetDraw } from "@common/components/baseMap/hooks/useDraw/useDraw";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

const INITIAL_STATE: {
    region: null | Polygon;
} = {
    region: null,
};

const MANAGER_MODE_TO_DRAW_MODE = {
    VIEW_MAP: EDrawMode.simple_select,
    DRAW_REGION: "draw_region" as MapboxDraw.DrawModes["DRAW_POLYGON"],
} as const;

const DRAW_OPTIONS = {
    displayControlsDefault: false,
    styles: DRAW_STYLES,
    userProperties: true,
    modes: {
        ...MapboxDraw.modes,
        draw_two_point_line: DrawTwoPointLineMode,
        direct_select_for_spot_counter: DirectSelectForSpotCounter,
        direct_select_without_midpoints: DirectSelectWithoutMidpointsMode,
        //@ts-ignore
        draw_area: MapboxDraw.modes.draw_polygon,
        draw_region: FreehandMode,
        static: StaticMode as unknown as MapboxDraw.DrawCustomMode,
    },
};

export const useRegionDraw = ({
    map,
    selectedTool,
}: {
    map: Map | null;
    selectedTool: TZoneSelectionSelectedToolId | null;
}) => {
    const [region, setRegion] = useState(INITIAL_STATE.region);

    const isDrawEnabled =
        selectedTool === ZONE_SELECTION_TOOLS.LASSO_ADD.id ||
        selectedTool === ZONE_SELECTION_TOOLS.LASSO_REMOVE.id;

    const draw = useGetDraw(map, DRAW_OPTIONS);

    const finishDraw = useCallback(() => {
        if (!draw || !draw.getAll().features[0] || !map || !isDrawEnabled) return;

        if (
            draw.getMode() ===
            (MANAGER_MODE_TO_DRAW_MODE.DRAW_REGION as MapboxDraw.DrawModes["DRAW_POLYGON"])
        )
            return;

        setRegion(draw.getAll().features[0].geometry as Polygon);
        draw.deleteAll();
        draw.changeMode(MANAGER_MODE_TO_DRAW_MODE.DRAW_REGION);
    }, [map, draw, isDrawEnabled]);

    const removeRegion = useCallback(() => setRegion(INITIAL_STATE.region), []);

    useEffect(() => {
        if (!draw || !map || !isDrawEnabled) return undefined;

        draw.changeMode(MANAGER_MODE_TO_DRAW_MODE.DRAW_REGION);

        map.on("draw.modechange", finishDraw);

        return () => {
            setRegion(INITIAL_STATE.region);
            map.off("draw.modechange", finishDraw);
        };
    }, [draw, isDrawEnabled, map, finishDraw]);

    useEffect(() => {
        if (!draw || isDrawEnabled) return;

        if (draw.getMode() === MANAGER_MODE_TO_DRAW_MODE.VIEW_MAP) return;

        draw.changeMode(MANAGER_MODE_TO_DRAW_MODE.VIEW_MAP);
    }, [draw, isDrawEnabled]);

    useEffect(() => {
        if (!map) return undefined;

        map.fire(MAP_DRAW_EVENTS.DRAW, { isDrawing: isDrawEnabled });

        return () => {
            map.fire(MAP_DRAW_EVENTS.DRAW, { isDrawing: false });
        };
    }, [map, isDrawEnabled]);

    return { removeRegion, region };
};
