import { MAP_STYLES } from "@app/viz3/npSegmentVisualization/map/mapStyles.constants";

export const NP_SEGMENT_VIZ_INITIAL_STATE = {
    map: {
        clickedZoneId: null,
        layerConfigurations: {
            colors: {
                high: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HIGH,
                mid: "",
                low: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.LOW,
                hover: MAP_STYLES.DISTRIBUTION_ZONES.COLOR.HOVER,
            },
            opacity: MAP_STYLES.DISTRIBUTION_ZONES.OPACITY,
        },
    },
    segmentLayers: null,
    preSelectedZones: [],
};
