export class MapLoader {
    container!: HTMLDivElement;
    _onAdd: () => void;

    constructor({ onAdd }: { onAdd: () => void }) {
        this._onAdd = onAdd;
    }

    onAdd = () => {
        this.container = document.createElement("div");
        this.container.className = "mapboxgl-ctrl mapboxgl-ctrl-group map-loader";

        this._onAdd();

        return this.container;
    };

    onRemove = () => {
        this.container.parentNode?.removeChild(this.container);
    };
}
