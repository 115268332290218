export const getUpdatedSelectedZones = <T extends unknown>(
    currentSelectedZones: Array<T>,
    newSelectedZones: Array<T>,
    isAddMode: boolean,
    property: keyof T = "zone_id" as keyof T,
) => {
    const mapOfCurrentSelectedZones = new Map(
        currentSelectedZones.map(zone => [String(zone[property]), zone]),
    );
    const mapOfNewZones = new Map(newSelectedZones.map(zone => [String(zone[property]), zone]));

    if (!isAddMode) {
        return currentSelectedZones.filter(zone => !mapOfNewZones.has(String(zone[property])));
    }

    mapOfNewZones.forEach((value, key) => mapOfCurrentSelectedZones.set(key, value));

    return Array.from(mapOfCurrentSelectedZones.values());
};
