import { MODES_OF_TRAVEL } from "@common/constants/analysis.constants";
import { TApproachKey, TTimePeriod } from "./createAnalysisHelp.types";

export const SEARCH_KEY = "search" as const;

export const MIN_QUERY_LENGTH = 3 as const;

// words (articles and prepositions) that should be ignored during search
export const EXCLUDED_WORDS = ["the", "out", "for"];

export const TRAVEL_MODES_OPTIONS = {
    All_Vehicles: {
        value: "All_Vehicles",
        label: "All Vehicles",
        order: 1,
    },
    Truck: {
        value: "Truck",
        label: "Truck",
        order: 2,
    },
    Bicycle: {
        value: "Bicycle",
        label: "Bicycle",
        order: 3,
    },
    Pedestrian: {
        value: "Pedestrian",
        label: "Pedestrian",
        order: 4,
    },
    Bus: {
        value: "Bus",
        label: "Bus",
        order: 5,
    },
    Rail: {
        value: "Rail",
        label: "Rail",
        order: 6,
    },
};

export const METRICS_OPTIONS = {
    HWL: {
        value: "HWL",
        label: "Home and Work Locations",
    },
    "85th percentile": {
        value: "85th percentile",
        label: "85th percentile",
    },
    "average trip speed": {
        value: "average trip speed",
        label: "Average Trip Speed",
    },
    "trip length": {
        value: "trip length",
        label: "Trip Length",
    },
    "travel time": {
        value: "travel time",
        label: "Travel Time",
    },
    "speed percentile": {
        value: "speed percentile",
        label: "Speed Percentile",
    },
};

export const APPROACHES_OPTIONS: Record<TApproachKey, { value: TApproachKey; label: string }> = {
    "Speed and Volume on Roadway": {
        value: "Speed and Volume on Roadway",
        label: "Speeds and Volumes on Roads",
    },
    "Trip Metrics": {
        value: "Trip Metrics",
        label: "Trip and Traveler Demographic Information",
    },
    Modeshare: {
        value: "Modeshare",
        label: "Mode Share: Active Transportation",
    },
    "Traffic Flow": {
        value: "Traffic Flow",
        label: "Traffic Flow: Origin Destination and Routing",
    },
    "Intersection Studies": {
        value: "Intersection Studies",
        label: "Intersections",
    },
};

export const TIME_PERIODS_OPTIONS: Record<TTimePeriod, { value: TTimePeriod; label: string }> = {
    Recent: {
        value: "Recent",
        label: "Most Recent Year",
    },
    "All Years": {
        value: "All Years",
        label: "All Years",
    },
};

export const ALL_VEHICLES_TRAVEL_MODES_CODES = [
    MODES_OF_TRAVEL.ALL_VEHICLES.code,
    MODES_OF_TRAVEL.ALL_VEHICLES_ONLY.code,
    MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code,
    MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code,
    MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code,
];
