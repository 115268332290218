import type { IBin, IInput } from "@app/analysis/addOns/state/addOns.types";
import type {
    TPresetGeogType,
    TRouteOptions,
} from "@app/analysis/state/analysisConfiguration.constants";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    EMPTY_TRIP_BIN_ADD_INPUT,
    TAllTripBinTypes,
    TRAVELER_PARAMS_US_CENSUS_2020_LIST,
    TRIP_BIN_TYPES_LIST,
} from "./addOns.constants";
import {
    ADD_ONS_INITIAL_STATE,
    INITIAL_TRIP_BIN_UI_STATE,
    TAddOnsState,
    TAdminSettings,
    TCommercialVehicleSettings,
    THwlSettings,
    TTravelerAttrSettings,
    TTravelerBinUiState,
    TTripAttrSettings,
    TTripBinUiState,
    TTripSpeedPercentileBinUiState,
    TValidation,
} from "./addOns.state";

const isFieldsInvalid = (validationFields: TValidation["fields"]) =>
    Object.values(validationFields).some(field => field && field.isInvalid);

export const { actions, reducer } = createSlice({
    name: "addOnsReducer",
    initialState: ADD_ONS_INITIAL_STATE,
    reducers: {
        setAddOnsInitialData: (state, action: PayloadAction<TAddOnsState>) => {
            return {
                ...state,
                ...action.payload,
                hwlSettings: {
                    ...state.hwlSettings,
                    ...action.payload.hwlSettings,
                },
                tripAttrSettings: {
                    ...state.tripAttrSettings,
                    ...action.payload.tripAttrSettings,
                },
                travelerAttrSettings: {
                    ...state.travelerAttrSettings,
                    ...action.payload.travelerAttrSettings,
                },
                commercialVehicleSettings: {
                    ...state.commercialVehicleSettings,
                    ...action.payload.commercialVehicleSettings,
                },
                adminSettings: {
                    ...state.adminSettings,
                    ...action.payload.adminSettings,
                },
            };
        },
        setGeogType: (state, action: PayloadAction<TPresetGeogType["code"]>) => {
            state.presetGeographyType = action.payload;
        },
        setTripAttrEnabled: (state, action: PayloadAction<TTripAttrSettings["enabled"]>) => {
            const tripAttrUiStates = TRIP_BIN_TYPES_LIST.reduce((res, tripBinType) => {
                return {
                    ...res,
                    [tripBinType.uiStateName]: {
                        ...(state.tripAttrSettings[
                            tripBinType.uiStateName as keyof TTripAttrSettings
                        ] as TTripBinUiState),
                        enabled: action.payload,
                    },
                };
            }, {});

            state.tripAttrSettings = {
                ...state.tripAttrSettings,
                ...tripAttrUiStates,
                enabled: action.payload,
            };
        },
        setInitialTripBin: (state, action: PayloadAction<keyof TTripAttrSettings>) => {
            const enabledState = (state.tripAttrSettings[action.payload] as TTripBinUiState)
                .enabled;

            (state.tripAttrSettings[action.payload] as TTripBinUiState) = {
                ...INITIAL_TRIP_BIN_UI_STATE,
                enabled: enabledState,
            };
        },
        setTripBins: (
            state,
            action: PayloadAction<{
                tripBins: Array<IBin> | null;
                binTypeCode: TAllTripBinTypes["code"];
            }>,
        ) => {
            //@ts-ignore
            state.tripAttrSettings[action.payload.binTypeCode] = action.payload.tripBins;
        },
        addTripBin: (
            state,
            action: PayloadAction<{
                binUiStateName: keyof TTripAttrSettings;
            }>,
        ) => {
            (state.tripAttrSettings[action.payload.binUiStateName] as TTripBinUiState) = {
                ...(state.tripAttrSettings[action.payload.binUiStateName] as TTripBinUiState),
                mode: "adding",
            };
        },
        editTripBin: (
            state,
            action: PayloadAction<{
                binUiStateName: keyof TTripAttrSettings;
            }>,
        ) => {
            (state.tripAttrSettings[action.payload.binUiStateName] as TTripBinUiState) = {
                ...(state.tripAttrSettings[action.payload.binUiStateName] as TTripBinUiState),
                mode: "editing",
                addInput: EMPTY_TRIP_BIN_ADD_INPUT,
            };
        },
        saveEditTripBin: (
            state,
            action: PayloadAction<{
                newTripBins?: Array<IBin>;
                binUiStateName: keyof TTripAttrSettings;
            }>,
        ) => {
            (state.tripAttrSettings[action.payload.binUiStateName] as TTripBinUiState).mode =
                action.payload.newTripBins?.some(bin => bin.editMode)
                    ? (state.tripAttrSettings[action.payload.binUiStateName] as TTripBinUiState)
                          .mode
                    : "";
        },
        editNewTripBin: (
            state,
            action: PayloadAction<{
                editInput: IInput;
                binUiStateName: keyof TTripAttrSettings;
            }>,
        ) => {
            (state.tripAttrSettings[action.payload.binUiStateName] as TTripBinUiState).addInput =
                action.payload.editInput;
        },
        saveNewTripBin: (
            state,
            action: PayloadAction<{
                binUiState: Partial<TTripBinUiState>;
                binUiStateName: keyof TTripAttrSettings;
            }>,
        ) => {
            (state.tripAttrSettings[action.payload.binUiStateName] as TTripBinUiState) = {
                ...(state.tripAttrSettings[action.payload.binUiStateName] as TTripBinUiState),
                ...action.payload.binUiState,
            };
        },
        setTripBinTypeEnabled: (
            state,
            action: PayloadAction<{
                enabled: boolean;
                binUiStateName: keyof TTripAttrSettings;
            }>,
        ) => {
            (state.tripAttrSettings[action.payload.binUiStateName] as TTripBinUiState).enabled =
                action.payload.enabled;
        },
        patchTripBinSettings: (
            state,
            action: PayloadAction<{
                binUiStateName: keyof TTripAttrSettings;
                update: Partial<TTripBinUiState> | Partial<TTripSpeedPercentileBinUiState>;
            }>,
        ) => {
            (state.tripAttrSettings[action.payload.binUiStateName] as TTripBinUiState) = {
                ...(state.tripAttrSettings[action.payload.binUiStateName] as TTripBinUiState),
                ...action.payload.update,
            };
        },
        setTravelerAttrEnabled: (
            state,
            action: PayloadAction<TTravelerAttrSettings["enabled"]>,
        ) => {
            const travelerAttrUiStates = TRAVELER_PARAMS_US_CENSUS_2020_LIST.reduce(
                (res, travelerBinType) => {
                    return {
                        ...res,
                        [travelerBinType.uiStateName]: {
                            ...(state.travelerAttrSettings[
                                travelerBinType.uiStateName as keyof TTravelerAttrSettings
                            ] as TTravelerBinUiState),
                            enabled: action.payload,
                        },
                    };
                },
                {},
            );

            state.travelerAttrSettings = {
                ...state.travelerAttrSettings,
                ...travelerAttrUiStates,
                enabled: action.payload,
            };
        },
        setTravelerBinTypeEnabled: (
            state,
            action: PayloadAction<{
                enabled: boolean;
                binUiStateName: keyof TTravelerAttrSettings;
            }>,
        ) => {
            (
                state.travelerAttrSettings[action.payload.binUiStateName] as TTravelerBinUiState
            ).enabled = action.payload.enabled;
        },
        setHwlFilterOptions: (state, action: PayloadAction<THwlSettings["hwlFilterOptions"]>) => {
            state.hwlSettings.hwlFilterOptions = {
                ...state.hwlSettings.hwlFilterOptions,
                ...action.payload,
            };
        },
        setHwlZoneIntersectionType: (
            state,
            action: PayloadAction<THwlSettings["hwlZoneIntersectionCode"]>,
        ) => {
            state.hwlSettings.hwlZoneIntersectionCode = action.payload;
        },
        setCommercialVehicleType: (
            state,
            action: PayloadAction<TCommercialVehicleSettings["commercialVehicleType"]>,
        ) => {
            state.commercialVehicleSettings.commercialVehicleType = {
                ...state.commercialVehicleSettings.commercialVehicleType,
                ...action.payload,
            };
        },
        setCommercialVehicleSettingsEnabled: (
            state,
            action: PayloadAction<TCommercialVehicleSettings["enabled"]>,
        ) => {
            state.commercialVehicleSettings.enabled = action.payload;
        },
        setBypassPrivacyCheckSetting: (
            state,
            action: PayloadAction<TAdminSettings["bypassPrivacyCheck"]>,
        ) => {
            state.adminSettings.bypassPrivacyCheck = action.payload;
        },
        setBypassSizeValidationCheckSetting: (
            state,
            action: PayloadAction<TAdminSettings["bypassSizeValidationCheck"]>,
        ) => {
            state.adminSettings.bypassSizeValidationCheck = action.payload;
        },
        toggleTripAttrSettings: state => {
            const newEnabledState = !state.tripAttrSettings.enabled;
            const tripAttrUiStates = TRIP_BIN_TYPES_LIST.reduce((res, tripBinType) => {
                return {
                    ...res,
                    [tripBinType.uiStateName]: {
                        ...(state.tripAttrSettings[
                            tripBinType.uiStateName as keyof TTripAttrSettings
                        ] as TTripBinUiState),
                        enabled: newEnabledState,
                    },
                };
            }, {});

            state.tripAttrSettings = {
                ...state.tripAttrSettings,
                ...tripAttrUiStates,
                enabled: newEnabledState,
            };
        },
        toggleHwlSettings: state => {
            state.hwlSettings.enabled = !state.hwlSettings.enabled;
        },
        toggleHwlFilterOptions: (
            state,
            action: PayloadAction<keyof THwlSettings["hwlFilterOptions"]>,
        ) => {
            state.hwlSettings.hwlFilterOptions[action.payload] =
                !state.hwlSettings.hwlFilterOptions[action.payload];
        },
        toggleEnableViz: state => {
            state.adminSettings.enable_viz = !state.adminSettings.enable_viz;
        },
        toggleBypassPrivacyCheckSetting: state => {
            state.adminSettings.bypassPrivacyCheck = !state.adminSettings.bypassPrivacyCheck;
        },
        toggleBypassSizeValidationCheckSetting: state => {
            state.adminSettings.bypassSizeValidationCheck =
                !state.adminSettings.bypassSizeValidationCheck;
        },
        toggleIsMassiveQueue: state => {
            state.adminSettings.isMassiveQueue = !state.adminSettings.isMassiveQueue;
        },
        toggleEnableUpsampling: state => {
            state.adminSettings.enableUpsampling = !state.adminSettings.enableUpsampling;
        },
        toggleEnableEmailNotification: state => {
            state.adminSettings.enableEmailNotification =
                !state.adminSettings.enableEmailNotification;
        },
        setSizeReviewDetails: (
            state,
            action: PayloadAction<{
                validationTypes: Array<string> | null;
                validationMessages: Array<string> | null;
            }>,
        ) => {
            state.adminSettings = {
                ...state.adminSettings,
                size_review_details: action.payload.validationMessages,
                size_review_types: action.payload.validationTypes,
            };
        },
        setRouteOptions: (state, action: PayloadAction<TRouteOptions>) => {
            state.routeOptions = action.payload;
        },
        setAddOnsValidation: (state, action: PayloadAction<TValidation["fields"]>) => {
            const newFields = {
                ...state.validation.fields,
                ...action.payload,
            };

            state.validation = {
                fields: newFields,
                isInvalid: isFieldsInvalid(newFields),
            };
        },
        resetAddOnsReducer: () => {
            return ADD_ONS_INITIAL_STATE;
        },
    },
});

export const addOnsReducer = reducer;
