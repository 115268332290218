import DrawPolygon from "@mapbox/mapbox-gl-draw/src/modes/draw_polygon";
import * as Constants from "@mapbox/mapbox-gl-draw/src/constants";
import simplify from "@turf/simplify";

// Copy of https://github.com/bemky/mapbox-gl-draw-freehand-mode/blob/master/src/index.js
export const FreehandMode = {
    ...DrawPolygon,
    onSetup() {
        const polygon = this.newFeature({
            type: Constants.geojsonTypes.FEATURE,
            properties: {},
            geometry: {
                type: Constants.geojsonTypes.POLYGON,
                coordinates: [[]],
            },
        });

        this.addFeature(polygon);
        this.clearSelectedFeatures();

        // disable dragPan
        setTimeout(() => {
            if (!this.map || !this.map.dragPan) return;
            this.map.dragPan.disable();
        }, 0);

        this.updateUIClasses({ mouse: Constants.cursors.ADD });
        this.activateUIButton(Constants.types.POLYGON);
        this.setActionableState({
            trash: true,
        });

        return {
            polygon,
            currentVertexPosition: 0,
            dragMoving: false,
        };
    },
    onDrag: (state, e) => {
        state.dragMoving = true;
        state.polygon.updateCoordinate(
            `0.${state.currentVertexPosition}`,
            e.lngLat.lng,
            e.lngLat.lat,
        );
        state.currentVertexPosition++;
        state.polygon.updateCoordinate(
            `0.${state.currentVertexPosition}`,
            e.lngLat.lng,
            e.lngLat.lat,
        );
    },
    onTouchMove: (state, e) => {
        state.dragMoving = true;
        state.polygon.updateCoordinate(
            `0.${state.currentVertexPosition}`,
            e.lngLat.lng,
            e.lngLat.lat,
        );
        state.currentVertexPosition++;
        state.polygon.updateCoordinate(
            `0.${state.currentVertexPosition}`,
            e.lngLat.lng,
            e.lngLat.lat,
        );
    },
    onMouseUp(state) {
        if (state.dragMoving) {
            this.simplify(state.polygon);
            this.fireUpdate();
            this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.polygon.id] });
        }
    },
    onTouchEnd(state, e) {
        this.onMouseUp(state, e);
    },
    fireUpdate() {
        this.map.fire(Constants.events.UPDATE, {
            action: Constants.updateActions.MOVE,
            features: this.getSelected().map(f => f.toGeoJSON()),
        });
    },
    simplify(polygon) {
        const tolerance = 1 / 1.05 ** (10 * this.map.getZoom()); // https://www.desmos.com/calculator/nolp0g6pwr
        simplify(polygon, {
            mutate: true,
            tolerance: tolerance,
            highQuality: true,
        });
    },
    onStop(state) {
        DrawPolygon.onStop.call(this, state);
        setTimeout(() => {
            if (!this.map || !this.map.dragPan) return;
            this.map.dragPan.enable();
        }, 0);
    },
};
